interface IconProps {
  iconClassName?: string;
}

const WebSiteIcon: React.FC<IconProps> = ({ iconClassName }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      className={iconClassName}
    >
      <path
        d="M8.01416 11.119C8.39046 11.6219 8.87051 12.0381 9.42177 12.3392C9.97303 12.6403 10.5826 12.8194 11.2091 12.8643C11.8357 12.9091 12.4645 12.8187 13.0531 12.5992C13.6416 12.3797 14.176 12.0362 14.6202 11.592L17.2482 8.96399C17.6609 8.55863 17.9893 8.07552 18.2144 7.54254C18.4394 7.00956 18.5566 6.43728 18.5592 5.85875C18.5619 5.28022 18.4499 4.7069 18.2297 4.1719C18.0095 3.6369 17.6855 3.15082 17.2764 2.74173C16.8673 2.33265 16.3812 2.00866 15.8462 1.78848C15.3112 1.56829 14.7379 1.45628 14.1594 1.45891C13.5809 1.46153 13.0086 1.57875 12.4756 1.80378C11.9426 2.02881 11.4595 2.3572 11.0542 2.76999L9.54816 4.26999"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5181 9.36704C11.1418 8.86344 10.6615 8.44671 10.1099 8.14516C9.5583 7.84362 8.94825 7.66432 8.32119 7.61945C7.69414 7.57458 7.06478 7.66519 6.47585 7.88512C5.88692 8.10504 5.35222 8.44914 4.90806 8.89404L2.28406 11.518C1.87696 11.9248 1.55397 12.4078 1.33353 12.9394C1.11308 13.471 0.9995 14.0408 0.999268 14.6163C0.999036 15.1918 1.11216 15.7617 1.33217 16.2934C1.55219 16.8252 1.87479 17.3084 2.28156 17.7155C2.68833 18.1226 3.1713 18.4456 3.7029 18.6661C4.23449 18.8865 4.8043 19.0001 5.37979 19.0003C5.95529 19.0006 6.52519 18.8874 7.05696 18.6674C7.58873 18.4474 8.07196 18.1248 8.47906 17.718L9.97906 16.218"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default WebSiteIcon;
