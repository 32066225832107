import { GetBlogsResponse } from "@apps/sdk";
import BlogPost from "../../components/BlogPost/BlogPost";
import { getFileSrc } from "../../utils";
import styles from "./Blog.module.scss";
import { formatTimestamp } from "../../utils/dates";

type BlogListProps = {
  blogPosts: GetBlogsResponse;
};

const BlogList = ({ blogPosts }: BlogListProps) => {
  // console.log(formatTimestamp(Number(blogPosts.blogs[0].createdAt)));

  return (
    <>
      {blogPosts.blogs.map((post) => (
        <BlogPost
          key={post.id}
          slug={post.slug}
          title={post.title}
          date={post.createdAt} //Number(post.createdAt)
          thumbnailIMG={getFileSrc(post.imageUrl || "")}
          className={styles.blogPost}
        />
      ))}
    </>
  );
};

export default BlogList;
