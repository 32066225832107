import { deepFreeze } from "../utils";
var EventEmitter = /** @class */ (function () {
    function EventEmitter() {
        this.listenersMap = new Map();
    }
    EventEmitter.prototype.addListener = function (event, listener) {
        var listeners = this.listenersMap.get(event);
        if (!listeners) {
            this.listenersMap.set(event, new Set([listener]));
            return;
        }
        listeners.add(listener);
    };
    EventEmitter.prototype.removeListener = function (event, listener) {
        var listeners = this.listenersMap.get(event);
        if (!listeners) {
            return;
        }
        listeners.delete(listener);
        if (listeners.size === 0) {
            this.listenersMap.delete(event);
        }
    };
    EventEmitter.prototype.removeAllListeners = function (event) {
        this.listenersMap.delete(event);
    };
    EventEmitter.prototype.dispatch = function (event, value) {
        var _a;
        var dispatchValue = value === null || value === void 0 ? void 0 : value.payload;
        (_a = this.listenersMap.get(event)) === null || _a === void 0 ? void 0 : _a.forEach(function (listener) { return listener(dispatchValue); });
    };
    return EventEmitter;
}());
export { EventEmitter };
export var createEventEmitter = function () {
    var emitter = new EventEmitter();
    // TODO
    // once(): add a one-time listener
    var emit = function (dispatchPayload, options) {
        if (options === void 0) { options = {}; }
        var event = dispatchPayload.event, payload = dispatchPayload.payload;
        if (!payload) {
            return emitter.dispatch(event, { payload: payload });
        }
        var noFreeze = options.noFreeze;
        var payloadValue = { payload: payload };
        var payloadValueToDispatch = noFreeze ? payloadValue : deepFreeze(payloadValue);
        emitter.dispatch(event, payloadValueToDispatch);
    };
    var addListener = function (event, callback) { return emitter.addListener(event, callback); };
    var removeListener = function (event, callback) { return emitter.removeListener(event, callback); };
    var removeAllListeners = function (event) { return emitter.removeAllListeners(event); };
    return {
        emit: emit,
        on: addListener,
        removeListener: removeListener,
        off: removeListener,
        removeAllListeners: removeAllListeners,
    };
};
