import DefaultTextField from './DefaultTextField';
import FormikTextField from './FormikTextField';
import { TextFieldProps } from './TextField.types';

const TextField: React.FC<TextFieldProps> = ({ variant = 'outlined', fullWidth = true, isDefault, ...props }) => {
    const TextFieldComponent = isDefault ? DefaultTextField : FormikTextField;

    return <TextFieldComponent {...{ fullWidth, variant, ...props }} />;
};

export default TextField;
