import { State } from "@packages/core-shared";
import { useEffect, useState } from "react";

export const useReactiveState = <T>(state: State<T>) => {
  const [current, setCurrent] = useState<Readonly<T>>(state.get());

  const [previous, setPrevious] = useState<Readonly<T | null>>(
    state.getPrevious()
  );

  useEffect(() => {
    const stateChangeHandler = (
      prevState: Readonly<T | null>,
      newState: Readonly<T>
    ) => {
      setCurrent(newState);

      setPrevious(prevState);
    };

    state.addChangeListener(stateChangeHandler);

    return () => {
      state.removeChangeListener(stateChangeHandler);
    };
  }, [state, setCurrent, setPrevious]);

  return {
    current,
    previous,
  };
};
