import TextArrow from "../../TextArrow/TextArrow";
import styles from "./BlogPost.module.scss";
import { useTranslations } from "../../../hooks";

import clsx from "clsx";

import { getDateDay, getDateMonth } from "../../../utils/dates/getDateBlogPost";

type BlogPostProps = {
  title: string;
  date: string;
  thumbnailIMG: string;
  slug: string;
  className?: string;
};

const BlogPost = ({
  title,
  date,
  thumbnailIMG,
  slug,
  className,
}: BlogPostProps) => {
  const dateDay = getDateDay(date);
  const dateMonthName = getDateMonth(date);

  console.log(getDateMonth(date));

  const { translate } = useTranslations();

  // const divStyle = {
  //   backgroundImage: `url(${thumbnailIMG})`,
  //   backgroundSize: "contain",
  //   backgroundPosition: "center",
  //   backgroundRepeat: "no-repeat",
  // };

  const divStyle = {
    backgroundImage: `url(${thumbnailIMG})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  return (
    <div className={clsx(styles.box, styles.container, className)}>
      <div style={divStyle} className={styles.photoContainer}></div>

      <div className={styles.infoContainer}>
        <div className={styles.date}>
          <div className={styles.dateDay}>{dateDay}</div>
          <div className={styles.dateMonth}>{dateMonthName}</div>
        </div>

        <div className={styles.divider} />

        <div className={styles.titleContainer}>
          <div className={styles.title}>{title} </div>
          <TextArrow
            caption={translate((t) => t.LEARN_MORE)}
            size={"small"}
            linkUrl={slug}
          />
        </div>
      </div>
    </div>
  );
};

export default BlogPost;
