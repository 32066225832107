interface IconProps {
  iconClassName?: string;
}

const TwitterIcon: React.FC<IconProps> = ({ iconClassName }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
      className={iconClassName}
    >
      <path
        d="M0.0479999 0.265015L7.576 10.333L0 18.516H1.71L8.343 11.351L13.704 18.517H19.504L11.547 7.88301L18.599 0.265015H16.894L10.785 6.86502L5.85 0.265015H0.0479999ZM2.556 1.52102H5.222L16.992 17.26H14.326L2.556 1.52102Z"
        fill="#CCCCCC"
      />
    </svg>
  );
};

export default TwitterIcon;
