import {
  GenerateCertificateRequest,
  GenerateCertificateResponse,
  Query,
} from "@apps/sdk";
import { useCallback } from "react";
import { useQueryState } from "./common";
import { frontofficeService } from "../services";
import { toast } from "react-toastify";

export const useGenerateCertificate = () => {
  const { data, error, loading, setState, setLoading } =
    useQueryState<GenerateCertificateResponse>();

  const generateCertificate = useCallback(
    async (payload: GenerateCertificateRequest) => {
      setLoading(true);

      setState(
        await frontofficeService.query({
          query: Query.GenerateCertificate,
          payload,
          onSuccess: (response: GenerateCertificateResponse) => {
            toast.success("Certificate generated", {
              position: "top-right",
            });
          },
          onError: (errorResponse: any) => {
            errorResponse.errors.forEach(
              (error: any) => toast.error(error.message),
              {
                position: "top-right",
              }
            );
          },
        })
      );

      setLoading(false);
    },
    []
  );

  return {
    generateCertificate,
    data,
    error,
    loading,
  };
};
