import { useTextField } from "../../hooks";

import DefaultTextField from "./DefaultTextField";
import { TextFieldProps } from "./TextField.types";

const FormikTextField: React.FC<TextFieldProps> = (props) => {
  const fieldProps = useTextField(props);
  const isValueZero = fieldProps.value === 0;
  const value = isValueZero ? fieldProps.value : fieldProps.value || "";

  if (fieldProps.options?.length) {
    const isValueInOptions = fieldProps.options.find(
      (option: any) => option.value === value
    );
    fieldProps.value = isValueInOptions ? value : "";
  } else {
    fieldProps.value = value;
  }

  return <DefaultTextField {...fieldProps} />;
};

export default FormikTextField;
