"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JwtResponseSchema = exports.UserSchema = exports.SocialsJsonSchema = exports.UserStatus = exports.UserType = void 0;
var zod_1 = require("zod");
var country_1 = require("./country");
var UserType;
(function (UserType) {
    UserType["Volunteer"] = "Volunteer";
    UserType["SportOrganization"] = "SportOrganization";
})(UserType || (exports.UserType = UserType = {}));
var UserStatus;
(function (UserStatus) {
    UserStatus["Pending"] = "Pending";
    UserStatus["Approved"] = "Approved";
})(UserStatus || (exports.UserStatus = UserStatus = {}));
exports.SocialsJsonSchema = zod_1.z.object({
    links: zod_1.z.array(zod_1.z.object({
        url: zod_1.z.string(),
        name: zod_1.z.string(),
    }))
});
exports.UserSchema = zod_1.z.object({
    id: zod_1.z.string(),
    email: zod_1.z.string().email(),
    fullName: zod_1.z.string(),
    type: zod_1.z.nativeEnum(UserType),
    country: country_1.CountrySchema.nullish(),
    phoneNumber: zod_1.z.string().nullish(),
    description: zod_1.z.string().nullish(),
    socials: exports.SocialsJsonSchema.nullish(),
    photoUrl: zod_1.z.string().nullish(),
    status: zod_1.z.nativeEnum(UserStatus)
});
exports.JwtResponseSchema = zod_1.z.object({
    token: zod_1.z.string(),
    expireDate: zod_1.z.string(),
});
