import { Link } from "react-router-dom";
import { capitalizeFirstLetter } from "../../utils/string/capitalizeFirsLetter";
import styles from "./TextArrow.module.scss";

import arrowRightBrowse from "./arrow-right-browse.svg";

type TextArrowProps = {
  caption: string;
  containerWrapper?: string;
  linkUrl?: string;
  size: "small" | "medium" | "large";
};

const TextArrow = ({
  caption,
  containerWrapper,
  size,
  linkUrl,
}: TextArrowProps) => {
  const fontSize = `font${capitalizeFirstLetter(size)}`;
  const imageSize = `image${capitalizeFirstLetter(size)}`;

  return (
    <>
      <Link to={linkUrl!}>
        <div
          className={`${styles.container} ${
            containerWrapper ? containerWrapper : ""
          }`}
        >
          <div className={`${styles.caption} ${styles[fontSize]} ${fontSize}`}>
            {caption}
          </div>
          <img
            src={arrowRightBrowse}
            className={`${styles[imageSize]} ${styles.image}`}
          />
        </div>
      </Link>
    </>
  );
};

export default TextArrow;
