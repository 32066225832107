import styles from "./SportOrganizations.module.scss";
import { useReactiveState, useTranslations } from "../../../hooks";
import { Link } from "react-router-dom";
import { path } from "../../../router";
import { authModalState, userState } from "../../../state";
import { UserType } from "@apps/sdk";

const SportOrganisations = () => {
  const { translate } = useTranslations();
  const { current: user } = useReactiveState(userState);

  const openRegisterModal = () =>
    authModalState.set({
      ...authModalState.get(),
      registerModal: true,
    });

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.title}>
          {translate((t) => t.SPORT_ORGANIZATIONS)}
        </div>

        <div className={styles.subtitle}>
          {translate((t) => t.RECRUIT_VOLUNTEERS_FOR_VARIOUS_EVENTS)}
        </div>

        {user?.type === UserType.SportOrganization ? (
          <Link to={path.createEvent}>
            <button className={styles.createSportEvent}>
              {translate((t) => t.CREATE_SPORT_EVENT)}
            </button>
          </Link>
        ) : (
          <>
            {user?.type === UserType.Volunteer ? (
              <Link to={path.events}>
                <button className={styles.createSportEvent}>
                  {translate((t) => t.CHECK_OUT_OUR_EVENTS)}
                </button>
              </Link>
            ) : (
              <button
                className={styles.createSportEvent}
                onClick={openRegisterModal}
              >
                {translate((t) => t.CREATE_SPORT_EVENT)}
              </button>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default SportOrganisations;
