import clsx from "clsx";
import styles from "./ContentContainer.module.scss";

type ContentContainerProps = {
    children: React.ReactNode;
    className?: string;
};

const ContentContainer = ({ className, children }: ContentContainerProps) => (
    <div className={clsx(styles.container, className)}>{children}</div>
);

export default ContentContainer;
