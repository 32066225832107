import { SportEventStatus } from "@apps/sdk";
// import { CourseStatus } from "graphql/generated";
import { useTranslations } from "../../hooks";
import styles from "./ContentFooterButtons.module.scss";

type ContentFooterButtonsProps = Readonly<{
  onSubmit?: () => void;
  onReset?: () => void;
  onDelete?: () => void;
  onSendForApproval?: () => void;
  status?: SportEventStatus;
  submitType?:
    | "SaveChanges"
    | "Submit"
    | "SendForApproval"
    | "CreateSportEvent"; //"Update" | "Create";
  type?: "button" | "submit" | "reset";
}>;

const ContentFooterButtons = ({
  onSubmit,
  onReset,
  submitType = "Submit", //Update
  type = "button",
}: ContentFooterButtonsProps) => {
  const { translate } = useTranslations();

  let submitButtonText = "";
  switch (submitType) {
    case "SaveChanges":
      submitButtonText = translate((t) => t.SAVE_CHANGES);
      break;
    case "Submit":
      submitButtonText = translate((t) => t.SUBMIT);
      break;
    case "SendForApproval":
      submitButtonText = translate((t) => t.SEND_FOR_APPROVAL);
      break;
    case "CreateSportEvent":
      submitButtonText = translate((t) => t.CREATE_SPORT_EVENT);
      break;
  }

  return (
    <div className={styles.container}>
      <button type={type} className={styles.submitBtn} onClick={onSubmit}>
        {submitButtonText}
      </button>

      {submitType === "SendForApproval" ? (
        <button type="button" className={styles.cancelBtn} onClick={onReset}>
          {translate((t) => t.CANCEL)}
        </button>
      ) : null}

      {/* {onSendForApproval && (
        <button
          type={type}
          className={
            status === CourseStatus.Pending
              ? styles.awaitingApprovalBtn
              : styles.approvalBtn
          }
          onClick={onSendForApproval}
          disabled={status === CourseStatus.Pending}
        >
          {status === CourseStatus.Pending
            ? translate((t) => t.COURSE_IS_WAITING_FOR_APPROVAL)
            : translate((t) => t.SEND_FOR_APPROVAL)}
        </button>
      )}

      {onDelete && (
        <ConfirmationButton
          onAccept={() => {
            onDelete();
          }}
          renderButton={(onClick) => (
            <button
              type="button"
              className={styles.deleteBtn}
              onClick={onClick}
            >
              {translate((t) => t.DELETE)}
            </button>
          )}
        />
      )} */}
    </div>
  );
};

export default ContentFooterButtons;
