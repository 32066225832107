import Headline from "./Headline";
import HowItWorks from "./HowItWorks";
import LatestBlogPosts from "./LatestBlogPosts";
import SportOrganizations from "./SportOrganizations";
import Volunteers from "./Volunteers";

const Home = () => {
  return (
    <>
      <Headline />
      <HowItWorks />
      <Volunteers />
      <SportOrganizations />
      <LatestBlogPosts />
    </>
  );
};

export default Home;
