"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateOrEditPageResponseSchema = exports.CreateOrEditPageRequestSchema = void 0;
var zod_1 = require("zod");
var shared_1 = require("../shared");
exports.CreateOrEditPageRequestSchema = zod_1.z.object({
    id: zod_1.z.string().optional(),
    image: shared_1.UploadSchema.optional(),
}).merge(shared_1.PageSchema.omit({
    id: true,
    imageUrl: true,
}));
exports.CreateOrEditPageResponseSchema = shared_1.PageSchema;
