"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetUsersResponseSchema = exports.GetUsersRequestSchema = void 0;
var zod_1 = require("zod");
var shared_1 = require("../shared");
exports.GetUsersRequestSchema = zod_1.z.object({
    pagination: shared_1.PaginationSchema,
    filter: zod_1.z.object({
        id: zod_1.z.string().optional(),
        type: zod_1.z.nativeEnum(shared_1.UserType).optional()
    }).optional()
});
exports.GetUsersResponseSchema = zod_1.z.object({
    users: zod_1.z.array(shared_1.UserSchema),
    maxCount: zod_1.z.number(),
});
