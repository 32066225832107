"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SportEventUserSchema = exports.SportEventUserStatus = void 0;
var zod_1 = require("zod");
var user_1 = require("./user");
var SportEventUserStatus;
(function (SportEventUserStatus) {
    SportEventUserStatus["Pending"] = "pending";
    SportEventUserStatus["Rejected"] = "rejected";
    SportEventUserStatus["Approved"] = "approved";
})(SportEventUserStatus || (exports.SportEventUserStatus = SportEventUserStatus = {}));
exports.SportEventUserSchema = zod_1.z.object({
    id: zod_1.z.string(),
    status: zod_1.z.nativeEnum(SportEventUserStatus),
    user: user_1.UserSchema
});
