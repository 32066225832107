import { User } from "@apps/sdk";
import { State } from "@packages/core-shared";
import { parse } from "../utils/json.utils";
import { USER_STORAGE_KEY } from "../app-constants";

const persistedUserData = localStorage.getItem(USER_STORAGE_KEY);

export const userState = new State<User | undefined>(
  persistedUserData ? parse<User>(persistedUserData) : undefined
);
