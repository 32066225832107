import clsx from "clsx";
import { EditorState, convertToRaw, ContentState } from "draft-js";

import draftToHtml from "draftjs-to-html";

import htmlToDraft from "html-to-draftjs";
import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";

import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import "./EditorConvertToHTML.css";

type EditorConvertToHTMLProps = Readonly<{
  html: string | undefined;
  error?: boolean;
  onChange: (html: string) => void;
}>;

const EditorConvertToHTML: React.FC<EditorConvertToHTMLProps> = ({
  html,
  error,
  onChange,
}) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [initialLoaded, setInitalLoaded] = useState(false);

  const handleEditorStateChange = (editorState: EditorState): void => {
    setEditorState(editorState);
    onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  useEffect(() => {
    if (!html || initialLoaded) {
      return;
    }

    const contentBlock = htmlToDraft(html);

    if (!contentBlock) {
      return;
    }

    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks
    );
    const newEditorState = EditorState.createWithContent(contentState);

    setEditorState(newEditorState);
    setInitalLoaded(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [html]);

  return (
    <Editor
      editorState={editorState}
      wrapperClassName="wrapper"
      toolbarClassName={clsx("toolbar", error && "error")}
      editorClassName={clsx("editor", error && "error")}
      onEditorStateChange={handleEditorStateChange}
    />
  );
};

export default EditorConvertToHTML;
