import { StateInterceptor } from "react-reactive-tools";
// import { customStorage, jsonUtils } from "../../utils";

// TODO

export const persistInterceptor: StateInterceptor = {
  onInit: async (stateName, _value, setState) => {
    try {
      //const persistedValue = await customStorage.get(stateName);
      const persistedValue = "";
      const parsedValue = "";

      if (!persistedValue) {
        return;
      }

      //   const parsedValue = jsonUtils.parse(persistedValue);

      parsedValue && setState(parsedValue);
    } catch {
      return;
    }
  },
  onSet: async (stateName, value) => {
    //await customStorage.set(stateName, jsonUtils.stringify(value));
  },
};
