import { Stars } from "@mui/icons-material";
import { Grid, Input } from "@mui/material";

import EventUpload from "../../../../components/ImageUpload/EventUpload";
import { ImageUploadComponentProps } from "../../../../components/ImageUpload/withImageUpload";

import TextField from "../../../../components/TextField";

import styles from "./EditSettingsHeaderPicture.module.scss";
import { ErrorMessage } from "formik";

type EditSettingsHeaderPictureProps = Readonly<{
  name: string;
  photoType: string;
  imgFileFieldName?: ImageUploadComponentProps["imgFileFieldName"];
  imgSourceFieldName?: ImageUploadComponentProps["imgSourceFieldName"];
}>;

const EditSettingsHeaderPicture: React.FC<EditSettingsHeaderPictureProps> = ({
  name,
  photoType,
  imgSourceFieldName,
  imgFileFieldName,
}) => {
  return (
    <>
      {imgFileFieldName && imgSourceFieldName && (
        <>
          <EventUpload
            imgSourceFieldName={imgSourceFieldName}
            imgFileFieldName={imgFileFieldName}
            imgAltText={name}
            circularCrop
            noProcess={true}
          />

          {/* <ErrorMessage name={name} /> */}
        </>
      )}
    </>
  );
};

export default EditSettingsHeaderPicture;
