import { UserType } from "@apps/sdk";
import ContentBackgroundContainer from "../../components/ContentBackgroundContainer";
import ContentHeading from "../../components/ContentHeading";
import { useGetSportEventsQuery, useGetUsersQuery } from "../../query-hooks";
import { useEffect } from "react";
import { useReactiveState } from "../../hooks";
import { userState } from "../../state";
import { ContentBlock } from "draft-js";
import ContentContainer from "../../components/ContentContainer";
import EventData from "./EventData";
import { useParams } from "react-router-dom";

const Event = () => {
  const { id } = useParams<{ id: string }>();

  const { getUsers, data: userData } = useGetUsersQuery();

  const { current: currentUser } = useReactiveState(userState);
  const { getSportEvents, data: events } = useGetSportEventsQuery();

  const user = userData?.users[0];

  useEffect(() => {
    getUsers({
      pagination: {
        page: 0,
        rowsPerPage: 1,
      },
      filter: {
        id: currentUser?.id || "",
      },
    });
  }, []);

  useEffect(() => {
    if (!id) {
      return;
    }

    getSportEvents({
      pagination: {
        page: 0,
        rowsPerPage: 1,
      },
      filter: {
        id: id,
      },
    });
  }, [id]);

  const eventsData = events?.sportEvents[0];

  return (
    <ContentBackgroundContainer>
      <ContentHeading
        photoUrl={eventsData?.createdBy.photoUrl || ""}
        name={eventsData?.createdBy?.fullName || ""}
        userType={eventsData?.createdBy?.type || UserType.Volunteer}
        createdById={eventsData?.createdBy?.id}
      />

      {eventsData ? <EventData data={eventsData} /> : null}
    </ContentBackgroundContainer>
  );
};

export default Event;
