import styles from "./ContentBackgroundContainer.module.scss";
import { ReactNode } from "react";

type ContentBackgroundProps = Readonly<{
  children: ReactNode;
  className?: string;
}>;

const ContentBackgroundContainer = ({
  children,
  className,
}: ContentBackgroundProps) => {
  return <div className={`${styles.container} ${className}`}>{children}</div>;
};

export default ContentBackgroundContainer;
