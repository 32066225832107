"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoginResponseSchema = exports.LoginRequestSchema = void 0;
var zod_1 = require("zod");
var shared_1 = require("../shared");
exports.LoginRequestSchema = zod_1.z.object({
    email: zod_1.z.string().email(),
    password: zod_1.z.string().min(3)
});
exports.LoginResponseSchema = zod_1.z.object({
    user: shared_1.UserSchema
}).merge(shared_1.JwtResponseSchema);
