import {
  Facebook,
  Instagram,
  LinkedIn,
  Rowing,
  Twitter,
  YouTube,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import styles from "./Footer.module.scss";

import speroLogo from "../../assets/logo.svg";

import bamfLogo from "../../assets/bamf-logo.svg";
import euLogo from "../../assets/eu-logo.svg";
import minifutballLogo from "../../assets/minifutbol-logo.svg";
import openFootballLogo from "../../assets/open_football-logo.svg";

import facebookIcon from "../../assets/facebook-icon.svg";
import linkedinIcon from "../../assets/linkedin-icon.svg";
import twitterIcon from "../../assets/twitter-icon.svg";
import youtubeIcon from "../../assets/youtube-icon.svg";

const Footer: React.FC = () => {
  const pagesData = [
    { title: "About", slug: "about" },
    { title: "Blog", slug: "blog" },
    { title: "Terms of Use", slug: "terms-of-use" },
    { title: "Privacy Policy", slug: "privacy-policy" },
    { title: "Contact", slug: "contact" },
  ];

  return (
    <div className={styles.footer}>
      <div className={styles.footerTop}>
        <div className={styles.imageContainer}>
          <img src={euLogo} alt="eu-logo" className={styles.euLogo} />
          <img
            src={openFootballLogo}
            alt="open-football-logo"
            className={styles.openFootballLogo}
          />
          <img src={bamfLogo} alt="bamf-logo" className={styles.bamfLogo} />
          <img
            src={minifutballLogo}
            alt="minifutball-logo"
            className={styles.minifutballLogo}
          />
        </div>
      </div>

      <div className={styles.footerBottom}>
        <div className={styles.footerBottomContainer}>
          <div className={styles.footerBottomLeft}>
            <img
              src={speroLogo}
              alt="spero-logo"
              className={styles.footerLogo}
            />
            <div className={styles.copyright}>&copy; 2023 SPERO Platform</div>
          </div>

          <div className={styles.footerBottomRight}>
            <nav className={styles.navigation}>
              {pagesData.map((p) => (
                <Link to={`/${p.slug}`} key={p.slug}>
                  {p.title}
                </Link>
              ))}
            </nav>

            <div className={styles.socialIcons}>
              <img
                src={facebookIcon}
                alt="facebook-icon"
                className={styles.socialIcon}
              />
              <img
                src={twitterIcon}
                alt="twitter-icon"
                className={styles.socialIcon}
              />
              <img
                src={linkedinIcon}
                alt="linkedin-icon"
                className={styles.socialIcon}
              />
              <img
                src={youtubeIcon}
                alt="youtube-icon"
                className={styles.socialIcon}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
