import styles from "./GreenTips.module.scss";
import greenLeaf from "./greenLeaf.svg";
import { Link } from "react-router-dom";

const GreenTips = () => {
  return (
    <Link to={`/green-tips-and-practices`}>
      <div className={styles.container}>
        <div className={styles.innerContainer}>
          <img src={greenLeaf}></img>

          <div className={styles.textContainer}>
            <span className={styles.topText}>Click here to learn what are</span>
            <span className={styles.bottomText}>
              OUR BEST GREEN TIPS AND PRACTICES!
            </span>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default GreenTips;
