interface IconProps {
  iconClassName?: string;
}

const VideoIcon: React.FC<IconProps> = ({ iconClassName }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="25"
      viewBox="0 0 19 25"
      fill="none"
      className={iconClassName}
    >
      <path
        d="M18.667 5.928V6.223H12.444V0H12.739C13.0485 0.000102691 13.3452 0.123121 13.564 0.342L18.325 5.1C18.5439 5.31878 18.6669 5.61553 18.667 5.925V5.928ZM10.889 6.611V0H1.167C0.857492 0 0.560661 0.122951 0.341806 0.341806C0.122951 0.560661 0 0.857492 0 1.167L0 23.722C0 24.0315 0.122951 24.3283 0.341806 24.5472C0.560661 24.766 0.857492 24.889 1.167 24.889H17.5C17.6533 24.889 17.805 24.8588 17.9466 24.8002C18.0882 24.7415 18.2168 24.6556 18.3252 24.5472C18.4336 24.4388 18.5195 24.3102 18.5782 24.1686C18.6368 24.027 18.667 23.8753 18.667 23.722V7.778H12.056C11.7467 7.77721 11.4504 7.654 11.2317 7.43532C11.013 7.21664 10.8898 6.92026 10.889 6.611ZM15.556 13.611V19.054C15.5559 19.2848 15.4874 19.5103 15.3592 19.7021C15.231 19.894 15.0487 20.0435 14.8355 20.1318C14.6223 20.2201 14.3878 20.2432 14.1614 20.1982C13.9351 20.1532 13.7272 20.0421 13.564 19.879L10.889 17.205V19.055C10.889 19.3645 10.766 19.6613 10.5472 19.8802C10.3283 20.099 10.0315 20.222 9.722 20.222H4.278C3.96849 20.222 3.67166 20.099 3.45281 19.8802C3.23395 19.6613 3.111 19.3645 3.111 19.055V13.611C3.111 13.3015 3.23395 13.0047 3.45281 12.7858C3.67166 12.567 3.96849 12.444 4.278 12.444H9.722C10.0315 12.444 10.3283 12.567 10.5472 12.7858C10.766 13.0047 10.889 13.3015 10.889 13.611V15.461L13.564 12.787C13.7272 12.6239 13.9351 12.5128 14.1614 12.4678C14.3878 12.4228 14.6223 12.4459 14.8355 12.5342C15.0487 12.6225 15.231 12.772 15.3592 12.9639C15.4874 13.1557 15.5559 13.3812 15.556 13.612V13.611Z"
        fill="#52559E"
      />
    </svg>
  );
};

export default VideoIcon;
