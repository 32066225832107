export var ErrorStatusCode;
(function (ErrorStatusCode) {
    ErrorStatusCode[ErrorStatusCode["Validation"] = 400] = "Validation";
    ErrorStatusCode[ErrorStatusCode["NotAuthorized"] = 401] = "NotAuthorized";
    ErrorStatusCode[ErrorStatusCode["ServerError"] = 500] = "ServerError";
})(ErrorStatusCode || (ErrorStatusCode = {}));
var Upload = /** @class */ (function () {
    function Upload(file) {
        this.file = file;
    }
    return Upload;
}());
export { Upload };
