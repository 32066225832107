import { createState } from "react-reactive-tools";
// import { Notification } from "graphql/generated";

type SubscriptionState = Readonly<{
  isConnected: boolean;
}>;

export const subscriptionState = createState<SubscriptionState>(
  "subscription",
  {
    isConnected: false,
  }
);

// export const notificationsState = createState<Notification[]>('notifications', [])

export const isOnlineState = createState<boolean>("isOnline", true);
