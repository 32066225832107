import AlertIcon from "../../assets/icons/AlerIcon"; //../../assets/icons/AlertIcon/
import styles from "./SuccessfullyApplied.module.scss";

const SuccessfullyApplied = () => {
  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <div className={styles.title}>
          You successfully applied for this sport event!
        </div>
      </div>
      <div className={styles.iconContainer}>
        <AlertIcon />
      </div>
      <div className={styles.textContainer}>
        <div className={styles.text}>
          Attention! You will receive an email or phone call if you are approved
          to volunteer, as well as detailed information about the event.
        </div>
      </div>
    </div>
  );
};

export default SuccessfullyApplied;
