import { useCallback, useEffect, useState } from "react";
import ContentBackgroundContainer from "../../components/ContentBackgroundContainer";
import { useGetBlogsQuery } from "../../query-hooks";
import styles from "./Blog.module.scss";
import BlogList from "./BlogList";
import { useParams } from "react-router-dom";
import BlogPost from "./BlogPostContent";
import BlogPostContent from "./BlogPostContent";
import { Pagination } from "@mui/material";
import { BlogStatus } from "@apps/sdk";

const Blog = () => {
  const { slug } = useParams<{ slug: string }>();

  const isPost = !!slug;

  const [activePage, setActivePage] = useState(1);

  const { getBlogs, data: blogs } = useGetBlogsQuery();
  const [paginationCount, setPaginationCount] = useState(0);

  const [typeFilterValue, setTypeFilterValue] = useState({ type: "" });
  const [pageSize, setPageSize] = useState(6);

  const maxCount = blogs?.maxCount || 0;

  useEffect(() => {
    getBlogs({
      pagination: {
        page: 0,
        rowsPerPage: pageSize,
      },
      filter: {
        status: BlogStatus.Published,
      },
    });
  }, []);

  useEffect(() => {
    if (maxCount !== 0) {
      setPaginationCount(Math.ceil(maxCount / pageSize));
    }
  }, [maxCount]);

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) =>
    setActivePage(value);

  useEffect(() => {
    getBlogs({
      pagination: {
        page: activePage - 1,
        rowsPerPage: pageSize,
      },
      filter: {
        status: BlogStatus.Published,
      },
    });
  }, [activePage]);

  return (
    <ContentBackgroundContainer>
      <div className={styles.titleContainer}>
        <div className={styles.title}>GET INSPIRATION FROM OUR BLOG</div>
        <div className={styles.subTitle}>
          Volunteers do not necessarily have the time... they just have the
          heart.
        </div>
      </div>

      {isPost ? (
        <BlogPostContent />
      ) : (
        <>
          <div className={styles.blogContainer}>
            {blogs?.blogs ? <BlogList blogPosts={blogs} /> : null}
          </div>

          <div className={styles.paginationContainer}>
            <Pagination
              count={paginationCount}
              page={activePage}
              onChange={handlePageChange}
              size="small"
            />
          </div>
        </>
      )}
    </ContentBackgroundContainer>
  );
};

export default Blog;
