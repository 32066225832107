import TextArrow from "../../../components/TextArrow/TextArrow";
import { useReactiveState, useTranslations } from "../../../hooks";

import styles from "./Headline.module.scss";

import { path } from "../../../router";
import { authModalState, userState } from "../../../state";
import { Link } from "react-router-dom";

const Headline = () => {
  const { translate } = useTranslations();
  const { current: user } = useReactiveState(userState);

  const openRegisterModal = () =>
    authModalState.set({
      ...authModalState.get(),
      registerModal: true,
    });

  return (
    <>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.title}>
            {translate((t) => t.HOME_PAGE_HEADING)}
          </div>
          <div className={styles.subtitle}>
            {translate((t) => t.HOME_PAGE_HEADING_DESCRIPTION)}
          </div>
          <div className={styles.callToAction}>
            {user?.type ? (
              <Link to={path.events}>
                <button className={styles.button}>
                  {translate((t) => t.GET_STARTED_NOW)}
                </button>
              </Link>
            ) : (
              <button className={styles.button} onClick={openRegisterModal}>
                {translate((t) => t.GET_STARTED_NOW)}
              </button>
            )}

            <TextArrow
              caption={translate((t) => t.BROWSE_SPORT_EVENTS)}
              size="large"
              linkUrl={path.events}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Headline;
