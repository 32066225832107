import TextArrow from "../../TextArrow/TextArrow";
import styles from "./BlogPostLatest.module.scss";

import { getDateDay, getDateMonth } from "../../../utils/dates/getDateBlogPost";

import parseHtml from "html-react-parser";

type BlogPostProps = {
  title: string;
  description: string;
  date: string;
  thumbnailIMG: string;
  slug: string;
};

const BlogPostLatest = ({
  title,
  description,
  date,
  thumbnailIMG,
  slug,
}: BlogPostProps) => {
  const dateDay = getDateDay(date);
  const dateMonthName = getDateMonth(date);

  // const divStyle = {
  //   backgroundImage: `url(${thumbnailIMG})`,
  //   backgroundSize: "contain",
  //   backgroundPosition: "center",
  //   backgroundRepeat: "no-repeat",
  // };

  const divStyle = {
    backgroundImage: `url(${thumbnailIMG})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    // backgroundPositionY: "top",
  };

  // const desc = parseHtml(description) as string;

  // let subDesc = "";

  // if (desc.length > 150) {
  //   subDesc = desc.substring(0, 150) + "...";
  // } else {
  //   subDesc = desc.substring(0, desc.length);
  // }

  return (
    <div className={styles.container}>
      <div className={styles.imageContainer}>
        {/* <img src={thumbnailIMG} className={styles.image} /> */}
        <div style={divStyle} className={styles.photoContainer}></div>
      </div>

      <div className={styles.infoContainer}>
        <div className={styles.date}>
          <div className={styles.dateDay}>{dateDay}</div>
          <div className={styles.dateMonth}>{dateMonthName}</div>
        </div>

        <div className={styles.titleContainer}>
          <div className={styles.title}>{title} </div>
          <div className={styles.description}>{parseHtml(description)}</div>
          <TextArrow caption={"learn more"} size="small" linkUrl={slug} />
        </div>
      </div>
    </div>
  );
};

export default BlogPostLatest;
