"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetPagesResponseSchema = exports.GetPagesRequestSchema = void 0;
var zod_1 = require("zod");
var shared_1 = require("../shared");
exports.GetPagesRequestSchema = zod_1.z.object({
    pagination: shared_1.PaginationSchema,
    filter: zod_1.z.object({
        id: zod_1.z.string().optional(),
        slug: zod_1.z.string().optional(),
        title: zod_1.z.string().optional(),
        status: zod_1.z.nativeEnum(shared_1.PageStatus).optional()
    }).optional()
});
exports.GetPagesResponseSchema = zod_1.z.object({
    pages: zod_1.z.array(shared_1.PageSchema),
    maxCount: zod_1.z.number(),
});
