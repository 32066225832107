import {
  Query,
  UpdateSportEventUserStatusResponse,
  UpdateSportEventUserStatusRequest,
} from "@apps/sdk";
import { useCallback } from "react";
import { useQueryState } from "./common";
import { frontofficeService } from "../services";
import { toast } from "react-toastify";

export const useUpdateSportEventUserStatusQuery = () => {
  const { data, error, loading, setState, setLoading } =
    useQueryState<UpdateSportEventUserStatusResponse>();

  const updateSportEventUserStatus = useCallback(
    async (payload: UpdateSportEventUserStatusRequest) => {
      setLoading(true);

      setState(
        await frontofficeService.query({
          query: Query.UpdateSportEventUserStatus,
          payload,
          onSuccess: (response: UpdateSportEventUserStatusResponse) => {
            toast.success("Changes saved", {
              position: "top-right",
            });
          },
          onError: (errorResponse: any) => {
            errorResponse.errors.forEach(
              (error: any) => toast.error(error.message),
              {
                position: "top-right",
              }
            );
          },
        })
      );

      setLoading(false);
    },
    []
  );

  return {
    updateSportEventUserStatus,
    data,
    error,
    loading,
  };
};
