interface IconProps {
  iconClassName?: string;
}

const LinkedInIcon: React.FC<IconProps> = ({ iconClassName }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
      className={iconClassName}
    >
      <path
        d="M5.15198 18.55H1.30598V6.165H5.15198V18.55ZM3.22698 4.475C2.78435 4.47697 2.35108 4.34765 1.98195 4.10338C1.61282 3.85911 1.32442 3.51087 1.15321 3.10269C0.981994 2.69451 0.935666 2.24473 1.02008 1.81022C1.10449 1.37571 1.31586 0.97599 1.62744 0.661606C1.93903 0.347222 2.33685 0.132294 2.77058 0.0440008C3.20432 -0.0442925 3.6545 -0.00198562 4.06419 0.165572C4.47389 0.333129 4.82469 0.618411 5.07225 0.985342C5.31981 1.35227 5.453 1.78437 5.45498 2.227C5.45553 2.81992 5.2216 3.389 4.80423 3.81012C4.38685 4.23125 3.81988 4.47025 3.22698 4.475ZM19.546 18.55H15.709V12.521C15.709 11.084 15.68 9.242 13.709 9.242C11.709 9.242 11.403 10.803 11.403 12.418V18.55H7.56098V6.165H11.25V7.854H11.3C11.6688 7.22257 12.2021 6.70306 12.8429 6.35084C13.4838 5.99863 14.2082 5.82691 14.939 5.854C18.831 5.854 19.547 8.417 19.547 11.746V18.546L19.546 18.55Z"
        fill="#CCCCCC"
      />
    </svg>
  );
};

export default LinkedInIcon;
