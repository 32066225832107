"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApplyForSportEventResponseSchema = exports.ApplyForSportEventRequestSchema = void 0;
var zod_1 = require("zod");
exports.ApplyForSportEventRequestSchema = zod_1.z.object({
    eventId: zod_1.z.string()
});
exports.ApplyForSportEventResponseSchema = zod_1.z.object({
    success: zod_1.z.boolean()
});
