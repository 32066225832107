import styles from "./MyProfile.module.scss";
import ContentContainer from "../../components/ContentContainer";
import TextField from "../../components/TextField";
import { FormikProps, useFormikContext, withFormik } from "formik";
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  LinearProgress,
  Switch,
} from "@mui/material";

import ContentButtons from "../../components/ContentFooterButtons";
import EditorConvertToHTML from "../../components/EditorConvertToHTML";

import { InputAdornment } from "@mui/material";

import { useEffect, useMemo, useState } from "react";
import { validationSchema } from "./MyProfile.validation";
import { deviceTypeState } from "../../state";
import { useTranslations } from "../../hooks";

import WebSiteIcon from "../../assets/icons/WebSiteIcon";
import FacebookIcon from "../../assets/icons/FacebookIcon";
import TwitterIcon from "../../assets/icons/TwitterIcon";
import LinkedInIcon from "../../assets/icons/LinkedInIcon";
import YouTubeIcon from "../../assets/icons/YouTubeIcon";
import LocationIcon from "../../assets/icons/LocationIcon";
import PhoneIcon from "../../assets/icons/PhoneIcon";
import LockIconInner from "../../assets/icons/LockIconInner";
import { GetUsersResponse, UserStatus, UserType } from "@apps/sdk";
import { Upload } from "@packages/core-shared";
import { useGetCountriesQuery } from "../../query-hooks";
import EditFormHeader from "./EditFormHeader";

export type EditUserFormValues = {
  fullName: string;
  description?: string;
  email: string;
  type: UserType;
  country?: { name: string; id: string } | undefined;
  phoneNumber?: string;
  photo?: Upload | undefined;
  photoUrl?: string;
  logoFile?: File;
  website?: string;
  facebook?: string;
  xaccount?: string;
  linkedin?: string;
  youtube?: string;
  oldPassword?: string;
  newPassword?: string;
};

const DEFAULT_VALUES: EditUserFormValues = {
  fullName: "",
  description: "",
  email: "",
  country: { id: "", name: "" },
  type: UserType.Volunteer,
  phoneNumber: "",
  photoUrl: "",
  website: "",
  facebook: "",
  xaccount: "",
  linkedin: "",
  youtube: "",
};

type EditUserProps = Readonly<{
  data:
    | (GetUsersResponse["users"][0] & { photo?: Upload | undefined })
    | undefined;
  onSubmit: (values: EditUserFormValues) => void;
  isEdit: boolean;
}>;

type EditUserFormProps = FormikProps<EditUserFormValues> & EditUserProps;

const EditUserForm: React.FC<EditUserFormProps> = (props) => {
  const { isDesktop } = deviceTypeState.get();
  const { translate } = useTranslations();

  const {
    handleSubmit,
    values: {
      fullName,
      phoneNumber,
      oldPassword,
      newPassword,
      description,
      website,
      facebook,
      xaccount,
      linkedin,
      youtube,
      country,
      photoUrl,
      logoFile,
      type,
    },
    errors,
    data,
    isEdit,
  } = props;

  const [isNewPasswordEditable, setIsNewPasswordEditable] = useState(false);

  useEffect(() => {
    if (oldPassword) {
      if (oldPassword.length >= 3) {
        setIsNewPasswordEditable(true);
      } else {
        setIsNewPasswordEditable(false);
      }
    }
  }, [oldPassword]);

  const { setFieldValue, submitForm, initialValues } = useFormikContext();

  const {
    getCountries,
    data: countriesData,
    error,
    loading,
  } = useGetCountriesQuery();

  useEffect(() => {
    getCountries({
      pagination: {
        page: 0,
        rowsPerPage: 100,
      },
    });
  }, []);

  const countryOptions = countriesData?.countries.map((country) => ({
    name: country.name,
    value: country.id,
  }));

  useEffect(() => {
    if (!data) {
      return;
    }

    setFieldValue("countryId", country?.id);

    data?.socials?.links.map((link) => {
      setFieldValue(link.name, link.url);
    });
  }, [initialValues]);

  const socialLinks = {
    links: [
      { name: "website", url: website },
      { name: "facebook", url: facebook },
      { name: "xaccount", url: xaccount },
      { name: "linkedin", url: linkedin },
      { name: "youtube", url: youtube },
    ],
  };

  useEffect(() => {
    setFieldValue("socials", socialLinks);
  }, [website, facebook, xaccount, linkedin, youtube]);

  useEffect(() => {
    if (!logoFile) {
      return;
    }

    const photoPayload = new Upload(logoFile);
    setFieldValue("photo", photoPayload);
  }, [logoFile]);

  const handleCountryChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const selectedCountryValue = event.target.value;
    const selectedCountry =
      countryOptions &&
      countryOptions.find((country) => country.value === selectedCountryValue);

    setFieldValue("country", {
      id: selectedCountry?.value,
      name: selectedCountry?.name,
    });

    setFieldValue("countryId", selectedCountry?.value);
  };

  return (
    <ContentContainer>
      {/* {!countryData ? (
        <LinearProgress />
      ) : ( */}
      <form onSubmit={handleSubmit} noValidate>
        <div className={styles.content}>
          <div className={styles.leftColumn}>
            <span className={styles.title}>
              {translate((t) => t.PROFILE_SETTINGS)}
            </span>

            <EditFormHeader
              name={fullName}
              photoType="Profile"
              imgSourceFieldName="photoUrl"
              imgFileFieldName="logoFile"
              role={data?.type}
            />

            <TextField
              value={fullName}
              name="fullName"
              label={
                type === UserType.Volunteer
                  ? translate((t) => t.FULL_NAME)
                  : translate((t) => t.ORGANIZATION_NAME)
              }
              className={styles.field}
            />

            <div className={styles.userInputContainer}>
              <TextField
                name="countryId"
                label={translate((t) => t.SELECT_COUNTRY)}
                select
                options={countryOptions}
                className={`${styles.field} ${styles.halfInput}`}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocationIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => handleCountryChange(e)}
              />

              <TextField
                value={phoneNumber}
                name="phoneNumber"
                className={`${styles.field} ${styles.halfInput}`}
                label={translate((t) => t.PHONE_NUMBER)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PhoneIcon />
                    </InputAdornment>
                  ),
                }}
              />

              <TextField
                value={oldPassword}
                name="oldPassword"
                type="password"
                className={`${styles.field} ${styles.halfInput}`}
                label={translate((t) => t.TYPE_OLD_PASSWORD)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockIconInner />
                    </InputAdornment>
                  ),
                }}
              />

              <TextField
                value={newPassword}
                name="newPassword"
                label={translate((t) => t.TYPE_NEW_PASSWORD)}
                type="password"
                className={`${styles.field} ${styles.halfInput}`}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockIconInner />
                    </InputAdornment>
                  ),
                }}
                disabled={!isNewPasswordEditable}
              />
            </div>

            <span className={styles.sectionNameFirst}>
              {translate((t) => t.ABOUT_ME)}
            </span>

            <FormControl>
              <EditorConvertToHTML
                html={description || ""}
                error={!!errors.description}
                onChange={(newVal: string) =>
                  setFieldValue("description", newVal)
                }
              />

              {errors.description && (
                <FormHelperText error>{errors.description}</FormHelperText>
              )}
            </FormControl>

            <span className={styles.sectionName}>
              {translate((t) => t.PUBLIC_LINKS)}
            </span>

            <div className={styles.socialFieldContainer}>
              <WebSiteIcon iconClassName={styles.socialIcon} />
              <TextField
                name="website"
                label={translate((t) => t.TYPE_URL_ADDRESS)}
                className={styles.socialField}
                type={"url"}
              />
            </div>

            <div className={styles.socialFieldContainer}>
              <FacebookIcon iconClassName={styles.socialIcon} />
              <TextField
                name="facebook"
                label={translate((t) => t.TYPE_URL_ADDRESS)}
                className={styles.socialField}
                type={"url"}
              />
            </div>

            <div className={styles.socialFieldContainer}>
              <TwitterIcon iconClassName={styles.socialIcon} />
              <TextField
                name="xaccount"
                label={translate((t) => t.TYPE_URL_ADDRESS)}
                className={styles.socialField}
                type={"url"}
              />
            </div>

            <div className={styles.socialFieldContainer}>
              <LinkedInIcon iconClassName={styles.socialIcon} />
              <TextField
                name="linkedin"
                label={translate((t) => t.TYPE_URL_ADDRESS)}
                className={styles.socialField}
                type={"url"}
              />
            </div>

            <div className={styles.socialFieldContainer}>
              <YouTubeIcon iconClassName={styles.socialIcon} />
              <TextField
                name="youtube"
                label={translate((t) => t.TYPE_URL_ADDRESS)}
                className={styles.socialField}
                type={"url"}
              />
            </div>

            <span className={styles.sectionName}>
              {translate((t) => t.PRIVACY_SETTINGS)}
            </span>

            <FormGroup>
              <FormControlLabel
                control={<Switch defaultChecked />}
                label={translate(
                  (t) => t.STORE_AND_ACCESS_INFORMATION_ON_DEVICE
                )}
                className={styles.privacySwitch}
              />
              <FormControlLabel
                control={<Switch className={styles.privacySwitch} />}
                label={translate((t) => t.STRICTLY_NECESSARY_COOKIES)}
              />
            </FormGroup>
          </div>
        </div>
        <ContentButtons type="submit" submitType="SaveChanges" />
      </form>
    </ContentContainer>
  );
};

const EditUserFormWrapper = withFormik<EditUserProps, EditUserFormValues>({
  mapPropsToValues: ({ data }) => {
    if (!data) {
      return DEFAULT_VALUES;
    }

    const {
      id,
      description,
      fullName,
      type,
      email,
      country,
      phoneNumber,
      socials,
      photoUrl,
      photo,
    } = data;

    return {
      ...DEFAULT_VALUES,
      id,
      fullName,
      description: description || DEFAULT_VALUES.description,
      country: country || DEFAULT_VALUES.country,
      email,
      type,
      phoneNumber: phoneNumber || DEFAULT_VALUES.phoneNumber,
      socials: socials || { links: [] },
      photo,
      photoUrl: photoUrl as string,
    };
  },
  validationSchema,
  enableReinitialize: true,
  handleSubmit: (values, { props: { onSubmit } }) => {
    const {
      website,
      facebook,
      xaccount,
      linkedin,
      youtube,
      photoUrl,
      logoFile,
      ...rest
    } = values;
    onSubmit(rest);
  },
  displayName: "EditUserForm",
})(EditUserForm);

export default EditUserFormWrapper;
