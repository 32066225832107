"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UploadSchema = exports.PaginationSchema = void 0;
var core_shared_1 = require("@packages/core-shared");
var zod_1 = require("zod");
exports.PaginationSchema = zod_1.z.object({
    page: zod_1.z.number().min(0),
    rowsPerPage: zod_1.z.number().min(1).max(100)
});
exports.UploadSchema = zod_1.z.instanceof(core_shared_1.Upload);
