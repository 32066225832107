"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PageSchema = exports.PageStatus = void 0;
var zod_1 = require("zod");
var PageStatus;
(function (PageStatus) {
    PageStatus["Hidden"] = "hidden";
    PageStatus["Published"] = "published";
})(PageStatus || (exports.PageStatus = PageStatus = {}));
exports.PageSchema = zod_1.z.object({
    id: zod_1.z.string(),
    title: zod_1.z.string(),
    slug: zod_1.z.string(),
    text: zod_1.z.string(),
    imageUrl: zod_1.z.string().nullish(),
    status: zod_1.z.nativeEnum(PageStatus),
    orderNumber: zod_1.z.number()
});
