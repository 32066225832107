import {
  Query,
  GetCertificatesResponse,
  GetCertificatesRequest,
} from "@apps/sdk";
import { useCallback } from "react";
import { useQueryState } from "./common";
import { frontofficeService } from "../services";

export const useGetCertificatesQuery = () => {
  const { data, error, loading, setState, setLoading } =
    useQueryState<GetCertificatesResponse>();

  const getCertificates = useCallback(
    async (payload: GetCertificatesRequest) => {
      setLoading(true);

      setState(
        await frontofficeService.query({
          query: Query.GetCertificates,
          payload,
        })
      );

      setLoading(false);
    },
    []
  );

  return {
    getCertificates,
    data,
    error,
    loading,
  };
};
