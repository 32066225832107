import moment from "moment";
import styles from "./Certificate.module.scss";
import { getBase64FromUrl, getFileSrc } from "../../utils";
import { useEffect, useState } from "react";

type CertificateProps = {
  refTarget: React.MutableRefObject<any>;
  name: string;
  eventName: string;
  manager: string;
  organizationLogo?: string;
};

const Certificate: React.FC<CertificateProps> = ({
  refTarget,
  name,
  eventName,
  manager,
  organizationLogo,
}) => {
  const issueDate = moment().format("DD/MM/YYYY");

  const [organizationLogoData, setOrganizationLogoData] = useState<string | undefined>(undefined)

  useEffect(() => {
    if (!organizationLogo) {
      return;
    }

    getBase64FromUrl(getFileSrc(organizationLogo)).then(setOrganizationLogoData)
  }, [organizationLogo])

  return (
    <div className={styles.container} ref={refTarget}>
      <div className={styles.textContainer}>
        <div className={styles.name}>{name}</div>
        <div className={styles.eventName}>{eventName}</div>
        <div className={styles.manager}>{manager}</div>
        {organizationLogoData && (
          <div
            className={styles.logo}
            style={{ backgroundImage: `url(${organizationLogoData})` }}
          ></div>
        )}
        <div className={styles.issueDate}>{issueDate}</div>
      </div>
    </div>
  );
};

export default Certificate;
