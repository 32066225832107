import { EventBoxSlideProps } from "./EventBoxSlide.types";

import { getEventContinuity } from "../../../utils/dates/getEventContinuity";

import clsx from "clsx";

import { getPercentage } from "../../../utils/numbers";

import styles from "./EventBoxSlide.module.scss";
import ProgressBar from "../../ProgressBar";

import { Link } from "react-router-dom";

import { getFileSrc } from "../../../utils";
import { formatTimestamp } from "../../../utils/dates";

const EventBoxSlide = ({
  id,
  name,
  dateFrom,
  dateTo,
  country,
  city,
  volunteersCount,
  approvedVolunteersCount,
  imageUrl,
  className,
}: EventBoxSlideProps) => {
  const imageSourceLocal = getFileSrc(imageUrl || "");

  const divStyle = {
    backgroundImage: `url(${imageSourceLocal})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  return (
    <div className={clsx(styles.box, className)}>
      <div className={styles.title}>{name}</div>
      <span className={styles.subtitle}>
        <strong>
          {country.name}, {city}
        </strong>
      </span>
      <span className={styles.dates}>
        {getEventContinuity(
          formatTimestamp(Number(dateFrom)),
          formatTimestamp(Number(dateTo))
        )}
      </span>

      <div style={divStyle} className={styles.photoContainer}></div>

      <span className={styles.volunteersCount}>
        {approvedVolunteersCount} out of {volunteersCount} volunteers joined
      </span>

      {/* TODO: Can't change the backgroud color of the bar */}
      <ProgressBar
        value={getPercentage(approvedVolunteersCount || 0, volunteersCount)}
        participants={volunteersCount}
        // style={{ color: "#33BBC5" }}
        color="inherit"
        // variant="determinate"
        className={styles.progressBar}
        sx={{
          backgroundColor: " #ffffff", //"rgb(241,241,241, 0.1)",
          "& .MuiLinearProgress-bar": {
            backgroundColor: "#33BBC5",
          },
        }}
      />
      <Link to={`/event/${id}`} className={styles.seeDetailsButton}>
        See Details
      </Link>
    </div>
  );
};

export default EventBoxSlide;
