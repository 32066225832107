import { createTheme } from "@mui/material/styles";
import { red } from "@mui/material/colors";
import { inputClasses } from "@mui/material/Input";

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: "#0258b6",
    },
    secondary: {
      main: "#fff",
    },
    error: {
      main: "#c31616",
    },
  },
  typography: {
    fontFamily: ["Noto Sans", "Arial", "sans-serif"].join(","),
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          background: "#fff",
          fontSize: "14px",
          [`&:hover:not(${inputClasses.disabled}):not(${inputClasses.focused}):not(${inputClasses.error}) .MuiOutlinedInput-notchedOutline`]:
            {
              borderColor: "rgba(42, 51, 58, 0.4)",
            },
        },
        notchedOutline: {
          borderColor: "rgba(42, 51, 58, 0.2)",
          fontSize: "14px",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        outlined: {
          fontSize: "14px",
          "&.MuiInputLabel-shrink": {
            fontSize: "14px",
          },
          "&.MuiFormLabel-root": {
            color: "#999",
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          "& .MuiSwitch-thumb": {
            backgroundColor: "#687c8d",
          },
          "&.Mui-checked": {
            "& + .MuiSwitch-track": {
              backgroundColor: "#0258B6",
            },
            "& .MuiSwitch-thumb": {
              backgroundColor: "#0258B6",
            },
          },
          "& + .MuiSwitch-track": {
            opacity: 1,
            backgroundColor: "#aebcc7",
          },
        },
      },
    },
  },
});

export default theme;
