import { Query, GetBlogsResponse, GetBlogsRequest } from "@apps/sdk";
import { useCallback } from "react";
import { useQueryState } from "./common";
import { frontofficeService } from "../services";

export const useGetBlogsQuery = () => {
  const { data, error, loading, setState, setLoading } =
    useQueryState<GetBlogsResponse>();

  const getBlogs = useCallback(async (payload: GetBlogsRequest) => {
    setLoading(true);

    setState(
      await frontofficeService.query({
        query: Query.GetBlogs,
        payload,
      })
    );

    setLoading(false);
  }, []);

  return {
    getBlogs,
    data,
    error,
    loading,
  };
};
