import React from "react";

interface IconProps {
  iconClassName?: string;
}

const ShareIcon: React.FC<IconProps> = ({ iconClassName }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="24"
      viewBox="0 0 23 24"
      fill="none"
      className={iconClassName}
    >
      <path
        d="M19.071 16.9641C18.1958 16.968 17.3537 17.299 16.71 17.892L8.11998 12.892C8.18624 12.6158 8.22245 12.3331 8.22798 12.049C8.22245 11.765 8.18624 11.4823 8.11998 11.206L16.614 6.25405C17.2222 6.81679 18.0056 7.1529 18.8325 7.20585C19.6594 7.25881 20.4793 7.02537 21.1544 6.54481C21.8294 6.06424 22.3183 5.36588 22.5389 4.56718C22.7594 3.76848 22.6982 2.91818 22.3655 2.15931C22.0328 1.40044 21.4489 0.779306 20.712 0.400381C19.9752 0.0214556 19.1303 -0.0921371 18.3195 0.0787099C17.5087 0.249557 16.7814 0.694419 16.2601 1.33847C15.7388 1.98252 15.4552 2.78645 15.457 3.61505C15.4625 3.89912 15.4987 4.18176 15.565 4.45805L7.07098 9.41005C6.55656 8.92854 5.9126 8.60791 5.21833 8.48761C4.52406 8.36731 3.80976 8.45259 3.16332 8.73295C2.51689 9.01331 1.9665 9.47653 1.57989 10.0656C1.19328 10.6547 0.987305 11.3439 0.987305 12.0485C0.987305 12.7532 1.19328 13.4424 1.57989 14.0315C1.9665 14.6206 2.51689 15.0838 3.16332 15.3642C3.80976 15.6445 4.52406 15.7298 5.21833 15.6095C5.9126 15.4892 6.55656 15.1686 7.07098 14.687L15.65 19.7001C15.5864 19.9563 15.5528 20.2191 15.55 20.483C15.55 21.1788 15.7563 21.859 16.1429 22.4375C16.5294 23.0161 17.0789 23.467 17.7217 23.7333C18.3645 23.9995 19.0719 24.0692 19.7543 23.9335C20.4367 23.7977 21.0636 23.4627 21.5556 22.9707C22.0476 22.4787 22.3826 21.8518 22.5184 21.1694C22.6541 20.487 22.5845 19.7796 22.3182 19.1368C22.0519 18.4939 21.601 17.9445 21.0225 17.5579C20.4439 17.1714 19.7638 16.965 19.068 16.965L19.071 16.9641Z"
        fill="#DEDEDE"
      />
    </svg>
  );
};

export default ShareIcon;
