interface IconProps {
  iconClassName?: string;
}

const LockIconInner: React.FC<IconProps> = ({ iconClassName }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="22"
      viewBox="0 0 17 22"
      fill="none"
      className={iconClassName}
    >
      <path
        d="M14.667 7.333H13.619V5.233C13.619 3.84379 13.0671 2.51149 12.0848 1.52917C11.1025 0.546854 9.7702 -0.00500488 8.381 -0.00500488C6.9918 -0.00500488 5.65949 0.546854 4.67717 1.52917C3.69486 2.51149 3.143 3.84379 3.143 5.233V7.333H2.1C1.54305 7.333 1.0089 7.55424 0.615076 7.94807C0.221249 8.3419 0 8.87604 0 9.43299V19.9C0 20.4569 0.221249 20.9911 0.615076 21.3849C1.0089 21.7787 1.54305 22 2.1 22H14.667C15.224 22 15.7581 21.7787 16.1519 21.3849C16.5457 20.9911 16.767 20.4569 16.767 19.9V9.429C16.7659 8.87273 16.5442 8.33962 16.1505 7.94666C15.7568 7.55369 15.2233 7.33299 14.667 7.333ZM8.381 16.762C7.96566 16.762 7.55965 16.6388 7.2143 16.4081C6.86896 16.1773 6.5998 15.8494 6.44085 15.4656C6.28191 15.0819 6.24032 14.6597 6.32135 14.2523C6.40238 13.8449 6.60239 13.4708 6.89608 13.1771C7.18977 12.8834 7.56395 12.6834 7.97131 12.6023C8.37867 12.5213 8.80091 12.5629 9.18464 12.7218C9.56836 12.8808 9.89634 13.15 10.1271 13.4953C10.3578 13.8406 10.481 14.2467 10.481 14.662C10.481 14.9378 10.4267 15.2108 10.3211 15.4656C10.2156 15.7204 10.0609 15.9519 9.86592 16.1469C9.67092 16.3419 9.43942 16.4966 9.18464 16.6021C8.92985 16.7077 8.65678 16.762 8.381 16.762ZM11.629 7.333H5.129V5.233C5.129 4.8062 5.21306 4.38358 5.37639 3.98927C5.53972 3.59497 5.77911 3.23669 6.0809 2.9349C6.38269 2.63311 6.74097 2.39371 7.13528 2.23039C7.52959 2.06706 7.9522 1.983 8.379 1.983C8.8058 1.983 9.22841 2.06706 9.62272 2.23039C10.017 2.39371 10.3753 2.63311 10.6771 2.9349C10.9789 3.23669 11.2183 3.59497 11.3816 3.98927C11.5449 4.38358 11.629 4.8062 11.629 5.233V7.333Z"
        fill="#CCCCCC"
      />
    </svg>
  );
};

export default LockIconInner;
