"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BlogSchema = exports.BlogStatus = void 0;
var zod_1 = require("zod");
var BlogStatus;
(function (BlogStatus) {
    BlogStatus["Hidden"] = "hidden";
    BlogStatus["Published"] = "published";
})(BlogStatus || (exports.BlogStatus = BlogStatus = {}));
exports.BlogSchema = zod_1.z.object({
    id: zod_1.z.string(),
    title: zod_1.z.string(),
    slug: zod_1.z.string(),
    text: zod_1.z.string(),
    imageUrl: zod_1.z.string().nullish(),
    status: zod_1.z.nativeEnum(BlogStatus),
    createdAt: zod_1.z.string(),
});
