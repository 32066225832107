import Compressor from 'compressorjs';
import { addToast, ToastMessageType } from '../../utils';
import { resolveAssetsPath } from '../../services';

export const checkForAllowedMimeType = (mimeType: string, allowedMimeTypes: string[]) =>
    allowedMimeTypes.some((x) => x === mimeType);

interface CompressImageResult {
    readonly compressFile: File;
    readonly compressSrc: string;
}

const compressImage = async (file: File, options?: Compressor.Options): Promise<CompressImageResult> =>
    new Promise<CompressImageResult>((res, rej) => {
        new Compressor(file, {
            ...options,
            success(result) {
                const uploadedImageUrl = URL.createObjectURL(result);
                const fileResult = new File([result], file.name, { type: result.type });
                const data: CompressImageResult = {
                    compressFile: fileResult,
                    compressSrc: uploadedImageUrl,
                };

                res(data);
            },
            error(err) {
                rej(err.message);
            },
        });
    });

export const isFileSizeBiggerThenMB = (fileSize: number, maxSizeMB: number): boolean => {
    if (fileSize / 1024 / 1024 > maxSizeMB) {
        addToast(`Upload Error: Max file size is ${maxSizeMB}MB!`, ToastMessageType.ERROR);

        return true;
    }

    return false;
};

interface ImageProcessResult {
    readonly files: File[];
    readonly compressSrc: string;
    readonly filesSizeInKb: number;
}

const DEFAULT_COMPRESSOR_OPTIONS = {
    quality: 0.8,
};

export const processImage = async (file: File, options?: Compressor.Options): Promise<ImageProcessResult> => {
    const compressorOptions: Compressor.Options = {
        ...DEFAULT_COMPRESSOR_OPTIONS,
        ...options,
    };

    const { compressFile, compressSrc } = await compressImage(file, compressorOptions);

    const files = [compressFile];
    const filesSizeInKb = compressFile.size / 1024;

    return {
        compressSrc,
        files,
        filesSizeInKb,
    };
};

const UUID_IMAGE_REGEX = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}/i;
const SUPPORT_IMAGE_REGEX = /\.(jpe?g|png|svg)$/i;

export const isAssetImage = (fileName: string): boolean => UUID_IMAGE_REGEX.test(fileName);
export const isUploadedImage = (fileName: string): boolean => fileName.includes('/uploads/');
export const isSupportedImage = (fileName: string): boolean => SUPPORT_IMAGE_REGEX.test(fileName);

export const loadImage = (fileNameValue: string): string => {
    if (!fileNameValue) {
        return '';
    }

    if (fileNameValue.includes('base64')) {
        return fileNameValue;
    }

    const splitFileName = fileNameValue.split('/');
    const fileName = splitFileName[splitFileName.length - 1];

    if (fileNameValue.includes('/') && !fileNameValue.includes('http') && !isAssetImage(fileName)) {
        fileNameValue = resolveAssetsPath(fileNameValue);
    }

    return isAssetImage(fileName) ? resolveAssetsPath(fileNameValue) : fileNameValue;
};
