import { Query } from "@apps/sdk";
import { CheckIsLoggedInResponse } from "@apps/sdk";
import { useQueryState } from "./common";
import { frontofficeService } from "../services";
import { useCallback } from "react";

export const useCheckIsLoggedInQuery = () => {
  const { data, error, loading, setState, setLoading } =
    useQueryState<CheckIsLoggedInResponse>();

  const checkIsLoggedIn = useCallback(async () => {
    setLoading(true);

    setState(
      await frontofficeService.query({
        query: Query.CheckIsLoggedIn,
      })
    );

    setLoading(false);
  }, []);

  return {
    checkIsLoggedIn,
    data,
    error,
    loading,
  };
};
