"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetCountriesResponseSchema = exports.GetCountriesRequestSchema = void 0;
var zod_1 = require("zod");
var shared_1 = require("../shared");
exports.GetCountriesRequestSchema = zod_1.z.object({
    pagination: shared_1.PaginationSchema,
    filter: zod_1.z.object({
        onlyCountriesWithEvents: zod_1.z.boolean().optional(),
    }).optional()
});
exports.GetCountriesResponseSchema = zod_1.z.object({
    countries: zod_1.z.array(shared_1.CountrySchema),
    maxCount: zod_1.z.number(),
});
