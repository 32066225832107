import React from "react";

interface IconProps {
  iconClassName?: string;
}

const CalendarIcon: React.FC<IconProps> = ({ iconClassName }) => {
  return (
    <div className={iconClassName}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21"
        height="24"
        viewBox="0 0 21 24"
        fill="none"
      >
        <path
          d="M7 10.5H4.667V12.833H7V10.5ZM11.667 10.5H9.333V12.833H11.666L11.667 10.5ZM16.334 10.5H14V12.833H16.333L16.334 10.5ZM18.667 2.333H17.5V0H15.167V2.333H5.833V0H3.5V2.333H2.333C2.02718 2.33326 1.72442 2.3939 1.44209 2.51145C1.15977 2.629 0.903427 2.80113 0.687784 3.01798C0.472141 3.23483 0.301439 3.49213 0.185471 3.77511C0.0695026 4.05809 0.0105518 4.36118 0.0120001 4.667L0 21C0 21.6187 0.245798 22.2122 0.68332 22.6497C1.12084 23.0872 1.71425 23.333 2.333 23.333H18.667C19.2852 23.3312 19.8775 23.0848 20.3146 22.6476C20.7518 22.2105 20.9982 21.6182 21 21V4.667C20.9984 4.04864 20.7521 3.45605 20.315 3.01871C19.8778 2.58138 19.2854 2.33485 18.667 2.333ZM18.667 21H2.333V8.167H18.667V21Z"
          fill="#CCCCCC"
        />
      </svg>
    </div>
  );
};

export default CalendarIcon;
