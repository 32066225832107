"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetSportEventUsersResponseSchema = exports.GetSportEventUsersRequestSchema = void 0;
var zod_1 = require("zod");
var shared_1 = require("../shared");
exports.GetSportEventUsersRequestSchema = zod_1.z.object({
    eventId: zod_1.z.string(),
    filter: zod_1.z.object({
        status: zod_1.z.nativeEnum(shared_1.SportEventUserStatus).optional()
    }).optional()
});
exports.GetSportEventUsersResponseSchema = zod_1.z.object({
    users: zod_1.z.array(zod_1.z.object({
        isCertificateGenerated: zod_1.z.boolean(),
    }).merge(shared_1.SportEventUserSchema))
});
