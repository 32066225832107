import { CloudUpload } from "@mui/icons-material";
import { Button } from "@mui/material";
import React from "react";

import { MAX_FILE_SIZE_MB } from "../ImageUpload.constants";
import { ImageUploadComponentProps, withImageUpload } from "../withImageUpload";
import styles from "./EventUpload.module.scss";
import { Styled } from "./EventUpload.styles";
import { getFileSrc } from "../../../utils";

const EventUploadWrapped: React.FC<ImageUploadComponentProps> = ({
  imgFileFieldName,
  imgSrc,
  showError,
  errorText,
  outsideErrorText,
  onFileChange,
  acceptFileTypes,
  imgSourceFieldName,
}) => {
  const acceptFileTypesMessage = acceptFileTypes
    ?.join(", ")
    .replaceAll(".", "")
    .toUpperCase();

  const imageSourceLocal = getFileSrc(imgSrc || imgSourceFieldName || "");

  // const divStyle = {
  //   backgroundImage: `url(${imageSourceLocal})`,
  //   backgroundSize: "contain",
  //   backgroundPosition: "center",
  // };

  const divStyle = {
    backgroundImage: `url(${imageSourceLocal})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    // backgroundPositionY: "top",
  };

  return (
    <div style={divStyle} className={styles.photoContainer}>
      <Styled.UploadButtonContainer>
        <label>
          <Styled.FileInput
            onChange={onFileChange}
            accept={acceptFileTypes?.join(", ")}
            name={imgFileFieldName}
            type="file"
          />

          <Button
            color="primary"
            variant="outlined"
            component="span"
            startIcon={<CloudUpload />}
          >
            Upload
          </Button>

          <Styled.TextContainer>
            {`Max size: ${MAX_FILE_SIZE_MB}MB`}
            <br />
            {acceptFileTypesMessage && `${acceptFileTypesMessage}`}
          </Styled.TextContainer>
        </label>
      </Styled.UploadButtonContainer>

      {showError && (
        <Styled.Error>{errorText || outsideErrorText}</Styled.Error>
      )}
    </div>
  );
};

const EventUpload = withImageUpload(EventUploadWrapped);

export default EventUpload;
