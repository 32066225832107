import { State } from "@packages/core-shared";
import { JwtResponse } from "@apps/sdk";
import { parse } from "../utils/json.utils";
import { TOKEN_STORAGE_KET } from "../app-constants";

const persistedTokenData = localStorage.getItem(TOKEN_STORAGE_KET);

export const tokenDataState = new State<JwtResponse | undefined>(
  persistedTokenData
    ? parse<JwtResponse>(persistedTokenData)
    : undefined
);
