import {
  EditSportEventRequest,
  EditSportEventResponse,
  Query,
} from "@apps/sdk";
import { useCallback } from "react";
import { useQueryState } from "./common";
import { frontofficeService } from "../services";
import { toast } from "react-toastify";

export const useEditSportEventQuery = () => {
  const { data, error, loading, setState, setLoading } =
    useQueryState<EditSportEventResponse>();

  const editSportEvent = useCallback(async (payload: EditSportEventRequest) => {
    setLoading(true);

    setState(
      await frontofficeService.query({
        query: Query.EditSportEvent,
        payload: payload,
        onSuccess: (response: EditSportEventResponse) => {
          toast.success("Event updated", {
            position: "top-right",
          });
        },
        onError: (errorResponse: any) => {
          errorResponse.errors.forEach(
            (error: any) => toast.error(error.message),
            {
              position: "top-right",
            }
          );
        },
      })
    );

    setLoading(false);
  }, []);

  return {
    editSportEvent,
    data,
    error,
    loading,
  };
};
