import styles from "./HowItWorks.module.scss";

import { deviceTypeState } from "../../../state";
import { useTranslations } from "../../../hooks";

import volunteer from "./assets/volunteer.png";
import sportsOrganisation from "./assets/sports-organization.png";

import volunteerMobile from "./assets/volunteer-mobile.png";
import sportsOrganisationMobile from "./assets/sports-organization-mobile.png";

const HowItWorks = () => {
  const { isDesktop } = deviceTypeState.get();
  const { translate } = useTranslations();

  return (
    <div className={styles.container}>
      <div className={styles.heading}>{translate((t) => t.HOW_IT_WORKS)}</div>

      {/* TODO : Background pictures on mobile are as perfect as it needs to. Pictures have to be changed for different languages*/}
      <div className={styles.imageWrapper}>
        {isDesktop ? (
          <>
            <img src={volunteer} />
            <img src={sportsOrganisation} />
          </>
        ) : (
          <>
            <img src={volunteerMobile} />
            <img src={sportsOrganisationMobile} />
          </>
        )}
      </div>
    </div>
  );
};

export default HowItWorks;
