import {
  ApplyForSportEventRequest,
  ApplyForSportEventResponse,
  Query,
} from "@apps/sdk";
import { useQueryState } from "./common";
import { frontofficeService } from "../services";
import { useCallback } from "react";
import { toast } from "react-toastify";

export const useApplyForSportEvent = () => {
  const { data, error, loading, setState, setLoading } =
    useQueryState<ApplyForSportEventResponse>();

  const applyForSportEvent = useCallback(async (eventId: string) => {
    setLoading(true);

    const applyForSportEventPayload: ApplyForSportEventRequest = {
      eventId,
    };

    setState(
      await frontofficeService.query({
        query: Query.ApplyForSportEvent,
        payload: applyForSportEventPayload,
        onSuccess: (response: ApplyForSportEventResponse) => {
          toast.success("Applied successful", {
            position: "top-right",
          });
        },
        onError: (errorResponse: any) => {
          errorResponse.errors.forEach(
            (error: any) => toast.error(error.message),
            {
              position: "top-right",
            }
          );
        },
      })
    );

    setLoading(false);
  }, []);

  return {
    applyForSportEvent,
    data,
    error,
    loading,
  };
};
