import React from "react";

interface IconProps {
  iconClassName?: string;
}

const CopyIcon: React.FC<IconProps> = ({ iconClassName }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="14"
      viewBox="0 0 13 14"
      fill="none"
      className={iconClassName}
    >
      <path
        d="M8.75 12.25V13.344C8.75 13.4301 8.73303 13.5155 8.70006 13.595C8.6671 13.6746 8.61878 13.7469 8.55786 13.8079C8.49695 13.8688 8.42463 13.9171 8.34504 13.9501C8.26545 13.983 8.18015 14 8.094 14H0.656C0.569853 14 0.484549 13.983 0.40496 13.9501C0.32537 13.9171 0.253053 13.8688 0.192138 13.8079C0.131223 13.7469 0.0829021 13.6746 0.049935 13.595C0.0169679 13.5155 0 13.4301 0 13.344L0 3.281C0 3.10702 0.0691141 2.94016 0.192138 2.81714C0.315162 2.69411 0.482018 2.625 0.656 2.625H2.625V10.719C2.62553 11.1249 2.787 11.514 3.074 11.801C3.36101 12.088 3.75012 12.2495 4.156 12.25H8.75ZM8.75 2.844V0H4.156C3.98202 0 3.81516 0.0691141 3.69214 0.192138C3.56911 0.315162 3.5 0.482018 3.5 0.656V10.719C3.5 10.893 3.56911 11.0598 3.69214 11.1829C3.81516 11.3059 3.98202 11.375 4.156 11.375H11.594C11.768 11.375 11.9348 11.3059 12.0579 11.1829C12.1809 11.0598 12.25 10.893 12.25 10.719V3.5H9.406C9.23218 3.49947 9.06563 3.43019 8.94272 3.30728C8.81981 3.18437 8.75053 3.01782 8.75 2.844ZM12.058 2L10.258 0.2C10.1974 0.137288 10.1248 0.0872992 10.0446 0.0529579C9.96446 0.0186166 9.87823 0.000612838 9.791 0L9.625 0V2.625H12.25V2.459C12.2488 2.28672 12.1798 2.12185 12.058 2Z"
        fill="#CCCCCC"
      />
    </svg>
  );
};

export default CopyIcon;
