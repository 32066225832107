import { useEffect, useState } from "react";
import ContentBackgroundContainer from "../../components/ContentBackgroundContainer";
import ContentHeading from "../../components/ContentHeading";
import {
  useGetCertificatesQuery,
  useGetSportEventsQuery,
  useGetUsersQuery,
} from "../../query-hooks";
import styles from "./UserProfile.module.scss";
import { useParams } from "react-router-dom";
import { UserType } from "@apps/sdk";
import ContentContainer from "../../components/ContentContainer";
import LocationIcon from "../../assets/icons/LocationIcon";
import SocialIcons from "../../components/SocialIcons/SocialIcons";
import parseHtml from "html-react-parser";
import EventBoxSmall from "../../components/EventBoxes/EventBoxSmall/EventBoxSmall";

const UserProfile = () => {
  const { userId } = useParams<{ userId: string }>();
  const { getUsers, data: users } = useGetUsersQuery();

  const { getSportEvents, data: events } = useGetSportEventsQuery();
  const { getCertificates, data: certificates } = useGetCertificatesQuery();
  const [workingHours, setWorkingHours] = useState(0);

  const user = users?.users[0];

  useEffect(() => {
    if (!certificates?.certificates?.length) {
      return;
    }

    setWorkingHours(
      certificates.certificates
        .map((certificate) => certificate.workingHoursCount)
        .reduce((a, b) => a + b)
    );
  }, [certificates]);

  useEffect(() => {
    if (user?.type === UserType.SportOrganization) {
      getSportEvents({
        pagination: {
          page: 0,
          rowsPerPage: 20,
        },
        filter: {
          createdByUserId: userId,
        },
      });
    } else {
      getSportEvents({
        pagination: {
          page: 0,
          rowsPerPage: 20,
        },
        filter: {
          volunteerUserId: userId,
        },
      });

      getCertificates({
        pagination: {
          page: 0,
          rowsPerPage: 99,
        },
        filter: {
          userId: userId,
        },
      });
    }
  }, [user]);

  useEffect(() => {
    if (!userId) {
      return;
    }

    getUsers({
      pagination: {
        page: 0,
        rowsPerPage: 1,
      },
      filter: {
        id: userId,
      },
    });
  }, [userId]);

  if (!events || !user) {
    return null;
  }

  return (
    <ContentBackgroundContainer>
      <ContentHeading
        photoUrl={user?.photoUrl || ""}
        name={user?.fullName || ""}
        userType={user?.type || UserType.Volunteer}
      />

      <ContentContainer className={styles.contentContainer}>
        <div className={styles.userName}>{user?.fullName}</div>

        <div className={styles.locationSocialContainer}>
          {user?.country && (
            <div className={styles.locationContainer}>
              <LocationIcon iconClassName={styles.icon} />
              <div className={styles.headerDivider} />
              Location: {user?.country?.name}
            </div>
          )}
          {user.socials && (
            <div>
              <SocialIcons
                socialLinks={user.socials.links}
                socialType="withWebsite"
                distance="big"
              />
            </div>
          )}
        </div>

        {user?.description ? (
          user.description.length > 0 ? (
            <div className={styles.description}>
              {parseHtml(user.description as string)}
            </div>
          ) : null
        ) : null}

        <div className={styles.organizedSportEvents}>
          {user?.type === UserType.SportOrganization
            ? "Organized Sport events"
            : "Participation in Sport Events"}
        </div>

        {user?.type === UserType.Volunteer && workingHours > 0 && (
          <div className={styles.totalLabourHours}>
            You have total of <span>{workingHours}</span> volunteer hours in
            SPERO Platform.
          </div>
        )}

        {events?.sportEvents.map((event) => {
          return (
            <EventBoxSmall
              event={event}
              key={event.id}
              userIdParam={userId}
              certificate={
                certificates
                  ? certificates.certificates.find(
                      (certificate) => certificate.eventName === event.name
                    )
                  : undefined
              }
            />
          );
        })}
      </ContentContainer>
    </ContentBackgroundContainer>
  );
};

export default UserProfile;
