"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SportEventSchema = exports.SportEventCosts = exports.OtherRequirementsJsonSchema = exports.KeyResponsibilitiesJsonSchema = exports.SportEventStatus = void 0;
var zod_1 = require("zod");
var country_1 = require("./country");
var user_1 = require("./user");
var sportEventUser_1 = require("./sportEventUser");
var SportEventStatus;
(function (SportEventStatus) {
    SportEventStatus["Draft"] = "draft";
    SportEventStatus["Approved"] = "approved";
    SportEventStatus["Pending"] = "pending";
    SportEventStatus["Rejected"] = "rejected";
})(SportEventStatus || (exports.SportEventStatus = SportEventStatus = {}));
exports.KeyResponsibilitiesJsonSchema = zod_1.z.object({
    list: zod_1.z.array(zod_1.z.string())
});
exports.OtherRequirementsJsonSchema = zod_1.z.object({
    list: zod_1.z.array(zod_1.z.string())
});
var SportEventCosts;
(function (SportEventCosts) {
    SportEventCosts["FullyCovered"] = "Fully Covered";
    SportEventCosts["PartiallyCovered"] = "Partially Covered";
    SportEventCosts["NotCovered"] = "Not Covered";
})(SportEventCosts || (exports.SportEventCosts = SportEventCosts = {}));
exports.SportEventSchema = zod_1.z.object({
    id: zod_1.z.string(),
    name: zod_1.z.string(),
    status: zod_1.z.nativeEnum(SportEventStatus),
    dateFrom: zod_1.z.string(),
    dateTo: zod_1.z.string(),
    country: country_1.CountrySchema,
    city: zod_1.z.string(),
    volunteersCount: zod_1.z.number().min(1),
    workingHoursCount: zod_1.z.number().min(1),
    description: zod_1.z.string(),
    keyResponsibilities: exports.KeyResponsibilitiesJsonSchema,
    otherRequirements: exports.OtherRequirementsJsonSchema,
    approvedVolunteersCount: zod_1.z.number().nullish(),
    isAlreadyApplied: zod_1.z.boolean().default(false).nullish(),
    applicationStatus: zod_1.z.nativeEnum(sportEventUser_1.SportEventUserStatus).nullish(),
    createdBy: user_1.UserSchema,
    costs: zod_1.z.nativeEnum(SportEventCosts),
    imageUrl: zod_1.z.string().nullish()
});
