import {
  CreateSportEventRequest,
  CreateSportEventResponse,
  Query,
} from "@apps/sdk";
import { useCallback } from "react";
import { useQueryState } from "./common";
import { frontofficeService } from "../services";
import { toast } from "react-toastify";

export const useCreateEventQuery = () => {
  const { data, error, loading, setState, setLoading } =
    useQueryState<CreateSportEventResponse>();

  const createEvent = useCallback(async (payload: CreateSportEventRequest) => {
    setLoading(true);

    setState(
      await frontofficeService.query({
        query: Query.CreateSportEvent,
        payload,
        onSuccess: (response: CreateSportEventResponse) => {
          toast.success("Event created", {
            position: "top-right",
          });
        },
        onError: (errorResponse: any) => {
          errorResponse.errors.forEach(
            (error: any) => toast.error(error.message),
            {
              position: "top-right",
            }
          );
        },
      })
    );

    setLoading(false);
  }, []);

  return {
    createEvent,
    data,
    error,
    loading,
  };
};
