import { createState } from "react-reactive-tools";

export type CourseModalState = Readonly<{
  liveEventModal: boolean;
  lecturesModal: boolean;
  uploadLessonModal: boolean;
}>;

export const courseModalState = createState<CourseModalState>(
  "courseModalOpen",
  {
    liveEventModal: false,
    lecturesModal: false,
    uploadLessonModal: false,
  }
);

export type AuthModalState = Readonly<{
  loginModal: boolean;
  registerModal: boolean;
  forgotPasswordModal: boolean;
  resetPasswordModal: boolean;
}>;

export const authModalState = createState<AuthModalState>("authModalOpen", {
  loginModal: false,
  registerModal: false,
  forgotPasswordModal: false,
  resetPasswordModal: false,
});

type TestModalState = Readonly<{
  quizModalId: string;
  questModalId: string;
}>;

export const testModalState = createState<TestModalState>("testModalOpen", {
  quizModalId: "",
  questModalId: "",
});
export const messagesModalState = createState("messagesModal", false);
