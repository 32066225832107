import { useEffect } from "react";
import { USER_STORAGE_KEY } from "../app-constants";
import { userState } from "../state";

export const usePersistUser = () => {
    useEffect(() => {
        userState.addChangeListener((_, newState) => {
          newState
            ? localStorage.setItem(USER_STORAGE_KEY, JSON.stringify(newState))
            : localStorage.removeItem(USER_STORAGE_KEY);
        });
      }, [])
}
