import styles from "./Events.module.scss";
import EventsList from "./EventsList";

import { useTranslations } from "../../hooks";
import ContentBackgroundContainer from "../../components/ContentBackgroundContainer";
import { InputAdornment, Pagination } from "@mui/material";

import TextField from "../../components/TextField";

import { useCallback, useEffect, useState } from "react";
import { Search } from "@mui/icons-material";
import DefaultTextField from "../../components/TextField/DefaultTextField";
import FilterIcon from "../../assets/icons/FilterIcon";
import {
  useGetCountriesQuery,
  useGetSportEventsQuery,
} from "../../query-hooks";
import {
  GetSportEventsResponse,
  SportEventStatus,
  SportEventUserStatus,
} from "@apps/sdk";

type CountryType = { name: string; id: string };

const Events = () => {
  const { translate } = useTranslations();

  const { getCountries, data: countriesData } = useGetCountriesQuery();

  //---

  const {
    getSportEvents,
    data: eventsData,
    loading,
  } = useGetSportEventsQuery();

  const [isSingleSearchInUse, setSingleSearchInUse] = useState(false);
  const [typeFilterValue, setTypeFilterValue] = useState({ type: "" });
  const [selectedCountry, setSelectedCountry] = useState<CountryType>();

  const [filterText, setFilterText] = useState<string>();

  const [activePage, setActivePage] = useState(1);
  const [paginationCount, setPaginationCount] = useState(0);
  const [pageSize, setPageSize] = useState(6);
  const [events, setEvents] = useState<GetSportEventsResponse>();

  const maxCount = events?.maxCount || 0;

  useEffect(() => {
    getCountries({
      pagination: {
        page: 0,
        rowsPerPage: 99,
      },
      filter: {
        onlyCountriesWithEvents: true,
      },
    });
  }, []);

  const countryOptions = countriesData?.countries.map((country) => ({
    name: country.name,
    value: country.id,
  }));

  useEffect(() => {
    getSportEvents({
      pagination: {
        page: 0,
        rowsPerPage: pageSize,
      },
      filter: {
        status: SportEventUserStatus.Approved,
      },
    });
  }, []);

  useEffect(() => {
    if (maxCount !== 0) {
      setPaginationCount(Math.ceil(maxCount / pageSize));
    }
  }, [maxCount]);

  useEffect(() => {
    if (maxCount === 0) {
      //|| typeFilterValue.type === ""
      return;
    }

    // setPaginationCount(events?.sportEvents.length || 0);
  }, [typeFilterValue]);

  const handleFilterByName = useCallback(
    (value: string | undefined) => {
      if (value) {
        setPaginationCount(1);

        setSingleSearchInUse(true);

        return;
      }

      setPaginationCount(maxCount);
      setSingleSearchInUse(false);
    },
    [maxCount]
  );

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) =>
    setActivePage(value);

  useEffect(() => {
    getSportEvents({
      pagination: {
        page: activePage - 1,
        rowsPerPage: pageSize,
      },
      filter: {
        status: SportEventUserStatus.Approved,
      },
    });
  }, [activePage]);

  useEffect(() => {
    // const tempEvents = eventsData?.sportEvents.filter(
    //   (x) => x.status === SportEventStatus.Approved
    // );
    // const tempEvents = eventsData?.sportEvents;

    // console.log(eventsData);
    setEvents(eventsData);
  }, [eventsData]);

  const handleCountryChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const selectedCountryValue = event.target.value;
    const selectedCountryInput =
      countryOptions &&
      countryOptions.find((country) => country.value === selectedCountryValue);

    setSelectedCountry({
      id: selectedCountryInput?.value || "",
      name: selectedCountryInput?.name || "",
    });
  };

  useEffect(() => {
    setPaginationCount(1);

    if (filterText !== "") {
      getSportEvents({
        pagination: {
          page: 0,
          rowsPerPage: pageSize,
        },
        filter: {
          countryId: selectedCountry?.id,
          name: filterText,
          status: SportEventUserStatus.Approved,
        },
      });
    } else {
      getSportEvents({
        pagination: {
          page: 0,
          rowsPerPage: pageSize,
        },
        filter: {
          countryId: selectedCountry?.id,
          status: SportEventUserStatus.Approved,
        },
      });
    }
  }, [selectedCountry]);

  const handleTextFilterChange = (e) => {
    setFilterText(e.target.value);
  };

  useEffect(() => {
    if (selectedCountry?.name !== "") {
      getSportEvents({
        pagination: {
          page: 0,
          rowsPerPage: pageSize,
        },
        filter: {
          name: filterText,
          countryId: selectedCountry?.id,
          status: SportEventUserStatus.Approved,
        },
      });
    } else {
      getSportEvents({
        pagination: {
          page: 0,
          rowsPerPage: pageSize,
        },
        filter: {
          name: filterText,
          status: SportEventUserStatus.Approved,
        },
      });
    }
  }, [filterText]);

  return (
    <ContentBackgroundContainer>
      <div className={styles.headingContainer}>
        <div className={styles.title}>
          {translate((t) => t.SEEK_FOR_VOLUNTEER_POSITIONS)}
        </div>
        <div className={styles.subtitle}>
          {translate(
            (t) => t.EVERY_PERSON_CAN_MAKE_A_DIFFERENCE_AND_SHOULD_TRY
          )}
        </div>
      </div>

      <div className={styles.filterContainer}>
        <DefaultTextField
          value={selectedCountry?.id}
          name="countryId"
          label={translate((t) => t.FILTER_BY_LOCATION)}
          select
          options={countryOptions}
          className={`${styles.field} ${styles.thirdInput}`}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FilterIcon />
              </InputAdornment>
            ),
          }}
          onChange={handleCountryChange}
          SelectProps={{
            MenuProps: {
              PaperProps: {
                style: {
                  maxHeight: 200,
                },
              },
            },
          }}
        />

        <DefaultTextField
          value={filterText}
          name="datesRange"
          className={`${styles.field} ${styles.thirdInput}`}
          placeholder={translate((t) => t.SEARCH_EVENTS)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          onChange={handleTextFilterChange}
        />
      </div>

      {events ? (
        events?.sportEvents?.length > 0 ? (
          <EventsList events={events?.sportEvents} />
        ) : (
          <div className={styles.noEventsFound}>No events found.</div>
        )
      ) : (
        <div className={styles.noEventsFound}>No events found.</div>
      )}

      <div className={styles.paginationContainer}>
        <Pagination
          count={paginationCount}
          page={activePage}
          onChange={handleChange}
          size="small"
        />
      </div>
    </ContentBackgroundContainer>
  );
};

export default Events;
