import styles from "./ContentHeading.module.scss";

import { Avatar } from "@mui/material";
import { UserType } from "@apps/sdk";
import { getFileSrc, convertCamelCaseToRegularWords } from "../../utils";
import { Link } from "react-router-dom";

type ContentHeadingProps = {
  photoUrl?: string;
  userType: UserType;
  name: string;
  createdById?: string;
};

const ContentHeading: React.FC<ContentHeadingProps> = ({
  photoUrl,
  userType,
  name,
  createdById,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>
        {convertCamelCaseToRegularWords(userType)}
      </div>
      <div className={styles.userPictureNameContainer}>
        <Avatar
          alt={name}
          src={getFileSrc(photoUrl || "")}
          className={styles.lecturerAvatar}
        />

        {createdById ? (
          <Link to={`/user-profile/${createdById}`}>
            <span className={styles.userName}>{name}</span>
          </Link>
        ) : (
          <span className={styles.userName}>{name}</span>
        )}
      </div>
    </div>
  );
};

export default ContentHeading;
