//-----------------------------------------------------------------------
// * QuestRoom imports not needed for now
// import "styles/global.scss";  // ? This was dublicated in QuestRoom
// import "video.js/dist/video-js.css";
// import ApolloClientProvider from "apollo";
//-----------------------------------------------------------------------

import BaseLayout from "./layouts/BaseLayout";
import "./styles/global.scss";

import { StyledEngineProvider } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";

import { Outlet } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import { usePersistToken, usePersistUser } from "./hooks";

const App = () => {
  usePersistToken();

  usePersistUser();

  return (
    <ThemeProvider theme={theme}>
      <StyledEngineProvider injectFirst>
        {/* <ApolloClientProvider> Needed for Apollo after we have DB */}
        <BaseLayout>
          <ScrollToTop />
          <Outlet />
        </BaseLayout>
        {/* </ApolloClientProvider> Needed for Apollo after we have DB */}
      </StyledEngineProvider>
    </ThemeProvider>
  );
};

export default App;
