import { JwtResponse, Header } from "@apps/sdk";
import { useEffect } from "react";
import { TOKEN_STORAGE_KET } from "../app-constants";
import { frontofficeService } from "../services";
import { tokenDataState } from "../state";

export const usePersistToken = () => {
    useEffect(() => {
        const persistTokenToLocalStorage = (tokenData: JwtResponse | undefined) => {
            tokenData
                ? localStorage.setItem(TOKEN_STORAGE_KET, JSON.stringify(tokenData))
                : localStorage.removeItem(TOKEN_STORAGE_KET);
        }

        const setTokenToAuthorizationHeader = (tokenData: JwtResponse | undefined) => {
            tokenData
                ? frontofficeService.appendHeader(Header.Authorization, tokenData.token)
                : frontofficeService.removeHeader(Header.Authorization);
        }

        setTokenToAuthorizationHeader(tokenDataState.get());

        tokenDataState.addChangeListener((_, newState) => {
            persistTokenToLocalStorage(newState);

            setTokenToAuthorizationHeader(newState);
        });
    }, [])
}
