"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateOrEditEducationResourceResponseSchema = exports.CreateOrEditEducationResourceRequestSchema = void 0;
var zod_1 = require("zod");
var shared_1 = require("../shared");
exports.CreateOrEditEducationResourceRequestSchema = zod_1.z.object({
    id: zod_1.z.string().optional(),
    file: shared_1.UploadSchema.optional(),
    imageFile: shared_1.UploadSchema.optional(),
    videoLink: zod_1.z.string().optional(),
    description: zod_1.z.string().optional()
}).merge(shared_1.EducationResourceSchema.omit({
    id: true,
    imageUrl: true,
    url: true,
    description: true
}));
exports.CreateOrEditEducationResourceResponseSchema = shared_1.EducationResourceSchema;
