import React from "react";

interface IconProps {
  iconClassName?: string;
}

const AlertIcon: React.FC<IconProps> = ({ iconClassName }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="49"
      viewBox="0 0 48 49"
      fill="none"
      className={iconClassName}
    >
      <path
        d="M24 0.75C19.2533 0.75 14.6131 2.15758 10.6663 4.79473C6.71954 7.43188 3.64341 11.1802 1.8269 15.5656C0.010399 19.951 -0.464881 24.7766 0.461164 29.4322C1.38721 34.0877 3.67299 38.3641 7.02945 41.7206C10.3859 45.077 14.6623 47.3628 19.3178 48.2888C23.9734 49.2149 28.799 48.7396 33.1844 46.9231C37.5698 45.1066 41.3181 42.0305 43.9553 38.0837C46.5924 34.1369 48 29.4968 48 24.75C47.9813 18.3906 45.4467 12.2969 40.9499 7.80013C36.4531 3.30331 30.3594 0.76873 24 0.75ZM26.769 36.75H21.231V32.135H26.769V36.75ZM26.769 26.596H21.231V11.827H26.769V26.596Z"
        fill="#C53333"
      />
    </svg>
  );
};

export default AlertIcon;
