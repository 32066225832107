import { User } from "@apps/sdk";
import styles from "./VolunteerBoxAction.module.scss";
import {
  Avatar,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { getFileSrc } from "../../utils";
import CopyIcon from "../../assets/icons/CopyIcon";
import { Link } from "react-router-dom";
import React, { useState } from "react";
import { useGenerateCertificate } from "../../query-hooks";

type VolunteerBoxActionsProps = {
  volunteer: any;
  eventId: string;
  onRadioChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const VolunteerBoxActions = ({
  volunteer,
  eventId,
  onRadioChange,
}: VolunteerBoxActionsProps) => {
  const volunteerData = volunteer.user;

  const [isCertificateGenerated, setIsCertificateGenerated] = useState(
    volunteer.isCertificateGenerated
  );

  const { generateCertificate, data: certificateData } =
    useGenerateCertificate();

  const handleCertificateGeneration = async () => {
    await generateCertificate({ userId: volunteerData.id, eventId }).then(
      (e) => {
        console.log(e);
        setIsCertificateGenerated(true);
      }
    );
  };

  const isApproved = volunteer.status === "approved";

  return (
    <div className={styles.container}>
      <div className={styles.leftSection}>
        <div className={styles.leftSectionAvatarWrapper}>
          <Avatar
            className={styles.avatar}
            src={getFileSrc(volunteerData.photoUrl || "")}
          />
        </div>
        <div className={styles.leftSectionDataWrapper}>
          <span className={styles.name}>{volunteerData.fullName}</span>

          <FormControl>
            <RadioGroup
              value={volunteer.status}
              name="radio-buttons-group"
              className={styles.radioContainer}
              onChange={(e) => onRadioChange(e)}
            >
              <FormControlLabel
                value="approved"
                control={
                  <Radio
                    sx={{
                      color: "#6ABE6D",
                      "&.Mui-checked": {
                        color: "#6ABE6D",
                      },
                    }}
                  />
                }
                label="Approve"
              />

              <FormControlLabel
                value="rejected"
                control={
                  <Radio
                    sx={{
                      color: "#C53333",
                      "&.Mui-checked": {
                        color: "#C53333",
                      },
                    }}
                  />
                }
                label="Reject"
              />
            </RadioGroup>
          </FormControl>

          <div className={styles.volunteerDataContainer}>
            {volunteerData.email ? (
              <div className={styles.emailVolunteer}>
                <span className={styles.volunteerDataText}>
                  {volunteerData.email}
                </span>
                <CopyIcon />
              </div>
            ) : null}

            {volunteerData.phoneNumber ? (
              <div>
                <span className={styles.volunteerDataText}>
                  {volunteerData.phoneNumber}
                </span>
                <CopyIcon />
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <div className={styles.rightSection}>
        <Link
          to={`/user-profile/${volunteerData?.id}`}
          className={styles.seeDetailsButton}
        >
          View profile
        </Link>
        <button
          className={
            isApproved && !isCertificateGenerated
              ? styles.seeDetailsButton
              : styles.seeDetailsButtonDisabled
          }
          onClick={handleCertificateGeneration}
        >
          {!isCertificateGenerated ? "generate certificate" : "generated"}
        </button>
      </div>
    </div>
  );
};

export default VolunteerBoxActions;
