import React, { lazy, Suspense } from "react";
import Footer from "../../sections/Footer";

import styles from "./BaseLayout.module.scss";

import { ToastContainer } from "../../utils";
import { authModalState, deviceTypeState } from "../../state";

const Header = lazy(() => import("../../sections/Header"));
const HeaderMobile = lazy(() => import("../../sections/HeaderMobile"));

const AuthModalLogin = lazy(() => import("../../sections/AuthModalLogin"));
const AuthModalRegister = lazy(
  () => import("../../sections/AuthModalRegister")
);
const AuthModalForgotPassword = lazy(
  () => import("../../sections/AuthModalForgotPassword")
);

type Props = {
  children: React.ReactNode;
};

const BaseLayout = ({ children }: Props) => {
  const authModal = authModalState.subscribe();

  // TODO: when there are users
  // useRefreshSession();

  const { isDesktop } = deviceTypeState.get();

  return (
    <>
      <ToastContainer className={"toast-container"} />
      <Suspense>{isDesktop ? <Header /> : <HeaderMobile />}</Suspense>
      <div className={styles.container}>{children}</div>

      <Footer />

      <Suspense>
        {authModal.loginModal && <AuthModalLogin />}
        {authModal.registerModal && <AuthModalRegister />}
        {authModal.forgotPasswordModal && <AuthModalForgotPassword />}
      </Suspense>
    </>
  );
};

export default BaseLayout;
