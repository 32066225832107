import ContentBackgroundContainer from "../../components/ContentBackgroundContainer";
import ContentContainer from "../../components/ContentContainer";
import styles from "./Contact.module.scss";
import ContactForm from "./ContactForm";

const Contact = () => {
  return (
    <ContentBackgroundContainer>
      <div className={styles.container}></div>
      <ContentContainer className={styles.contentContainer}>
        <div className={styles.title}>Contact</div>

        <div className={styles.text}>
          Thank you for reaching out to us! We are eager to hear from you and
          committed to providing you with exceptional service. Whether you have
          questions, suggestions, or require assintace , your feeback is
          invaluable to us.
        </div>
        <div className={styles.textBold}>
          Use the form below to send us a message, and a member of our team will
          get back to you promptly.
        </div>
        <div className={styles.text}>
          Your engament with our community is what fuels our passion and
          innovation, and we look forward to connecting with you.
        </div>

        <ContactForm />
      </ContentContainer>
    </ContentBackgroundContainer>
  );
};

export default Contact;
