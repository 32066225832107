interface IconProps {
  iconClassName?: string;
}

const YouTubeIcon: React.FC<IconProps> = ({ iconClassName }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="19"
      viewBox="0 0 26 19"
      fill="none"
      className={iconClassName}
    >
      <path
        d="M25.026 3.32598C24.8815 2.7822 24.5966 2.28593 24.2 1.88684C23.8034 1.48776 23.3089 1.19986 22.766 1.05198C20.773 0.513977 12.78 0.513977 12.78 0.513977C12.78 0.513977 4.78699 0.513977 2.79399 1.05198C2.25112 1.19986 1.75662 1.48776 1.35999 1.88684C0.96336 2.28593 0.678524 2.7822 0.533994 3.32598C0.165845 5.3678 -0.0129141 7.43927 -6.28538e-06 9.51398C-0.0136541 11.5927 0.165108 13.6682 0.533994 15.714C0.679586 16.2533 0.965385 16.7445 1.36234 17.1376C1.75929 17.5307 2.25325 17.8117 2.79399 17.952C4.78699 18.49 12.78 18.49 12.78 18.49C12.78 18.49 20.773 18.49 22.766 17.952C23.3067 17.8117 23.8007 17.5307 24.1976 17.1376C24.5946 16.7445 24.8804 16.2533 25.026 15.714C25.3944 13.6708 25.5731 11.598 25.56 9.52198C25.5731 7.44593 25.3944 5.37312 25.026 3.32998V3.32598ZM10.166 13.314V5.71398L16.85 9.51398L10.169 13.314H10.166Z"
        fill="#CCCCCC"
      />
    </svg>
  );
};

export default YouTubeIcon;
