import { UserType } from "@apps/sdk";
import ContentBackgroundContainer from "../../components/ContentBackgroundContainer";
import ContentHeading from "../../components/ContentHeading";
import {
  useCheckIsLoggedInQuery,
  useGetSportEventsQuery,
  useGetUsersQuery,
} from "../../query-hooks";
import CreatedEventsList from "./CreatedEventsList";
import { useReactiveState } from "../../hooks";
import { userState } from "../../state";
import { Pagination } from "@mui/material";
import { useEffect, useState } from "react";
import styles from "./CreatedEvents.module.scss";

const CreatedEvents = () => {
  const { current: user } = useReactiveState(userState);
  const { checkIsLoggedIn, data: isLogged } = useCheckIsLoggedInQuery();

  const [activePage, setActivePage] = useState(1);
  const [paginationCount, setPaginationCount] = useState(0);
  const [pageSize, setPageSize] = useState(4);

  const { getSportEvents, data: events, loading } = useGetSportEventsQuery();

  const maxCount = events?.maxCount || 0;

  useEffect(() => {
    if (maxCount !== 0) {
      setPaginationCount(Math.ceil(maxCount / pageSize));
    }
  }, [maxCount]);

  useEffect(() => {
    checkIsLoggedIn();
  }, []);

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) =>
    setActivePage(value);

  useEffect(() => {
    getSportEvents({
      pagination: {
        page: activePage - 1,
        rowsPerPage: pageSize,
      },
      filter: {
        createdByUserId: user?.id,
      },
    });
  }, [activePage, isLogged]);

  return (
    <ContentBackgroundContainer>
      <ContentHeading
        photoUrl={user?.photoUrl || ""}
        name={user?.fullName || ""}
        userType={user?.type || UserType.Volunteer}
      />

      {events ? <CreatedEventsList events={events?.sportEvents} /> : null}

      <div className={styles.paginationContainer}>
        <Pagination
          count={paginationCount}
          page={activePage}
          onChange={handlePageChange}
          size="small"
        />
      </div>
    </ContentBackgroundContainer>
  );
};

export default CreatedEvents;
