import React from "react";

interface IconProps {
  iconClassName?: string;
}

const DolarIcon: React.FC<IconProps> = ({ iconClassName }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="28"
      viewBox="0 0 16 28"
      fill="none"
      className={iconClassName}
    >
      <path
        d="M8.74698 12.35C5.34698 11.465 4.24698 10.55 4.24698 9.125C4.24698 7.49 5.76198 6.35 8.29698 6.35C10.967 6.35 11.957 7.625 12.047 9.5H15.362C15.3279 8.14213 14.8325 6.83634 13.9573 5.79758C13.0822 4.75882 11.8794 4.04901 10.547 3.785V0.5H6.04698V3.74C3.13698 4.37 0.796978 6.26 0.796978 9.155C0.796978 12.62 3.66198 14.345 7.84698 15.355C11.597 16.255 12.347 17.575 12.347 18.97C12.347 20.005 11.612 21.655 8.29698 21.655C5.20698 21.655 3.99198 20.275 3.82698 18.505H0.526978C0.706978 21.79 3.16698 23.635 6.04698 24.25V27.5H10.547V24.275C13.472 23.72 15.797 22.025 15.797 18.95C15.797 14.69 12.147 13.235 8.74698 12.35Z"
        fill="#CCCCCC"
      />
    </svg>
  );
};

export default DolarIcon;
