// Import necessary dependencies
import React from "react";
import { Formik, Form } from "formik";
import TextField from "../../components/TextField";

import styles from "./ContactForm.module.scss";
import { TextareaAutosize } from "@mui/material";
import Textarea from "@mui/joy/Textarea";

interface ContactFormValues {
  firstName: string;
  lastName: string;
  email: string;
  subject: string;
  message: string;
}

const initialValues: ContactFormValues = {
  firstName: "",
  lastName: "",
  email: "",
  subject: "",
  message: "",
};

// Submit function
const onSubmit = (values: ContactFormValues) => {
  // Add your submit logic here
  console.log(values);
};

// ContactForm component
const ContactForm: React.FC = () => {
  return (
    <Formik
      initialValues={initialValues}
      //   validate={validate}
      onSubmit={onSubmit}
    >
      <Form>
        <div className={styles.namesContainer}>
          <TextField
            name="firstName"
            className={`${styles.halfField}`}
            label={"First Name"}
          />

          <TextField
            name="lastName"
            className={`${styles.halfField}`}
            label={"Last Name"}
          />
        </div>

        <TextField
          name="email"
          className={`${styles.fullField}`}
          label={"Email Address"}
        />

        <TextField
          name="subject"
          className={`${styles.fullField}`}
          label={"Subject"}
        />

        <div>
          <TextareaAutosize
            placeholder={"Type your message here"}
            className={styles.textArea}
            minRows={10}
            maxRows={10}
          />
        </div>

        <div className={styles.buttonContainer}>
          <button className={styles.submitBtn}>Submit</button>
        </div>
      </Form>
    </Formik>
  );
};

export default ContactForm;
