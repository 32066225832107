"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RegisterResponseSchema = exports.RegisterRequestSchema = void 0;
var zod_1 = require("zod");
var shared_1 = require("../shared");
exports.RegisterRequestSchema = zod_1.z.object({
    type: zod_1.z.nativeEnum(shared_1.UserType),
    fullName: zod_1.z.string().min(1),
    email: zod_1.z.string().email(),
    password: zod_1.z.string().min(3)
});
exports.RegisterResponseSchema = zod_1.z.object({
    user: shared_1.UserSchema
}).merge(shared_1.JwtResponseSchema);
