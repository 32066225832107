import { useEffect, useState } from "react";
import ContentBackgroundContainer from "../../components/ContentBackgroundContainer";
import { useGetPagesQuery } from "../../query-hooks";
import { useParams } from "react-router-dom";
import ContentContainer from "../../components/ContentContainer";

import styles from "./StaticPage.module.scss";
import { getFileSrc } from "../../utils";

import parseHtml from "html-react-parser";

const StaticPage = () => {
  const { slug } = useParams<{ slug: string }>();
  const { getPages, data: pages } = useGetPagesQuery();

  useEffect(() => {
    getPages({
      pagination: {
        page: 0,
        rowsPerPage: 1,
      },
      filter: {
        slug,
      },
    });
  }, [slug]);

  const page = pages?.pages[0];

  // const divStyle = {
  //   backgroundImage: `url(${getFileSrc(pages?.pages[0]?.imageUrl || "")}`,
  //   backgroundSize: "contain",
  //   backgroundPosition: "center",
  // };

  const divStyle = {
    backgroundImage: `url(${getFileSrc(pages?.pages[0]?.imageUrl || "")}`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    // backgroundPositionY: "top",
  };

  return (
    <ContentBackgroundContainer>
      <div className={styles.container}></div>
      <div style={divStyle} className={styles.photoContainer} />
      <ContentContainer className={styles.contentContainer}>
        {page ? (
          <>
            <div className={styles.title}>{page?.title}</div>
            <div className={styles.text}>{parseHtml(page?.text)}</div>{" "}
          </>
        ) : null}
      </ContentContainer>
    </ContentBackgroundContainer>
  );
};

export default StaticPage;
