import EditUserForm, { EditUserFormValues } from "./EditUserForm";
import { useReactiveState, useTranslations } from "../../hooks";
import ContentHeading from "../../components/ContentHeading/ContentHeading";
import ContentBackgroundContainer from "../../components/ContentBackgroundContainer";
import { userState } from "../../state";
import { useEditUserQuery, useGetUsersQuery } from "../../query-hooks";
import { useEffect } from "react";
import { UserStatus, UserType } from "@apps/sdk";

import { addToast, ToastMessageType } from "../../utils";

const MyProfile: React.FC = () => {
  const { current: currentUser } = useReactiveState(userState);

  const { translate } = useTranslations();

  const { getUsers, data: userData } = useGetUsersQuery();

  useEffect(() => {
    getUsers({
      pagination: {
        page: 0,
        rowsPerPage: 1,
      },
      filter: {
        id: currentUser?.id || "",
      },
    });
  }, []);

  const { editUser, error, data: updatedUser } = useEditUserQuery();

  useEffect(() => {
    userState.set(updatedUser || currentUser);
  }, [updatedUser]);

  const handleSubmit = (values: EditUserFormValues): void => {
    //TODO: Is the status here?
    const payload = {
      ...values,
      status: UserStatus.Approved,
    };

    editUser(payload);

    if (error) {
      addToast(error.errors[0].message, ToastMessageType.ERROR);
    } else {
      addToast(
        translate((t) => t.CHANGES_MADE_SUCCESSFULLY),
        ToastMessageType.SUCCESS
      );
    }
  };

  const user = updatedUser || userData?.users[0];

  const isEdit = !!userData;

  return (
    <ContentBackgroundContainer>
      <ContentHeading
        photoUrl={user?.photoUrl || ""}
        name={user?.fullName || ""}
        userType={user?.type || UserType.Volunteer}
      />
      <EditUserForm data={user} onSubmit={handleSubmit} isEdit={isEdit} />
    </ContentBackgroundContainer>
  );
};

export default MyProfile;
