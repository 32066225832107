interface IconProps {
  iconClassName?: string;
}

const LocationIcon: React.FC<IconProps> = ({ iconClassName }) => {
  return (
    <div className={iconClassName}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="24"
        viewBox="0 0 17 24"
        fill="none"
      >
        <path
          d="M8.167 2.20744e-06C7.09427 -0.000786757 6.03192 0.209921 5.04069 0.620071C4.04947 1.03022 3.14884 1.63177 2.3903 2.3903C1.63177 3.14883 1.03022 4.04947 0.620071 5.04069C0.20992 6.03192 -0.000786757 7.09427 2.20744e-06 8.167C2.20744e-06 14.292 8.167 23.334 8.167 23.334C8.167 23.334 16.334 14.292 16.334 8.167C16.3348 7.09427 16.1241 6.03192 15.7139 5.04069C15.3038 4.04947 14.7022 3.14883 13.9437 2.3903C13.1852 1.63177 12.2845 1.03022 11.2933 0.620071C10.3021 0.209921 9.23973 -0.000786757 8.167 2.20744e-06ZM8.167 11.083C7.59007 11.083 7.0261 10.9119 6.5464 10.5914C6.06671 10.2709 5.69283 9.8153 5.47205 9.28229C5.25126 8.74928 5.1935 8.16277 5.30605 7.59692C5.4186 7.03108 5.69642 6.51132 6.10437 6.10337C6.51232 5.69542 7.03208 5.4176 7.59792 5.30505C8.16377 5.1925 8.75028 5.25027 9.28329 5.47105C9.8163 5.69183 10.2719 6.06571 10.5924 6.5454C10.9129 7.0251 11.084 7.58907 11.084 8.166C11.0837 8.93956 10.7763 9.68135 10.2293 10.2283C9.68235 10.7753 8.94056 11.0827 8.167 11.083Z"
          fill="#CCCCCC"
        />
      </svg>
    </div>
  );
};

export default LocationIcon;
