var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import axios from "axios";
import FormData from 'form-data';
import { appendFilesToFormData, hasFilesInPayload } from "./utils";
var NetworkClient = /** @class */ (function () {
    function NetworkClient(options) {
        var _this = this;
        this.rootHeaders = {};
        this.query = function (options) { return __awaiter(_this, void 0, void 0, function () {
            var query, payload, onSuccess, onError, headers, onDownloadProgress, onUploadProgress, result, resultData, body, isMultipart, formData, formDataBody, isSuccess, data, error_1, axiosError;
            var _a, _b, _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        query = options.query, payload = options.payload, onSuccess = options.onSuccess, onError = options.onError, headers = options.headers, onDownloadProgress = options.onDownloadProgress, onUploadProgress = options.onUploadProgress;
                        result = {};
                        body = payload || {};
                        isMultipart = hasFilesInPayload(body);
                        if (isMultipart) {
                            formData = new FormData();
                            formDataBody = __assign({}, payload);
                            appendFilesToFormData(formDataBody, formData);
                            formData.append('body', JSON.stringify(formDataBody));
                            body = formData;
                        }
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 4, , 5]);
                        return [4 /*yield*/, axios.post("".concat(this.serverUrl, "/").concat(query), body, {
                                headers: __assign(__assign(__assign({}, (isMultipart ? { 'content-type': 'multipart/form-data' } : { 'content-type': 'application/json' })), this.rootHeaders), (headers || {})),
                                onDownloadProgress: onDownloadProgress,
                                onUploadProgress: onUploadProgress
                            })];
                    case 2:
                        resultData = _d.sent();
                        isSuccess = resultData.status >= 200 && resultData.status < 300;
                        return [4 /*yield*/, resultData.data];
                    case 3:
                        data = _d.sent();
                        if (!isSuccess) {
                            result.error = data;
                            onError === null || onError === void 0 ? void 0 : onError(data);
                            (_a = this.errorCallback) === null || _a === void 0 ? void 0 : _a.call(this, data);
                        }
                        else {
                            result.data = data;
                            onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess(data);
                        }
                        return [3 /*break*/, 5];
                    case 4:
                        error_1 = _d.sent();
                        axiosError = error_1;
                        if ((_b = axiosError.response) === null || _b === void 0 ? void 0 : _b.data) {
                            result.error = axiosError.response.data;
                        }
                        else {
                            result.error = {
                                errors: [{
                                        message: axiosError.message
                                    }],
                                statusCode: 500
                            };
                        }
                        onError === null || onError === void 0 ? void 0 : onError(result.error);
                        (_c = this.errorCallback) === null || _c === void 0 ? void 0 : _c.call(this, result.error);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/, result];
                }
            });
        }); };
        this.appendHeader = function (name, value) {
            _this.rootHeaders[name] = value;
        };
        this.removeHeader = function (name) {
            delete _this.rootHeaders[name];
        };
        this.onError = function (callback) {
            _this.errorCallback = callback;
        };
        this.serverUrl = options.url;
    }
    return NetworkClient;
}());
export { NetworkClient };
export var createNetworkClient = function (options) {
    var networkClint = new NetworkClient(options);
    return {
        query: function (options) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, networkClint.query(options)];
                case 1: return [2 /*return*/, _a.sent()];
            }
        }); }); },
        appendHeader: function (name, value) { return networkClint.appendHeader(name, value); },
        removeHeader: function (name) { return networkClint.removeHeader(name); },
        onError: function (callback) { return networkClint.onError(callback); }
    };
};
