import enTranslationsDictionary from "../app-constants/locales/en.json";
import { createState } from "react-reactive-tools";
import { useCallback } from "react";
import { Locale } from "../app-constants";
import { replaceAllInText } from "../utils/string";
import { persistInterceptor } from "../state/interceptors";

type LocaleTranslationDictionary = typeof enTranslationsDictionary;

type LocaleTranslationState = {
  locale: string;
  translationsDictionary: LocaleTranslationDictionary;
};

const translationsDictionaryState = createState<LocaleTranslationState>(
  "translations",
  {
    locale: Locale.EN,
    translationsDictionary: enTranslationsDictionary,
  },
  [persistInterceptor]
);

export const useTranslations = () => {
  const { translationsDictionary, locale } =
    translationsDictionaryState.subscribe();

  const changeLocale = useCallback((locale: Locale) => {
    import(`../app-constants/locales/${locale}.json`) //app-constants/locales/${locale}.json
      .then((localeJSON: LocaleTranslationDictionary) => {
        translationsDictionaryState.set({
          locale,
          translationsDictionary: localeJSON,
        });
      })
      .catch((error) => console.error(error));
  }, []);

  const translate = useCallback(
    (
      callback: (dirct: LocaleTranslationDictionary) => string,
      params?: { [key: string]: string | number }
    ) => {
      let translationString = callback(translationsDictionary);

      if (params) {
        Object.keys(params).forEach((key) => {
          translationString = replaceAllInText(
            translationString,
            `{{${key}}}`,
            params[key].toString()
          );
        });
      }

      return translationString;
    },
    [translationsDictionary]
  );

  const formatDate = useCallback(
    (dateToFormat: Date, options?: Intl.DateTimeFormatOptions) => {
      return new Intl.DateTimeFormat(locale, options).format(dateToFormat);
    },
    [locale]
  );

  const formatNumber = useCallback(
    (numberToFormat: number, options?: Intl.NumberFormatOptions) => {
      return new Intl.NumberFormat(locale, options).format(numberToFormat);
    },
    [locale]
  );

  return {
    locale,
    changeLocale,
    translate,
    formatDate,
    formatNumber,
  };
};
