import fallbackImage from "../../assets/fallbackImage.jpeg";
import { resolveAssetsPath } from "../../services";

export const getFileSrc = (imgSrc: string): string => {
  return imgSrc
    ? imgSrc.includes("blob:")
      ? imgSrc
      : resolveAssetsPath(imgSrc)
    : fallbackImage;
};
