import * as Yup from "yup";

const ERROR = {
  REQUIRED: "Required Field",
  LANGUAGES_EMPTY: "You must select at leaset one language",
};

export const validationSchema = Yup.object().shape({
  // languages: Yup.array().min(1, ERROR.LANGUAGES_EMPTY).required(ERROR.REQUIRED),
  // gender: Yup.string().required(ERROR.REQUIRED),
  // occupation: Yup.string().required(ERROR.REQUIRED),

  fullName: Yup.string().required(ERROR.REQUIRED),
  // location: Yup.string().required(ERROR.REQUIRED),
  //countryId: Yup.string().required(ERROR.REQUIRED),
  description: Yup.string().required(ERROR.REQUIRED),
});
