import React from "react";

interface IconProps {
  iconClassName?: string;
}

const FacebookIcon: React.FC<IconProps> = ({ iconClassName }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="19"
      viewBox="0 0 10 19"
      fill="none"
      className={iconClassName}
    >
      <path
        d="M9.009 10.625L9.509 7.367H6.384V5.254C6.36223 5.01067 6.39546 4.76557 6.48124 4.53682C6.56702 4.30808 6.70315 4.10156 6.87956 3.93255C7.05596 3.76354 7.26812 3.63638 7.50032 3.56046C7.73252 3.48455 7.97883 3.46183 8.221 3.494H9.641V0.72C8.80682 0.585277 7.96391 0.511747 7.119 0.5C4.544 0.5 2.862 2.06 2.862 4.885V7.368H0V10.626H2.862V18.5H6.384V10.625H9.009Z"
        fill="#CCCCCC"
      />
    </svg>
  );
};

export default FacebookIcon;
