import EventSettingsForm from "./EventSettingsForm";

import { useNavigate, useParams } from "react-router-dom";
import { useReactiveState } from "../../../hooks";
import ContentHeading from "../../../components/ContentHeading";
import ContentBackgroundContainer from "../../../components/ContentBackgroundContainer";
import { useEffect } from "react";
import { userState } from "../../../state";
import {
  CreateSportEventRequest,
  GetSportEventsResponse,
  SportEventCosts,
  SportEventStatus,
  UserStatus,
  UserType,
} from "@apps/sdk";
import {
  useCreateEventQuery,
  useGetSportEventsQuery,
} from "../../../query-hooks";
import { useEditSportEventQuery } from "../../../query-hooks/useEditSportEventQuery";
import { toast } from "react-toastify";

const EventSettings = () => {
  const navigate = useNavigate();

  const { eventId } = useParams<{ eventId: string }>();

  const isEdit = !!eventId;

  const { current: user } = useReactiveState(userState);

  const { createEvent, data: createdSportEvent } = useCreateEventQuery();

  const { getSportEvents, data: events } = useGetSportEventsQuery();

  const { editSportEvent, data: updatedEditedSportEvent } =
    useEditSportEventQuery();

  useEffect(() => {
    if (!eventId) {
      return;
    }

    getSportEvents({
      pagination: {
        page: 0,
        rowsPerPage: 1,
      },
      filter: {
        id: eventId,
      },
    });
  }, [eventId]);

  const handleSubmit = (values: CreateSportEventRequest): void => {
    values.keyResponsibilities.list = values.keyResponsibilities.list.filter(
      (x) => x !== ""
    );

    values.otherRequirements.list = values.otherRequirements.list.filter(
      (x) => x !== ""
    );

    if (!isEdit) {
      if (!values.image?.file) {
        toast.error("Image required!");
        console.log("toast");
        return;
      }
    }

    if (isEdit) {
      const payload = {
        ...values,
        status: values.status.toLocaleLowerCase() as SportEventStatus,
        dateFrom: new Date(values.dateFrom).getTime().toString(),
        dateTo: new Date(values.dateTo).getTime().toString(),
        id: eventId,
        volunteersCount: Number(values.volunteersCount),
        workingHoursCount: Number(values.workingHoursCount),
      };

      editSportEvent(payload);
    } else {
      const payload = {
        ...values,
        status: values.status.toLocaleLowerCase() as SportEventStatus,
        dateFrom: new Date(values.dateFrom).getTime().toString(),
        dateTo: new Date(values.dateTo).getTime().toString(),
        volunteersCount: Number(values.volunteersCount),
        workingHoursCount: Number(values.workingHoursCount),
      };

      createEvent(payload);
    }
  };

  useEffect(() => {
    if (!createdSportEvent) {
      return;
    }

    navigate(`/event/edit/${createdSportEvent?.id}`);
  }, [createdSportEvent]);

  const DEFAULT_CREATED_BY = {
    id: "",
    email: "",
    fullName: "",
    type: UserType.SportOrganization,
    phoneNumber: "",
    description: "",
    socials: {
      links: [],
    },
    photoUrl: "",
    status: UserStatus.Approved,
  };

  const DEFAULTS: GetSportEventsResponse["sportEvents"][0] = {
    id: "",
    name: "",
    status: SportEventStatus.Draft,
    dateFrom: "",
    dateTo: "",
    city: "",
    country: { id: "", name: "" },
    description: "",
    costs: SportEventCosts.NotCovered,
    volunteersCount: 0,
    workingHoursCount: 0,
    approvedVolunteersCount: 0,
    keyResponsibilities: { list: [] },
    otherRequirements: { list: [] },
    createdBy: DEFAULT_CREATED_BY,
    imageUrl: "",
  };

  let event;
  if (isEdit) {
    event =
      createdSportEvent || updatedEditedSportEvent || events?.sportEvents[0];
  } else {
    event = DEFAULTS;
  }

  return (
    <ContentBackgroundContainer>
      <ContentHeading
        photoUrl={user?.photoUrl || ""}
        name={user?.fullName || ""}
        userType={user?.type || UserType.Volunteer}
      />

      {event ? (
        <EventSettingsForm
          data={event}
          onSubmit={handleSubmit}
          isEdit={isEdit}
        />
      ) : null}
    </ContentBackgroundContainer>
  );
};

export default EventSettings;
