"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetEducationResourcesResponseSchema = exports.GetEducationResourcesRequestSchema = void 0;
var zod_1 = require("zod");
var shared_1 = require("../shared");
exports.GetEducationResourcesRequestSchema = zod_1.z.object({
    pagination: shared_1.PaginationSchema,
    filter: zod_1.z.object({
        id: zod_1.z.string().optional(),
        slug: zod_1.z.string().optional(),
        name: zod_1.z.string().optional(),
        status: zod_1.z.nativeEnum(shared_1.EducationResourceStatus).optional()
    }).optional()
});
exports.GetEducationResourcesResponseSchema = zod_1.z.object({
    resources: zod_1.z.array(shared_1.EducationResourceSchema),
    maxCount: zod_1.z.number(),
});
