"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetBlogsResponseSchema = exports.GetBlogsRequestSchema = void 0;
var zod_1 = require("zod");
var shared_1 = require("../shared");
exports.GetBlogsRequestSchema = zod_1.z.object({
    pagination: shared_1.PaginationSchema,
    filter: zod_1.z.object({
        id: zod_1.z.string().optional(),
        slug: zod_1.z.string().optional(),
        title: zod_1.z.string().optional(),
        status: zod_1.z.nativeEnum(shared_1.BlogStatus).optional(),
    }).optional()
});
exports.GetBlogsResponseSchema = zod_1.z.object({
    blogs: zod_1.z.array(shared_1.BlogSchema),
    maxCount: zod_1.z.number(),
});
