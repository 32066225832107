interface IconProps {
  iconClassName?: string;
}

const PhoneIcon: React.FC<IconProps> = ({ iconClassName }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="22"
      viewBox="0 0 14 22"
      fill="none"
      className={iconClassName}
    >
      <path
        d="M11 0H3C2.20435 0 1.44129 0.316071 0.878679 0.87868C0.31607 1.44129 0 2.20435 0 3V19C0 19.7956 0.31607 20.5587 0.878679 21.1213C1.44129 21.6839 2.20435 22 3 22H11C11.7956 22 12.5587 21.6839 13.1213 21.1213C13.6839 20.5587 14 19.7956 14 19V3C14 2.20435 13.6839 1.44129 13.1213 0.87868C12.5587 0.316071 11.7956 0 11 0ZM9 20H5V19H9V20ZM12.25 17H1.75V3H12.25V17Z"
        fill="#CCCCCC"
      />
    </svg>
  );
};

export default PhoneIcon;
