import { useEffect, useState } from "react";
import ContentBackgroundContainer from "../../components/ContentBackgroundContainer";
import { useGetEducationalResourcesQuery } from "../../query-hooks";

import styles from "./EducationalResouces.module.scss";
import EducationalResourceBox from "./EducationalResourceBox";
import { InputAdornment, Pagination } from "@mui/material";
import DefaultTextField from "../../components/TextField/DefaultTextField";
import { useTranslations } from "../../hooks";
import { Search } from "@mui/icons-material";

const EducationalResources = () => {
  const [isSingleSearchInUse, setSingleSearchInUse] = useState(false);
  const [paginationCount, setPaginationCount] = useState(0);

  const [typeFilterValue, setTypeFilterValue] = useState({ type: "" });
  const [pageSize, setPageSize] = useState(6);
  const [activePage, setActivePage] = useState(1);
  const [filterText, setFilterText] = useState<string>();

  const { translate } = useTranslations();

  const handleTextFilterChange = (e) => {
    setFilterText(e.target.value);
  };

  const { getEducationalResources, data: resourcesData } =
    useGetEducationalResourcesQuery();

  const maxCount = resourcesData?.maxCount || 0;

  useEffect(() => {
    getEducationalResources({
      pagination: {
        page: 0,
        rowsPerPage: pageSize,
      },
    });
  }, []);

  useEffect(() => {
    if (maxCount !== 0) {
      setPaginationCount(Math.ceil(maxCount / pageSize));
    }
  }, [maxCount]);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) =>
    setActivePage(value);

  useEffect(() => {
    getEducationalResources({
      pagination: {
        page: activePage - 1,
        rowsPerPage: pageSize,
      },
    });
  }, [activePage]);

  useEffect(() => {
    if (filterText !== "") {
      getEducationalResources({
        pagination: {
          page: 0,
          rowsPerPage: pageSize,
        },
        filter: {
          name: filterText,
        },
      });

      return;
    }

    getEducationalResources({
      pagination: {
        page: 0,
        rowsPerPage: pageSize,
      },
    });
  }, [filterText]);

  const resources = resourcesData?.resources;

  return (
    <ContentBackgroundContainer>
      <div className={styles.educationalResourcesContainer}>
        <div className={styles.title}>EDUCATIOANL RESOURCES</div>
        <div className={styles.subtitle}>
          Enhancing Volunteer Engament and Expertise in Sports
        </div>
      </div>

      <div className={styles.educationalResourcesFilterContainer}>
        <DefaultTextField
          value={filterText}
          name="datesRange"
          className={`${styles.field}`}
          placeholder={translate((t) => t.SEARCH_RESOURCES)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          onChange={handleTextFilterChange}
        />
      </div>

      <div className={styles.resourcesContainer}>
        {resources?.map((educationalResource) => (
          <EducationalResourceBox
            educationalResource={educationalResource}
            key={educationalResource.id}
          />
        ))}
      </div>

      <div className={styles.paginationContainer}>
        <Pagination
          count={paginationCount}
          page={activePage}
          onChange={handleChange}
          size="small"
        />
      </div>
    </ContentBackgroundContainer>
  );
};

export default EducationalResources;
