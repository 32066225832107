import { SportEvent } from "@apps/sdk";
import React from "react";
import EventBoxActions from "../../components/EventBoxes/EventBoxActions/EventBoxActions";
import styles from "./CreatedEventsList.module.scss";

type CreatedEventsListProps = {
  events: SportEvent[];
};

const CreatedEventsList: React.FC<CreatedEventsListProps> = ({ events }) => {
  return (
    <div className={styles.eventsContainer}>
      {events.map((event) => (
        <EventBoxActions event={event} key={event.id} />
      ))}
    </div>
  );
};

export default CreatedEventsList;
