"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createService = exports.Query = void 0;
var core_shared_1 = require("@packages/core-shared");
var Query;
(function (Query) {
    Query["Login"] = "frontoffice-login";
    Query["CheckIsLoggedIn"] = "frontoffice-check-is-logged-in";
    Query["Register"] = "frontoffice-register";
    Query["EditUser"] = "frontoffice-edit-user";
    Query["GetUsers"] = "frontoffice-get-users";
    Query["ApplyForSportEvent"] = "frontoffice-apply-for-sport-event";
    Query["CreateSportEvent"] = "frontoffice-create-sport-event";
    Query["EditSportEvent"] = "frontoffice-edit-sport-event";
    Query["UpdateSportEventUserStatus"] = "frontoffice-update-sport-event-user-status";
    Query["GetSportEvents"] = "frontoffice-get-sport-events";
    Query["GetSportEventUsers"] = "frontoffice-get-sport-event-users";
    Query["CreateOrEditEducationResource"] = "frontoffice-create-or-edit-education-resource";
    Query["GetEducationResources"] = "frontoffice-get-education-resources";
    Query["GetCountries"] = "frontoffice-get-countries";
    Query["GetBlogs"] = "frontoffice-get-blogs";
    Query["CreateOrEditBlog"] = "frontoffice-create-or-edit-blog";
    Query["GetPages"] = "frontoffice-get-pages";
    Query["CreateOrEditPage"] = "frontoffice-create-or-edit-page";
    Query["GetCertificates"] = "frontoffice-get-certificates";
    Query["GenerateCertificate"] = "frontoffice-generate-certificate";
})(Query || (exports.Query = Query = {}));
var createService = function (serviceUrl) { return (0, core_shared_1.createNetworkClient)({ url: serviceUrl }); };
exports.createService = createService;
