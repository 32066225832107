import { Upload } from '../types';
var FILE_PREFIX = '__file:';
var appendFileToFormData = function (upload, formData, currentFileIndex) {
    var key = "".concat(FILE_PREFIX).concat(currentFileIndex);
    formData.append(key, upload.file);
    return { key: key, nextFileIndex: currentFileIndex + 1 };
};
export var appendFilesToFormData = function (value, formData) {
    var currentFileIndex = 0;
    var appendFiles = function (value) {
        if (value instanceof Upload) {
            var _a = appendFileToFormData(value, formData, currentFileIndex), key = _a.key, nextFileIndex = _a.nextFileIndex;
            currentFileIndex = nextFileIndex;
            return key;
        }
        if (value && typeof value === 'object') {
            for (var property in value) {
                if (value[property] instanceof Upload) {
                    var _b = appendFileToFormData(value[property], formData, currentFileIndex), key = _b.key, nextFileIndex = _b.nextFileIndex;
                    currentFileIndex = nextFileIndex;
                    value[property] = key;
                }
                else if (Array.isArray(value[property])) {
                    value[property] = value[property].map(function (x) { return appendFiles(x); });
                }
                else {
                    appendFiles(value[property]);
                }
            }
        }
        return value;
    };
    appendFiles(value);
};
