import { monthNamesCapital } from "./monthNames";

export function getEventContinuity(startDate: string, endDate: string): string {
  const startParts = startDate.split(".");
  const endParts = endDate.split(".");

  const startDay = startParts[1];
  const startMonth = Number(startParts[0]);
  const startYear = startParts[2];

  const endDay = endParts[1];
  const endMonth = Number(endParts[0]);
  const endYear = endParts[2];

  if (startMonth === endMonth && startYear === endYear && startDay === endDay) {
    return `${startDay} ${getMonthName(startMonth)}, ${startYear}`;
  } else if (startMonth === endMonth && startYear === endYear) {
    return `${startDay}-${endDay} ${getMonthName(startMonth)}, ${startYear}`;
  } else if (startYear === endYear) {
    return `${startDay} ${getMonthName(startMonth)} - ${endDay} ${getMonthName(
      endMonth
    )}, ${startYear}`;
  } else {
    return `${startDay} ${getMonthName(
      startMonth
    )}, ${startYear} - ${endDay} ${getMonthName(endMonth)}, ${endYear}`;
  }
}

function getMonthName(month: number): string {
  const months = monthNamesCapital;
  return months[month - 1];
}
