import { GetSportEventsResponse, SportEvent } from "@apps/sdk";
import EventBoxSlide from "../../components/EventBoxes/EventBoxSlide";

import styles from "./EventsList.module.scss";

type EventListProps = {
  events: SportEvent[];
};

const EventsList: React.FC<EventListProps> = (props) => {
  const events = props.events;

  if (!events) {
    return <></>;
  }

  return (
    <div className={styles.eventsContainer}>
      {events.map((eventInfo) => (
        <EventBoxSlide {...eventInfo} className={styles.box} />
      ))}
    </div>
  );
};

export default EventsList;
