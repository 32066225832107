import React from "react";
import { Calendar, momentLocalizer, Event } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import { Link } from "react-router-dom";
import { SportEvent } from "@apps/sdk";
import { dateFromTimestamp } from "@packages/core-shared";

const localizer = momentLocalizer(moment);

type BigCalendarProps = {
  eventsData: SportEvent[];
};

type CalendarEvent = {
  id: string;
  title: string;
  start: Date;
  end: Date;
};

const EventComponent: React.FC<{ event: CalendarEvent }> = ({ event }) => (
  <Link
    to={`/event/${event.id}`}
    style={{ color: "#fff", textDecoration: "none" }}
  >
    <div>{event.title}</div>
  </Link>
);

const BigCalendar = ({ eventsData }: BigCalendarProps) => {
  const generateEvents = () => {
    const events: CalendarEvent[] = [];

    eventsData.forEach((event) => {
      events.push({
        id: event.id,
        title: event.name,
        start: dateFromTimestamp(event.dateFrom),
        end: dateFromTimestamp(event.dateTo),
      });
    });

    return events;
  };

  const events = generateEvents();

  const eventStyleGetter = () => {
    return {
      style: {
        backgroundColor: "#333787",

        padding: 5,
        borderRadius: 5,
      },
    };
  };

  return (
    <div>
      <Calendar
        events={events}
        startAccessor="start"
        endAccessor="end"
        localizer={localizer}
        style={{ height: 700 }}
        components={{
          event: EventComponent,
        }}
        eventPropGetter={eventStyleGetter}
      />
    </div>
  );
};

export default BigCalendar;
