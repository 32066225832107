import { useEffect, useState } from "react";
import { useReactiveState } from ".";
import { useCheckIsLoggedInQuery } from "../query-hooks";
import { isValidToken } from "../utils/auth.utils";
import { tokenDataState } from "../state";

export const useCheckIsLoggedIn = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const { current: tokenData } = useReactiveState(tokenDataState);

  const { checkIsLoggedIn, data, loading } = useCheckIsLoggedInQuery();

  useEffect(() => {
    if (!tokenData) {
      setIsLoggedIn(false);

      return;
    }

    if (!isValidToken(tokenData)) {
      setIsLoggedIn(false);

      return;
    }

    checkIsLoggedIn();
  }, [tokenData, checkIsLoggedIn])

  useEffect(() => {
    if (!data?.isLoggedIn) {
      setIsLoggedIn(false);

      return;
    }

    setIsLoggedIn(true);
  }, [data])

  return {
    isLoggedIn: isLoggedIn && !!data?.isLoggedIn,
    loading
  };
};
