import {
  GetSportEventUsersResponse,
  SportEvent,
  SportEventUserStatus,
  User,
  UserType,
} from "@apps/sdk";
import ContentBackgroundContainer from "../../components/ContentBackgroundContainer";
import ContentHeading from "../../components/ContentHeading";
import { useReactiveState } from "../../hooks";
import { userState } from "../../state";
import { useParams } from "react-router-dom";
import {
  useCheckIsLoggedInQuery,
  useGetCertificatesQuery,
  useGetSportEventUsers,
  useGetSportEventsQuery,
  useUpdateSportEventUserStatusQuery,
} from "../../query-hooks";
import { useEffect, useState } from "react";
import ContentContainer from "../../components/ContentContainer";
import ProgressBar from "../../components/ProgressBar";
import { getPercentage } from "../../utils/numbers";

import styles from "./AppliedVounteers.module.scss";
import { Divider } from "@mui/material";
import VolunteerBoxActions from "./VolunteerBoxActions";
import { getFileSrc } from "../../utils";

const AppliedVolunteers = () => {
  const { eventId } = useParams<{ eventId: string }>();

  const { checkIsLoggedIn, data: isLogged } = useCheckIsLoggedInQuery();
  const { current: user } = useReactiveState(userState);
  const { getSportEventUsers, data: volunteers } = useGetSportEventUsers();
  const { getSportEvents, data: events } = useGetSportEventsQuery();
  const { getCertificates, data: certificates } = useGetCertificatesQuery();

  const { updateSportEventUserStatus } = useUpdateSportEventUserStatusQuery();

  const [volunteersState, setVolunttersState] = useState<any>();

  const [eventData, setEventData] = useState<SportEvent>();

  const [eventImage, setEventImage] = useState<string>();

  console.log("isLogged", isLogged?.isLoggedIn);

  useEffect(() => {
    checkIsLoggedIn();
  }, []);

  useEffect(() => {
    if (!eventId) {
      return;
    }

    getCertificates({
      pagination: {
        page: 0,
        rowsPerPage: 99,
      },
      filter: {
        eventId: eventId,
      },
    });

    getSportEventUsers({
      eventId,
    });
  }, [eventId, isLogged]);

  useEffect(() => {
    getSportEvents({
      pagination: {
        page: 0,
        rowsPerPage: 1,
      },
      filter: {
        id: eventId,
      },
    });
  }, []);

  useEffect(() => {
    setVolunttersState(volunteers?.users);
  }, [volunteers]);

  const handleRadioChange = (volunteerIndex: number, value: string) => {
    setVolunttersState((prevState: any) => {
      return prevState.map((volunteer: User, index: number) => {
        if (index === volunteerIndex) {
          return {
            ...volunteer,
            status: value as SportEventUserStatus,
          };
        }

        return volunteer;
      });
    });
  };

  useEffect(() => {
    setEventData(events?.sportEvents[0]);
    setEventImage(getFileSrc(events?.sportEvents[0].imageUrl || ""));
  }, [events]);

  const handleSaveChanges = async () => {
    const updatePromises = volunteersState
      .filter(
        (x: { id: string; status: SportEventUserStatus }) =>
          x.status !== volunteers!.users.find((y) => y.id === x.id)?.status
      )
      .map((volunteer: { status: SportEventUserStatus; id: string }) => {
        const payload = {
          id: volunteer.id,
          status: volunteer.status,
        };

        return updateSportEventUserStatus(payload);
      });

    await Promise.all(updatePromises);

    await getSportEvents({
      pagination: {
        page: 0,
        rowsPerPage: 1,
      },
      filter: {
        id: eventId,
      },
    });

    if (eventId) {
      await getSportEventUsers({
        eventId,
      });
    }
  };

  const divStyle = {
    backgroundImage: `url(${eventImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  return (
    <>
      <ContentBackgroundContainer className={styles.contentBackgroundContainer}>
        <ContentHeading
          photoUrl={user?.photoUrl || ""}
          name={user?.fullName || ""}
          userType={user?.type || UserType.Volunteer}
        />

        <div style={divStyle} className={styles.photoContainer} />

        <ContentContainer className={styles.contentContainer}>
          <div className={styles.heading}>{eventData?.name}</div>

          <div className={styles.progBarContainer}>
            <ProgressBar
              value={getPercentage(
                eventData?.approvedVolunteersCount as number,
                eventData?.volunteersCount as number
              )}
              participants={eventData?.volunteersCount}
              color="inherit"
              className={styles.progressBar}
              sx={{
                backgroundColor: "rgb(241,241,241, 0.1)",
                "& .MuiLinearProgress-bar": {
                  backgroundColor: "#33BBC5",
                },
              }}
            />

            <div className={styles.volunteersText}>
              {eventData?.approvedVolunteersCount} out of{" "}
              {eventData?.volunteersCount} volunteers joined
            </div>
          </div>

          <div className={styles.subHeading}>Applied volunteers</div>

          {volunteers ? (
            <>
              {volunteersState
                ? volunteersState.map((volunteer: User, index: number) => (
                    <VolunteerBoxActions
                      volunteer={volunteer}
                      eventId={eventId}
                      key={volunteer.id}
                      onRadioChange={(event) =>
                        handleRadioChange(index, event.target.value)
                      }
                    />
                  ))
                : null}
            </>
          ) : null}

          <Divider className={styles.divider} />

          <div className={styles.buttonContainer}>
            <button
              className={styles.saveChangesButton}
              onClick={handleSaveChanges}
            >
              Save changes
            </button>
          </div>
        </ContentContainer>
      </ContentBackgroundContainer>
    </>
  );
};

export default AppliedVolunteers;
