import { EditUserRequest, EditUserResponse, Query } from "@apps/sdk";
import { useCallback } from "react";
import { useQueryState } from "./common";
import { frontofficeService } from "../services";
import { toast } from "react-toastify";

export const useEditUserQuery = () => {
  const { data, error, loading, setState, setLoading } =
    useQueryState<EditUserResponse>();

  const editUser = useCallback(async (payload: EditUserRequest) => {
    setLoading(true);

    setState(
      await frontofficeService.query({
        query: Query.EditUser,
        payload: payload,
        onSuccess: (response: EditUserResponse) => {
          toast.success("User edit successfull", {
            position: "top-right",
          });
        },
        onError: (errorResponse: any) => {
          errorResponse.errors.forEach(
            (error: any) => toast.error(error.message),
            {
              position: "top-right",
            }
          );
        },
      })
    );

    setLoading(false);
  }, []);

  return {
    editUser,
    data,
    error,
    loading,
  };
};
