import { Dialog } from "@mui/material";
import { useFormikContext, useField } from "formik";
import { useTextField } from "../../hooks";
import React, { useState, useEffect } from "react";
import { addToast, ToastMessageType, isValidUrl } from "../../utils";

import ImageCropper from "./ImageCropper";
import {
  DEFAULT_ACCEPT_TYPES,
  DEFAULT_CROP_ASPECT_RATIO,
  ImageMimeTypes,
  MAX_FILE_SIZE_MB,
} from "./ImageUpload.constants";
import {
  loadImage,
  isFileSizeBiggerThenMB,
  processImage,
  checkForAllowedMimeType,
} from "./ImageUpload.utils";

type AcceptType = Readonly<{
  type: string;
  mime: string;
}>;

type ImageUploadProps = Readonly<{
  imgSourceFieldName: string;
  imgFileFieldName: string;
  imgAltText?: string;
  textInputLabel?: string;
  showTextInput?: boolean;
  options?: Compressor.Options;
  expectedAspectRatio?: number;
  noProcess?: boolean;
  acceptFiles?: AcceptType[];
  outsideErrorText?: string;
  isSingleFile?: boolean;
  showPlaceholder?: boolean;
  circularCrop?: boolean;
}>;

export type ImageUploadComponentProps = Readonly<{
  showTextInput?: boolean;
  textInputLabel?: string;
  imgSourceFieldName: string;
  imgFileFieldName: string;
  imgAltText?: string;
  imgSrc?: string;
  showError: boolean;
  errorText: string;
  outsideErrorText?: string;
  onFileChange: (
    e: React.ChangeEvent<
      HTMLInputElement & { target: HTMLInputElement & EventTarget }
    >
  ) => void;
  acceptFileTypes: string[];
}>;

const DEFAULT_MIME_TYPES = DEFAULT_ACCEPT_TYPES.map((x) => x.mime);
const DEFAULT_FILE_TYPES = DEFAULT_ACCEPT_TYPES.map((x) => x.type);

export const withImageUpload =
  (Component: React.FC<ImageUploadComponentProps>) =>
  ({
    imgSourceFieldName,
    imgFileFieldName,
    imgAltText,
    textInputLabel,
    showTextInput,
    options,
    expectedAspectRatio = 1,
    noProcess,
    acceptFiles,
    outsideErrorText,
    isSingleFile,
    circularCrop = false,
  }: ImageUploadProps): JSX.Element => {
    const { setFieldValue } = useFormikContext<any>();

    const {
      value: fileNameValue,
      error,
      helperText,
    } = useTextField<string>({
      name: imgSourceFieldName,
      skipIsSubmittedCheck: true,
    });

    const [{ value: fileUploadValue }] = useField<File>(imgFileFieldName);
    const [imgSrc, setImgSrc] = useState<string>();

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [fileToCrop, setFileToCrop] = useState<File>();

    const acceptMimeTypes =
      acceptFiles?.map((x) => x.mime) || DEFAULT_MIME_TYPES;
    const acceptFileTypes =
      acceptFiles?.map((x) => x.type) || DEFAULT_FILE_TYPES;

    useEffect(() => {
      if (!fileNameValue || isValidUrl(fileNameValue)) {
        setImgSrc(fileNameValue);
        setFieldValue(imgFileFieldName, undefined);
        return;
      }

      if (fileUploadValue) {
        return;
      }

      if (fileNameValue) {
        const assetImgSrc = fileNameValue.startsWith("blob")
          ? fileNameValue
          : loadImage(fileNameValue);

        setImgSrc(assetImgSrc);
        setFieldValue(imgSourceFieldName, fileNameValue);
      }
    }, [
      imgFileFieldName,
      imgSourceFieldName,
      setFieldValue,
      fileNameValue,
      fileUploadValue,
    ]);

    const handleFileChange = async (
      e: React.ChangeEvent<
        HTMLInputElement & { target: HTMLInputElement & EventTarget }
      >
    ) => {
      if (!e?.target) {
        return;
      }

      const file = (e?.target?.files || [])[0];

      if (file) {
        if (isFileSizeBiggerThenMB(file.size, MAX_FILE_SIZE_MB)) {
          return;
        }

        if (
          acceptMimeTypes?.length &&
          !checkForAllowedMimeType(file.type, acceptMimeTypes)
        ) {
          addToast(
            `Upload Error: Image types allowed are ${acceptFileTypes.join(
              ", "
            )}!`,
            ToastMessageType.ERROR
          );

          return;
        }

        if (file.type === ImageMimeTypes.SVG || noProcess) {
          setFieldValue(imgFileFieldName, noProcess ? file : [file]);
          setFieldValue(imgSourceFieldName, file.name);
          setImgSrc(URL.createObjectURL(file));

          return;
        }

        if (expectedAspectRatio) {
          setIsDialogOpen(true);
          setFileToCrop(file);
          e.target.value = "";

          return;
        }

        const { compressSrc, files } = await processImage(file, options);

        setFieldValue(imgSourceFieldName, file.name);

        if (isSingleFile) {
          setFieldValue(imgFileFieldName, files[0]);
          return;
        }

        setFieldValue(imgFileFieldName, files);
        setImgSrc(compressSrc);
      }
    };

    const handleDialogCancel = () => {
      setIsDialogOpen(false);
    };

    const handleImageReady = async (imageFile: File) => {
      const { compressSrc, files } = await processImage(imageFile, options);

      // setFieldValue(imgSourceFieldName, 'upload-' + imageFile.name);
      setFieldValue(imgSourceFieldName, URL.createObjectURL(files[0]));
      setFieldValue(imgFileFieldName, files);
      setImgSrc(compressSrc);
      handleDialogCancel();
    };

    const showError = error || !!outsideErrorText;

    const componentProps = {
      showTextInput,
      textInputLabel,
      imgFileFieldName,
      imgSourceFieldName,
      imgAltText,
      imgSrc,
      showError,
      errorText: helperText,
      outsideErrorText,
      onFileChange: handleFileChange,
      acceptFileTypes,
    };

    return (
      <>
        <Component {...componentProps} />

        <Dialog onClose={handleDialogCancel} open={isDialogOpen}>
          <ImageCropper
            onClose={handleDialogCancel}
            file={fileToCrop}
            onImageReady={handleImageReady}
            aspectRatio={expectedAspectRatio || DEFAULT_CROP_ASPECT_RATIO}
            circularCrop={circularCrop}
          />
        </Dialog>
      </>
    );
  };
