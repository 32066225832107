import styles from "./EventSettings.module.scss";
import baseStyles from "../EditEvent.module.scss";

import ContentContainer from "../../../components/ContentContainer";
import TextField from "../../../components/TextField";
import {
  ErrorMessage,
  Field,
  FormikProps,
  useFormikContext,
  withFormik,
} from "formik";
import { AddCircleOutlineOutlined, TaskAlt } from "@mui/icons-material";
import {
  Avatar,
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  Input,
  LinearProgress,
  Switch,
} from "@mui/material";

import ContentButtons from "../../../components/ContentFooterButtons";
import EditorConvertToHTML from "../../../components/EditorConvertToHTML";

import { InputAdornment } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { validationSchema } from "./EventSettings.validation";
import { deviceTypeState } from "../../../state";
import { useTranslations } from "../../../hooks";

import LocationIcon from "../../../assets/icons/LocationIcon";
import CalendarIcon from "../../../assets/icons/CalendarIcon";
import EditSettingsHeaderPicture from "./EditSettingsHeaderPicture";
import {
  CreateSportEventRequest,
  EditSportEventRequest,
  GetSportEventsResponse,
  SportEventCosts,
  SportEventStatus,
} from "@apps/sdk";

import { useGetCountriesQuery } from "../../../query-hooks";

import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import { formatTimestamp } from "../../../utils/dates";

import { Upload } from "@packages/core-shared";
import DolarIcon from "../../../assets/icons/DolarIcon";
import { toast } from "react-toastify";

export type EditEventFormValues = {
  name: string;
  dateFrom: string;
  dateTo: string;
  country?: { name: string; id: string } | undefined;
  volunteersCount: number;
  workingHoursCount: number;
  keyResponsibilities: { list: [] };
  otherRequirements: { list: [] };
  costs: string;
  photo?: Upload | undefined;
  imageUrl?: string;
  logoFile?: File;
};

const DEFAULT_VALUES: EditEventFormValues = {
  name: "",
  dateFrom: "",
  dateTo: "",
  country: { id: "", name: "" },
  volunteersCount: 0,
  workingHoursCount: 0,
  keyResponsibilities: { list: [] },
  otherRequirements: { list: [] },
  costs: "",
  imageUrl: "",
};

type EditEventProps = Readonly<{
  data: GetSportEventsResponse["sportEvents"][0];
  onSubmit: (values: EditSportEventRequest) => void;
  isEdit: boolean; //CreateSportEventRequest
}>;

type EditEventFormProps = FormikProps<EditEventProps> & EditEventProps;

//----

type CreateSportEventProps = Readonly<{
  data: GetSportEventsResponse["sportEvents"][0];
  onSubmit: (values: CreateSportEventRequest) => void;
  isEdit: boolean;
}>;

type FormikDateProps = {
  dateFrom: string;
  dateTo: string;
};

type EditUserFormProps = FormikProps<
  EditSportEventRequest & { logoFile: Upload | undefined }
> &
  CreateSportEventProps;

const EventSettingsForm: React.FC<any> = (props) => {
  //
  const { isDesktop } = deviceTypeState.get();
  const { translate } = useTranslations();

  const {
    handleSubmit,
    handleBlur,
    values: {
      name,
      description,
      dateFrom,
      dateTo,
      volunteersCount,
      workingHoursCount,
      keyResponsibilities,
      otherRequirements,
      status,
      countryId,
      costs,
      logoFile,
      image,
    },
    errors,
    data,
    isEdit,
  } = props;

  let buttonFooterType: "SaveChanges" | "CreateSportEvent";
  if (isEdit) {
    buttonFooterType = "SaveChanges";
  } else {
    buttonFooterType = "CreateSportEvent";
  }

  const {
    setFieldValue,
    submitForm,
    initialValues,
    errors: formikErrors,
    touched,
  } = useFormikContext<FormikDateProps>();

  const { getCountries, data: countriesData } = useGetCountriesQuery();

  useEffect(() => {
    getCountries({
      pagination: {
        page: 0,
        rowsPerPage: 100,
      },
    });
  }, []);

  const countryOptions = countriesData?.countries.map((country) => ({
    name: country.name,
    value: country.id,
  }));

  const costsOptions = [
    { name: SportEventCosts.FullyCovered, value: SportEventCosts.FullyCovered },
    {
      name: SportEventCosts.PartiallyCovered,
      value: SportEventCosts.PartiallyCovered,
    },
    { name: SportEventCosts.NotCovered, value: SportEventCosts.NotCovered },
  ];

  const handleCostsChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const selectedCostsValue = event.target.value;
    const selectedCountry =
      costsOptions &&
      costsOptions.find((cost) => cost.value === selectedCostsValue);

    setFieldValue("costs", selectedCountry?.value);
  };

  const handleCountryChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const selectedCountryValue = event.target.value;

    const selectedCountry =
      countryOptions &&
      countryOptions.find((country) => country.value === selectedCountryValue);

    setFieldValue("country", {
      id: selectedCountry?.value,
      name: selectedCountry?.name,
    });

    setFieldValue("countryId", selectedCountry?.value);
  };

  useEffect(() => {
    if (!data) {
      return;
    }

    setFieldValue(
      "dateFrom",
      data?.dateFrom ? formatTimestamp(Number(data?.dateFrom)) : ""
    );
    setFieldValue(
      "dateTo",
      data?.dateTo ? formatTimestamp(Number(data?.dateTo)) : ""
    );
  }, [initialValues]);

  //----

  useEffect(() => {
    if (!logoFile) {
      return;
    }

    const photoPayload = new Upload(logoFile);
    setFieldValue("image", photoPayload);
  }, [logoFile]);

  const photoContainer = useMemo(
    () => (
      <EditSettingsHeaderPicture
        name="image"
        photoType="Profile"
        imgSourceFieldName={data.imageUrl ? data.imageUrl : "imageUrl"}
        imgFileFieldName="logoFile"
      />
    ),
    [setFieldValue, data.imageUrl]
  );

  return (
    <>
      {photoContainer}

      <ContentContainer className={styles.formContainer}>
        {/* {!countryData ? (
        <LinearProgress />
      ) : ( */}
        <form onSubmit={handleSubmit} noValidate>
          <div className={styles.content}>
            <TextField
              name="name"
              label={translate((t) => t.EVENT_NAME)}
              className={styles.field}
            />

            <div className={styles.userInputContainer}>
              <div className={styles.datesContainer}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    label="From Date"
                    onChange={(time) => {
                      setFieldValue("dateFrom", time);
                      touched.dateFrom = false;
                    }}
                    value={dateFrom ? new Date(dateFrom) : null}
                    className={`${styles.field} ${styles.datePicker}`}
                    views={["year", "month", "day", "hours", "minutes"]}
                    slots={{ openPickerIcon: CalendarIcon }}
                    slotProps={{
                      textField: {
                        name: "dateFrom",
                        error: touched?.dateFrom && !!formikErrors,
                        helperText: touched?.dateFrom && formikErrors?.dateFrom,
                      },
                    }}
                  />
                </LocalizationProvider>

                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    label="To Date"
                    onChange={(time) => {
                      setFieldValue("dateTo", time);
                      touched.dateTo = false;
                    }}
                    value={dateTo ? new Date(dateTo) : null}
                    className={`${styles.field} ${styles.datePicker}`}
                    views={["year", "month", "day", "hours", "minutes"]}
                    slots={{ openPickerIcon: CalendarIcon }}
                    slotProps={{
                      textField: {
                        name: "dateTo",
                        error: touched.dateTo && !!formikErrors,
                        helperText: touched.dateTo && formikErrors.dateTo,
                      },
                    }}
                  />
                </LocalizationProvider>
              </div>

              <div className={styles.countryCostsContainer}>
                <TextField
                  name="countryId"
                  label={translate((t) => t.SELECT_COUNTRY)}
                  select
                  options={countryOptions}
                  className={`${styles.field} ${styles.thirdInput}`}
                  onChange={handleCountryChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LocationIcon />
                      </InputAdornment>
                    ),
                  }}
                />

                <TextField
                  name="city"
                  className={`${styles.field} ${styles.thirdInput}`}
                  label={"City"}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LocationIcon />
                      </InputAdornment>
                    ),
                  }}
                />

                <TextField
                  name="costs"
                  label={translate((t) => t.COSTS)}
                  select
                  options={costsOptions}
                  className={`${styles.field} ${styles.thirdInput}`}
                  onChange={handleCostsChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <DolarIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>

            <div className={styles.volunteersContainer}>
              <div className={styles.volunteerContainer}>
                <TextField
                  name="volunteersCount"
                  className={`${styles.volunteerField}`}
                  size="small"
                />

                <span>{translate((t) => t.REQUIRED_NUMBER_OF_VOLUNTEERS)}</span>
              </div>

              <div className={styles.volunteerContainer}>
                <TextField
                  name="workingHoursCount"
                  className={`${styles.volunteerField}`}
                  size="small"
                />

                <span>
                  {translate((t) => t.REQUIRED_NUMBER_OF_WORKING_HOURS)}
                </span>
              </div>
            </div>

            <span className={styles.sectionNameFirst}>
              {translate((t) => t.DESCRIPTION)}
            </span>
            <FormControl>
              <EditorConvertToHTML
                html={description || ""}
                // error={!!errors.description}
                onChange={(newVal: string) =>
                  setFieldValue("description", newVal)
                }
              />

              {/* {errors.description && (
                <FormHelperText error>{errors.description}</FormHelperText>
              )} */}
            </FormControl>

            <div className={baseStyles.title}>
              {translate((t) => t.KEY_RESPONSIBILITIES)}
            </div>

            <div className={baseStyles.objectives}>
              {keyResponsibilities?.list.map((_, index) => (
                <div key={index} className={styles.objective}>
                  <TaskAlt />
                  <TextField
                    name={`keyResponsibilities['list'].${index}`}
                    label={`List Item #${index + 1}`}
                    className={baseStyles.field}
                  />
                </div>
              ))}
            </div>

            <Button
              className={baseStyles.addBtn}
              variant="text"
              disableElevation
              onClick={() =>
                setFieldValue("keyResponsibilities", {
                  list: [...(keyResponsibilities.list || []), ""],
                })
              }
              startIcon={<AddCircleOutlineOutlined />}
            >
              {translate((t) => t.ADD_MORE_POINTS)}
            </Button>

            <div className={baseStyles.title}>
              {translate((t) => t.OTHER_REQUIREMENTS)}
            </div>

            <div className={baseStyles.objectives}>
              {otherRequirements?.list.map((_, index) => (
                <div key={index} className={styles.objective}>
                  <TaskAlt />
                  <TextField
                    name={`otherRequirements['list'].${index}`}
                    label={`List Item #${index + 1}`}
                    className={baseStyles.field}
                  />
                </div>
              ))}
            </div>

            <Button
              className={baseStyles.addBtn}
              variant="text"
              disableElevation
              onClick={() =>
                setFieldValue("otherRequirements", {
                  list: [...(otherRequirements.list || []), ""],
                })
              }
              startIcon={<AddCircleOutlineOutlined />}
            >
              {translate((t) => t.ADD_MORE_POINTS)}
            </Button>
          </div>

          {/* <ErrorMessage name="_error" component="div" />

          {Object.keys(errors).length > 0 &&
            Object.keys(errors).map((fieldName) => (
              <div key={fieldName} style={{ color: "red", marginTop: "5px" }}>
                {errors[fieldName]}
              </div>
            ))} */}

          <ContentButtons type="submit" submitType={buttonFooterType} />
          {/* TODO: Add draft */}
        </form>
      </ContentContainer>
    </>
  );
};

const EventSettingsFormWrapper = withFormik<
  CreateSportEventProps,
  CreateSportEventRequest
>({
  mapPropsToValues: ({ data }) => {
    const {
      name,
      city,
      country,
      description,
      dateFrom,
      dateTo,
      volunteersCount,
      approvedVolunteersCount,
      workingHoursCount,
      keyResponsibilities,
      otherRequirements,
      costs,
    } = data;

    return {
      name,
      description: description || "",
      country,
      city,
      dateFrom,
      dateTo,
      status: SportEventStatus.Draft,
      volunteersCount,
      approvedVolunteersCount,
      workingHoursCount,
      keyResponsibilities,
      otherRequirements,
      countryId: country.id || "",
      costs,
    };
  },
  validationSchema,
  enableReinitialize: true,
  handleSubmit: (values, { props: { onSubmit } }) => {
    onSubmit(values);
  },
  displayName: "EventSettingsForm",
})(EventSettingsForm);

export default EventSettingsFormWrapper;
