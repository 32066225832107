import { Stars } from "@mui/icons-material";
import { Grid } from "@mui/material";

import AvatarUpload from "../../../components/ImageUpload/AvatarUpload";
import { ImageUploadComponentProps } from "../../../components/ImageUpload/withImageUpload";
import TextField from "../../../components/TextField";

import { Styled } from "./EditFormHeader.styles";

type EditFormHeaderProps = Readonly<{
  name: string;
  role?: string;
  photoType: string;
  imgFileFieldName?: ImageUploadComponentProps["imgFileFieldName"];
  imgSourceFieldName?: ImageUploadComponentProps["imgSourceFieldName"];
}>;

const EditFormHeader: React.FC<EditFormHeaderProps> = ({
  name,
  role,
  photoType,
  imgSourceFieldName,
  imgFileFieldName,
}) => (
  <Grid container spacing={3}>
    <Grid
      item
      container
      xs={12}
      spacing={2}
      wrap="nowrap"
      alignItems="center"
      justifyContent="center"
      marginBottom={"20px"}
    >
      <Grid item>
        {imgFileFieldName && imgSourceFieldName && (
          <AvatarUpload
            imgSourceFieldName={imgSourceFieldName}
            imgFileFieldName={imgFileFieldName}
            imgAltText={name}
            circularCrop
            noProcess={true}
          />
        )}
      </Grid>
    </Grid>
  </Grid>
);

export default EditFormHeader;
