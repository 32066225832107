import {
  Query,
  GetSportEventUsersRequest,
  GetSportEventUsersResponse,
} from "@apps/sdk";
import { useCallback } from "react";
import { useQueryState } from "./common";
import { frontofficeService } from "../services";

export const useGetSportEventUsers = () => {
  const { data, error, loading, setState, setLoading } =
    useQueryState<GetSportEventUsersResponse>();

  const getSportEventUsers = useCallback(
    async (payload: GetSportEventUsersRequest) => {
      setLoading(true);

      setState(
        await frontofficeService.query({
          query: Query.GetSportEventUsers,
          payload,
        })
      );

      setLoading(false);
    },
    []
  );

  return {
    getSportEventUsers,
    data,
    error,
    loading,
  };
};
