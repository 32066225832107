import MuiAvatar from "@mui/material/Avatar";
import Typography, { TypographyProps } from "@mui/material/Typography";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const Avatar = styled(MuiAvatar)`
  width: 120px;
  height: 120px;
  background-color: #bdbdbd;
  margin-bottom: 20px;
`;

const RemovePhoto = styled.button`
  background: transparent;
  border: 0;
  cursor: pointer;
  margin-top: 5px;
  color: #666;
  font-size: 14px;
`;

const TextContainer = styled.div`
  margin-top: 10px;
  font-size: 12px;
  line-height: 14px;
`;

const FileInput = styled.input`
  display: none;
`;

const Error: React.FC<TypographyProps> = (props) => (
  <Typography variant="caption" color="error" {...props} />
);

export const Styled = {
  Container,
  Avatar,
  RemovePhoto,
  TextContainer,
  FileInput,
  Error,
};
