import { MenuItem } from '@mui/material';

import { Styled } from './TextField.styles';
import { TextFieldProps, Option } from './TextField.types';

const DefaultTextField: React.FC<TextFieldProps> = ({ hasCheckbox, options, placeholder, ...props }) => {
    const optionsToRender = options ? (placeholder ? [{ value: '', name: placeholder }, ...options] : options) : [];
    const isCheckboxVisible = !!(hasCheckbox && props.select && options);

    const optionsValueNameMap =
        optionsToRender.reduce(
            (result, { name, value }) => ({
                ...result,
                [value]: name as string | number,
            }),
            {} as { [key: string]: string | number }
        ) || {};

    const selectProps: Pick<TextFieldProps, 'SelectProps'> = {
        SelectProps: {
            ...props.SelectProps,
            renderValue: (value) =>
                props.SelectProps?.multiple
                    ? (value as Array<string | number>).map((val) => optionsValueNameMap[val]).join(', ')
                    : optionsValueNameMap[value as string | number],
        },
    };

    const textFieldProps = { placeholder, ...props, ...selectProps };

    return (
        <Styled.TextField {...textFieldProps}>
            {optionsToRender.map(({ name, value }: Option, idx: number) => (
                <MenuItem key={idx} value={value || ''}>
                    {isCheckboxVisible && (
                        <Styled.Checkbox
                            disableRipple
                            color="primary"
                            checked={Array.isArray(props.value) ? props.value.includes(value) : props.value === value}
                        />
                    )}
                    {name}
                </MenuItem>
            ))}
        </Styled.TextField>
    );
};

export default DefaultTextField;
