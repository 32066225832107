import { AcceptType } from './ImageUpload.types';

export const MAX_FILE_SIZE_MB = 2;
export const DEFAULT_CROP_ASPECT_RATIO = 16 / 9;

export enum ImageFileTypes {
    PNG = '.png',
    JPG = '.jpg',
    JPEG = '.jpeg',
    SVG = '.svg',
}

export enum ImageMimeTypes {
    PNG = 'image/png',
    JPG = 'image/jpeg',
    JPEG = 'image/jpeg',
    SVG = 'image/svg+xml',
}

export const ACCEPT_TYPES = {
    PNG: { type: ImageFileTypes.PNG, mime: ImageMimeTypes.PNG },
    JPEG: { type: ImageFileTypes.JPEG, mime: ImageMimeTypes.JPEG },
    JPG: { type: ImageFileTypes.JPG, mime: ImageMimeTypes.JPG },
    SVG: { type: ImageFileTypes.SVG, mime: ImageMimeTypes.SVG },
};

export const DEFAULT_ACCEPT_TYPES: AcceptType[] = [
    ACCEPT_TYPES.PNG,
    ACCEPT_TYPES.JPEG,
    ACCEPT_TYPES.JPG,
    ACCEPT_TYPES.SVG,
];
