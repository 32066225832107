import { Divider as MuiDivider, Avatar as MuiAvatar } from "@mui/material";
import styled, { css, CSSProp } from "styled-components";

const Container = styled.div`
  padding: 10px;
`;

const Avatar = styled(MuiAvatar)`
  width: 120px;
  height: 120px;
`;

const Divider = styled(MuiDivider)`
  width: 100%;
`;

export const Styled = {
  Container,

  Avatar,

  Divider,
};
