import { monthNamesLower } from "./monthNames";

export function getMonthFromDate(dateString: string): string {
  const months = monthNamesLower;

  const parts = dateString.split(".");
  if (parts.length !== 3) {
    throw new Error("Invalid date format. Please use 'DD.MM.YYYY'.");
  }

  const monthIndex = parseInt(parts[1]) - 1;
  if (monthIndex < 0 || monthIndex >= months.length) {
    throw new Error("Invalid month value in the date.");
  }

  const month = months[monthIndex];
  return month;
}

export function getDayFromDate(dateString: string): string {
  const parts = dateString.split(".");
  if (parts.length !== 3) {
    throw new Error("Invalid date format. Please use 'DD.MM.YYYY'.");
  }

  const day = parts[0];
  return day;
}

//-----

export function getDateDay(timestamp: string): number {
  const date = new Date(parseInt(timestamp, 10));
  return date.getDate();
}

export function getDateMonth(timestamp: string): string {
  const date = new Date(parseInt(timestamp, 10));

  const months = monthNamesLower;

  return months[date.getMonth()];
}
