"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateSportEventResponseSchema = exports.CreateSportEventRequestSchema = void 0;
var zod_1 = require("zod");
var shared_1 = require("../shared");
exports.CreateSportEventRequestSchema = zod_1.z.object({
    countryId: zod_1.z.string(),
    image: shared_1.UploadSchema.optional(),
}).merge(shared_1.SportEventSchema.omit({
    id: true,
    createdBy: true,
    approvedVolunteersCount: true,
    country: true,
    imageUrl: true,
    isAlreadyApplied: true
}));
exports.CreateSportEventResponseSchema = shared_1.SportEventSchema;
