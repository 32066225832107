"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EducationResourceSchema = exports.EducationResourceStatus = exports.EducationResourceType = void 0;
var zod_1 = require("zod");
var EducationResourceType;
(function (EducationResourceType) {
    EducationResourceType["Video"] = "video";
    EducationResourceType["Document"] = "document";
})(EducationResourceType || (exports.EducationResourceType = EducationResourceType = {}));
var EducationResourceStatus;
(function (EducationResourceStatus) {
    EducationResourceStatus["Hidden"] = "hidden";
    EducationResourceStatus["Published"] = "published";
})(EducationResourceStatus || (exports.EducationResourceStatus = EducationResourceStatus = {}));
exports.EducationResourceSchema = zod_1.z.object({
    id: zod_1.z.string(),
    name: zod_1.z.string(),
    slug: zod_1.z.string(),
    type: zod_1.z.nativeEnum(EducationResourceType),
    imageUrl: zod_1.z.string().nullish(),
    url: zod_1.z.string().nullish(),
    status: zod_1.z.nativeEnum(EducationResourceStatus),
    description: zod_1.z.string().nullish(),
});
