import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";
import { Autoplay, Navigation } from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import EventBoxSlide from "../EventBoxes/EventBoxSlide/EventBoxSlide";

import { CircularProgress } from "@mui/material";
import { SportEvent } from "@apps/sdk";

import styles from "./EventSlider.module.scss";

interface EventSliderProps {
  readonly sportEvents: SportEvent[];
}

const EventSlider = ({ sportEvents }: EventSliderProps) => {
  SwiperCore.use([Autoplay]);

  return (
    <div className={styles.eventSliderContainer}>
      <div className={styles.wrapper}>
        {sportEvents.length ? (
          <div className={styles.sliderWrapper}>
            <Swiper
              slidesPerView={3.2}
              spaceBetween={20}
              pagination={{
                clickable: true,
              }}
              loop={true}
              breakpoints={{
                320: {
                  slidesPerView: 1.2,
                  spaceBetween: 10,
                },
                640: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 3.2,
                  spaceBetween: 20,
                },
                2500: {
                  slidesPerView: 4.7,
                  spaceBetween: 20,
                },
              }}
              modules={[Navigation]}
              navigation={{
                nextEl: ".custom_next",
                prevEl: ".custom_prev",
              }}
            >
              {sportEvents.map((eventInfo, idx) => (
                <SwiperSlide key={idx}>
                  <EventBoxSlide {...eventInfo} className={styles.box} />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        ) : (
          <CircularProgress color="secondary" />
        )}
      </div>
    </div>
  );
};

export default EventSlider;
