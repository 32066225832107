"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetCertificatesResponseSchema = exports.GetCertificatesRequestSchema = void 0;
var zod_1 = require("zod");
var shared_1 = require("../shared");
exports.GetCertificatesRequestSchema = zod_1.z.object({
    pagination: shared_1.PaginationSchema,
    filter: zod_1.z.object({
        userId: zod_1.z.string().optional(),
        eventId: zod_1.z.string().optional()
    }).optional()
});
exports.GetCertificatesResponseSchema = zod_1.z.object({
    certificates: zod_1.z.array(shared_1.CertificateSchema),
    maxCount: zod_1.z.number(),
});
