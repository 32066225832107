import { EducationResourceType } from "@apps/sdk";
import { extractYouTubeVideoId, getFileSrc } from "../../utils";
import styles from "./EducationalResourceBox.module.scss";
import FileIcon from "../../assets/icons/FileIcon";
import VideoIcon from "../../assets/icons/VideoIcon";
import { useState } from "react";
import { Box, Grid, Modal } from "@mui/material";
import YouTube from "react-youtube";

const EducationalResourceBox: React.FC<any> = ({ educationalResource }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const opts = {
    width: "100%",
    playerVars: {
      autoplay: 1,
    },
  };

  const modalStyle = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    height: "50%", //60
    bgcolor: "#000",
    border: "1px solid #000",
    boxShadow: 24,
    marginRight: "2px",
    marginLeft: "2px",

    "@media (max-width: 600px)": {
      width: "95%",
      height: "52%",
    },
  };

  const videoWrapperStyle: React.CSSProperties = {
    // position: "relative",
    // paddingBottom: "56.25%", // 16:9 aspect ratio for responsive sizing
    // paddingTop: "30px", // Optional: Add padding to center the video vertically
    // height: "0",
    // overflow: "hidden",
    marginTop: "35px",
    marginLeft: "2px",
    marginRight: "2px",
    // "@media (max-width: 600px)": {
    //   marginTop: "5%",
    // },
  };

  return (
    <div className={styles.container}>
      <Grid container>
        <Grid item xs={11}>
          <div className={styles.title}>{educationalResource.name}</div>
        </Grid>
        <Grid
          item
          container
          xs={1}
          direction="column"
          alignItems="flex-end"
          justifyItems="flex-start"
        >
          {educationalResource.type === EducationResourceType.Document ? (
            <FileIcon />
          ) : (
            <VideoIcon />
          )}
        </Grid>
      </Grid>

      <div className={styles.description}>
        {educationalResource.description}
      </div>

      <div className={styles.buttonContainer}>
        {educationalResource.type === EducationResourceType.Document ? (
          <a href={getFileSrc(educationalResource.url)} download>
            <button className={styles.downloadBtn}>Download</button>
          </a>
        ) : (
          <>
            <button className={styles.downloadBtn} onClick={handleOpen}>
              Watch
            </button>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={modalStyle}>
                <div style={videoWrapperStyle}>
                  <YouTube
                    videoId={extractYouTubeVideoId(educationalResource.url)}
                    opts={opts}
                  />
                </div>
              </Box>
            </Modal>
          </>
        )}
      </div>
    </div>
  );
};

export default EducationalResourceBox;
