import { Avatar } from "@mui/material";
import styles from "./EventBox.module.scss";
import { Link } from "react-router-dom";
import { getFileSrc } from "../../../utils";
import { Certificate, SportEvent, SportEventUserStatus } from "@apps/sdk";
import LocationIcon from "../../../assets/icons/LocationIcon";
import CalendarIcon from "../../../assets/icons/CalendarIcon";
import { formatTimestamp, getEventContinuity } from "../../../utils/dates";
import ApprovedIcon from "../../../assets/icons/ApprovedIcon";
import RejectedIcon from "../../../assets/icons/RejectedIcon";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import { useReactiveState } from "../../../hooks";
import { userState } from "../../../state";
import CertificateComponent from "../../Certificate/Certificate";
import { usePDF, Margin } from "react-to-pdf";

type EventBoxSmallProps = {
  event: SportEvent;
  userIdParam: string;
  certificate?: Certificate;
};

const EventBoxSmall = ({
  event,
  userIdParam,
  certificate,
}: EventBoxSmallProps) => {
  const { current: currentUser } = useReactiveState(userState);

  let statusIcon;
  let statusText = "";
  let statusTextCss = "";

  switch (event.applicationStatus) {
    case SportEventUserStatus.Approved:
      statusIcon = <ApprovedIcon />;
      statusText = "Approved";
      statusTextCss = "statusApproved";
      break;
    case SportEventUserStatus.Pending:
      statusIcon = (
        <PendingActionsIcon
          sx={{ color: "#ccc", width: "30px", height: "30px" }}
        />
      );
      statusText = "Pending";
      statusTextCss = "statusPending";
      break;
    case SportEventUserStatus.Rejected:
      statusIcon = <RejectedIcon />;
      statusText = "Rejected";
      statusTextCss = "statusRejected";
      break;
  }

  const { toPDF, targetRef } = usePDF({
    page: {
      orientation: "landscape",
      margin: Margin.SMALL,
    },
    filename: certificate
      ? `${certificate.eventName
          .toLowerCase()
          .replace(/[^\w\s]/g, "")
          .replace(/\s+/g, "-")
          .trim()}-certificate`
      : "",
  });

  return (
    <>
      {certificate && (
        <CertificateComponent
          refTarget={targetRef}
          name={certificate.userFullName}
          eventName={certificate.eventName}
          manager={certificate.sportOrganizationName}
          organizationLogo={certificate.sportOrganizationLogo}
        />
      )}

      <div className={styles.container}>
        <div className={styles.leftSection}>
          <div>
            <Avatar
              className={styles.avatar}
              src={getFileSrc(event.imageUrl || "")}
            />
          </div>
          <div className={styles.leftSectionDataWrapper}>
            <div className={styles.name}>{event.name}</div>

            <div className={styles.locationDatesContainer}>
              <div className={styles.locationContainer}>
                <LocationIcon iconClassName={styles.locationIcon} />
                <div className={styles.headerDivider}></div>
                {event.country.name}, {event.city}
              </div>
              <div className={styles.datesContainer}>
                <CalendarIcon />
                <div className={styles.headerDivider}></div>

                {getEventContinuity(
                  formatTimestamp(Number(event.dateFrom)),
                  formatTimestamp(Number(event.dateTo))
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.rightSection}>
          {userIdParam === currentUser.id ? (
            <div className={styles.status}>
              {statusIcon}
              <span className={styles[statusTextCss]}>{statusText}</span>
            </div>
          ) : null}

          <div className={styles.buttonContainer}>
            <Link
              to={`/event/${event?.id}`}
              className={styles.seeDetailsButton}
            >
              View Event
            </Link>
            {certificate && (
              <button
                className={`${styles.seeDetailsButton} ${styles.certificateBtn}`}
                onClick={() => toPDF()}
              >
                Certificate
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default EventBoxSmall;
