"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CertificateSchema = void 0;
var zod_1 = require("zod");
exports.CertificateSchema = zod_1.z.object({
    userFullName: zod_1.z.string(),
    eventName: zod_1.z.string(),
    sportOrganizationName: zod_1.z.string(),
    sportOrganizationLogo: zod_1.z.string().nullish(),
    workingHoursCount: zod_1.z.number()
});
