import { useEffect } from "react";
import ContentBackgroundContainer from "../../components/ContentBackgroundContainer";
import ContentContainer from "../../components/ContentContainer";
import { useGetSportEventsQuery } from "../../query-hooks";
import BigCalendar from "./BigCalendar";
import styles from "./EventsCalendar.module.scss";
import { useReactiveState } from "../../hooks";
import { userState } from "../../state";
import { UserType } from "@apps/sdk";

const EventsCalendar = () => {
  const { current: user } = useReactiveState(userState);

  const { getSportEvents, data: events } = useGetSportEventsQuery();
  const eventsCount = 100;

  useEffect(() => {
    if (user?.type === UserType.SportOrganization) {
      getSportEvents({
        pagination: {
          page: 0,
          rowsPerPage: eventsCount,
        },
        filter: {
          createdByUserId: user.id,
        },
      });
    } else {
      getSportEvents({
        pagination: {
          page: 0,
          rowsPerPage: eventsCount,
        },
        filter: {
          volunteerUserId: user?.id,
        },
      });
    }
  }, []);

  return (
    <ContentBackgroundContainer>
      <div className={styles.container}>
        <ContentContainer>
          {events?.sportEvents ? (
            <BigCalendar eventsData={events?.sportEvents} />
          ) : null}
        </ContentContainer>
      </div>
    </ContentBackgroundContainer>
  );
};

export default EventsCalendar;
