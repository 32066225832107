import { deepFreeze } from "../utils";
var State = /** @class */ (function () {
    function State(initialState, options) {
        if (options === void 0) { options = {}; }
        this.ListenerFunctions = new Set();
        this.options = {};
        this.options = options;
        var noFreeze = this.options.noFreeze;
        this.initialState = noFreeze ? { payload: initialState } : deepFreeze({ payload: initialState });
        this.Value = this.initialState;
        this.PrevValue = null;
    }
    State.prototype.addChangeListener = function (listener) {
        this.ListenerFunctions.add(listener);
    };
    State.prototype.removeChangeListener = function (listener) {
        this.ListenerFunctions.delete(listener);
    };
    State.prototype.set = function (updateState) {
        var _this = this;
        var noFreeze = this.options.noFreeze;
        this.PrevValue = this.Value;
        if (typeof updateState === 'function') {
            var newPayloadValue = { payload: updateState(this.PrevValue.payload) };
            this.Value = noFreeze ? newPayloadValue : deepFreeze(newPayloadValue);
        }
        else {
            var newPayloadValue = { payload: updateState };
            this.Value = noFreeze ? newPayloadValue : deepFreeze(newPayloadValue);
        }
        this.ListenerFunctions.forEach(function (listener) { var _a; return listener((_a = _this.PrevValue) === null || _a === void 0 ? void 0 : _a.payload, _this.Value.payload); });
    };
    State.prototype.get = function () {
        return this.Value.payload;
    };
    State.prototype.getPrevious = function () {
        return !this.PrevValue ? null : this.PrevValue.payload;
    };
    State.prototype.reset = function () {
        var _this = this;
        this.Value = this.initialState;
        this.PrevValue = null;
        this.ListenerFunctions.forEach(function (listener) { return listener(_this.PrevValue, _this.Value.payload); });
    };
    return State;
}());
export { State };
