import React from "react";

interface IconProps {
  iconClassName?: string;
}

const ApprovedIcon: React.FC<IconProps> = ({ iconClassName }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      className={iconClassName}
    >
      <path
        d="M24.166 12.584C24.166 14.9738 23.4574 17.3099 22.1297 19.2969C20.802 21.284 18.9149 22.8327 16.707 23.7472C14.4991 24.6617 12.0696 24.901 9.72573 24.4348C7.38186 23.9686 5.22887 22.8178 3.53903 21.1279C1.8492 19.4381 0.698402 17.2851 0.232177 14.9413C-0.234048 12.5974 0.00523543 10.1679 0.919769 7.96001C1.8343 5.75213 3.38301 3.86503 5.37005 2.53733C7.35709 1.20963 9.69321 0.500977 12.083 0.500977C15.2876 0.500977 18.361 1.774 20.627 4.04001C22.893 6.30601 24.166 9.37937 24.166 12.584ZM10.685 18.984L19.65 10.019C19.7953 9.87283 19.8769 9.67509 19.8769 9.46898C19.8769 9.26286 19.7953 9.06513 19.65 8.91898L18.55 7.81898C18.4039 7.67364 18.2061 7.59206 18 7.59206C17.7939 7.59206 17.5962 7.67364 17.45 7.81898L10.137 15.123L6.72001 11.71C6.57385 11.5646 6.37612 11.4831 6.17001 11.4831C5.96389 11.4831 5.76616 11.5646 5.62001 11.71L4.52001 12.81C4.37467 12.9561 4.29309 13.1539 4.29309 13.36C4.29309 13.5661 4.37467 13.7638 4.52001 13.91L9.587 18.977C9.73241 19.1224 9.92935 19.2045 10.135 19.2054C10.3406 19.2063 10.5383 19.126 10.685 18.982V18.984Z"
        fill="#6ABE6D"
      />
    </svg>
  );
};

export default ApprovedIcon;
