import React from "react";

interface IconProps {
  iconClassName?: string;
}

const RejectedIcon: React.FC<IconProps> = ({ iconClassName }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24.708"
      height="24.708"
      viewBox="0 0 24.708 24.708"
      className={iconClassName}
    >
      <path
        d="M15.729,3.375A12.354,12.354,0,1,0,28.083,15.729,12.311,12.311,0,0,0,15.729,3.375Zm6.177,16.8-1.73,1.729-4.448-4.448-4.448,4.448-1.73-1.729L14,15.729,9.552,11.282l1.73-1.729L15.729,14l4.448-4.447,1.73,1.729-4.448,4.448Z"
        transform="translate(-3.375 -3.375)"
        fill="#c53333"
      />
    </svg>
  );
};

export default RejectedIcon;
