import { Button, Grid } from "@mui/material";
import React, { useState, useCallback, useRef, useEffect } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

import { Styled } from "./ImageCropper.styles";

// Setting a high pixel ratio avoids blurriness in the canvas crop preview.
const pixelRatio = 4;

interface ImageCropperProps {
  readonly file: File | undefined;
  readonly onImageReady: (imageFile: File) => void;
  readonly onClose: () => void;
  readonly aspectRatio: number;
  readonly circularCrop?: boolean;
}

const ImageCropper: React.FC<ImageCropperProps> = ({
  file,
  onImageReady,
  onClose,
  aspectRatio,
  circularCrop = false,
}) => {
  const [upImg, setUpImg] = useState();
  const imgRef = useRef<any>();
  const previewCanvasRef = useRef<any>();
  const [crop, setCrop] = useState<any>({
    unit: "%",
    width: 30,
    aspect: aspectRatio,
  });
  const [completedCrop, setCompletedCrop] = useState<any>();

  useEffect(() => {
    if (file) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setUpImg(reader.result as any));
      reader.readAsDataURL(file);
    }
  }, [file]);

  const handleImageLoaded = useCallback((img: any) => {
    imgRef.current = img;
  }, []);

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current as any;
    const canvas = previewCanvasRef.current as any;
    const cropCanvas = completedCrop as any;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");

    canvas.width = Math.ceil(cropCanvas.width * pixelRatio);
    canvas.height = Math.ceil(cropCanvas.height * pixelRatio);

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingEnabled = false;

    ctx.drawImage(
      image,
      cropCanvas.x * scaleX,
      cropCanvas.y * scaleY,
      cropCanvas.width * scaleX,
      cropCanvas.height * scaleY,
      0,
      0,
      cropCanvas.width,
      cropCanvas.height
    );
  }, [completedCrop]);

  const getResizedCanvas = (cropCanvas: any) => {
    const image = imgRef.current as any;

    const tmpCanvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    tmpCanvas.width = Math.ceil(cropCanvas.width * scaleX);
    tmpCanvas.height = Math.ceil(cropCanvas.height * scaleY);
    const ctx = tmpCanvas.getContext("2d");

    if (!ctx) return;

    ctx.imageSmoothingEnabled = false;

    ctx.drawImage(
      image,
      cropCanvas.x * scaleX,
      cropCanvas.y * scaleY,
      cropCanvas.width * scaleX,
      cropCanvas.height * scaleY,
      0,
      0,
      cropCanvas.width * scaleX,
      cropCanvas.height * scaleY
    );

    return tmpCanvas;
  };

  const generateDownload = (previewCanvas: any, cropCanvas: any) => {
    if (!file || !cropCanvas || !previewCanvas) {
      return;
    }

    const canvas = getResizedCanvas(cropCanvas);

    canvas?.toBlob(
      (blob) => {
        if (blob) {
          const fileResult = new File([blob], file.name, { type: file.type });
          onImageReady(fileResult);
        }
      },
      file.type,
      1
    );
  };

  const handleSave = () =>
    generateDownload(previewCanvasRef.current, completedCrop);

  return (
    <Styled.Container>
      <Grid container spacing={3} justifyContent="center">
        <Grid item>
          <h3>Original Image</h3>

          <ReactCrop
            src={upImg || ""}
            onImageLoaded={handleImageLoaded}
            crop={crop}
            onChange={(c) => setCrop(c)}
            onComplete={(c) => setCompletedCrop(c)}
            style={{ width: "200px", height: "200px" }}
            circularCrop={circularCrop}
          />
        </Grid>

        <Grid item>
          <h3>Cropped Image</h3>

          <canvas
            ref={previewCanvasRef}
            style={{
              background: "lightgray",
              width: "200px",
              height: "200px",
              borderRadius: circularCrop ? "50%" : 0,
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Styled.Divider />
        </Grid>

        <Grid
          item
          container
          spacing={3}
          alignItems="center"
          justifyContent="center"
        >
          <Grid item>
            <Button color="inherit" onClick={onClose}>
              Cancel
            </Button>
          </Grid>

          <Grid item>
            <Button
              type="button"
              variant="outlined"
              color="primary"
              onClick={handleSave}
              disabled={!completedCrop?.width || !completedCrop?.height}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Styled.Container>
  );
};

export default ImageCropper;
