import React from "react";
import ContentContainer from "../../components/ContentContainer";
import styles from "./Event.module.scss";
import ProgressBar from "../../components/ProgressBar";
import { getPercentage } from "../../utils/numbers";
import { Grid } from "@mui/material";
import LocationIcon from "../../assets/icons/LocationIcon";
import CalendarIcon from "../../assets/icons/CalendarIcon";
import { formatTimestamp, getEventContinuity } from "../../utils/dates";
import { useReactiveState, useTranslations } from "../../hooks";
import parseHtml from "html-react-parser";
import BulletIcon from "../../assets/icons/BulletIcon";
import GreenTips from "../../components/GreenTips/GreenTips";
import {
  useApplyForSportEvent,
  useGetSportEventsQuery,
} from "../../query-hooks";
import { getFileSrc } from "../../utils";
import { SportEvent, SportEventCosts, UserType } from "@apps/sdk";
import { authModalState, userState } from "../../state";
import SuccessfullyApplied from "../../components/SuccessfullyApplied/SuccessfullyApplied";
import { useParams } from "react-router-dom";

type EventDataProps = {
  data: SportEvent;
};

const EventData: React.FC<EventDataProps> = (props) => {
  const { translate } = useTranslations();

  const { current: user } = useReactiveState(userState);

  const { applyForSportEvent } = useApplyForSportEvent();

  const eventData = props.data;

  const applyForEvent = () => {
    applyForSportEvent(eventData.id).then(() => {
      eventData.isAlreadyApplied = true;
    });
  };

  const eventImage = getFileSrc(eventData.imageUrl || "");

  // const divStyle = {
  //   backgroundImage: `url(${eventImage})`,
  //   backgroundSize: "contain",
  //   backgroundPosition: "center",
  // };

  const divStyle = {
    backgroundImage: `url(${eventImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    // backgroundPositionY: "top",
  };

  let costsText = "";
  switch (eventData.costs) {
    case SportEventCosts.FullyCovered:
      costsText = "* All costs are fully covered.";
      break;
    case SportEventCosts.NotCovered:
      costsText = "* Costs are not covered.";
      break;
    case SportEventCosts.PartiallyCovered:
      costsText = "* Costs are pratially covered.";
      break;
  }

  const openRegisterModal = () =>
    authModalState.set({
      ...authModalState.get(),
      registerModal: true,
    });

  return (
    <>
      <div style={divStyle} className={styles.photoContainer} />

      <ContentContainer className={styles.contentContainer}>
        <h1 className={styles.title}>{eventData.name}</h1>

        <Grid container spacing={2} className={styles.progresContainer}>
          <Grid item md={8} width="100%">
            <ProgressBar
              value={getPercentage(
                eventData.approvedVolunteersCount as number,
                eventData.volunteersCount
              )}
              participants={eventData.volunteersCount}
              color="inherit"
              className={styles.progressBar}
              sx={{
                backgroundColor: "rgb(241,241,241, 0.1)",
                "& .MuiLinearProgress-bar": {
                  backgroundColor: "#33BBC5",
                },
              }}
            />
          </Grid>

          <Grid item md={4}>
            <div className={styles.volunteersText}>
              {eventData.approvedVolunteersCount} out of{" "}
              {eventData.volunteersCount} volunteers joined
            </div>
          </Grid>
        </Grid>

        <div className={styles.locationDatesContainer}>
          <div className={styles.locationContainer}>
            <LocationIcon />
            <div className={styles.headerDivider}></div>
            Location: {eventData.country.name}, {eventData.city}
          </div>
          <div className={styles.datesContainer}>
            <CalendarIcon />
            <div className={styles.headerDivider}></div>
            Dates:{" "}
            {getEventContinuity(
              formatTimestamp(Number(eventData.dateFrom)),
              formatTimestamp(Number(eventData.dateTo))
            )}
          </div>
        </div>

        <div className={styles.volunteersContainer}>
          <div className={styles.volunteerContainer}>
            <span className={styles.numberOfVolunteers}>
              {eventData.volunteersCount}
            </span>
            <span>{translate((t) => t.REQUIRED_NUMBER_OF_VOLUNTEERS)}</span>
          </div>

          <div className={styles.volunteerContainer}>
            <span className={styles.numberOfWorkingHours}>
              {eventData.workingHoursCount}
            </span>
            <span>{translate((t) => t.REQUIRED_NUMBER_OF_WORKING_HOURS)}</span>
          </div>
        </div>

        <div className={styles.costsContainer}>{costsText}</div>

        <div>
          <div className={styles.sectionName}>Event description</div>
          <div className={styles.description}>
            {parseHtml(eventData.description)}
          </div>
        </div>

        {eventData.keyResponsibilities.list.length > 0 ? (
          <div>
            <div className={styles.sectionName}>Key responsibilities</div>
            <div>
              <ul className={styles.customBulletList}>
                {eventData.keyResponsibilities.list.map(
                  (responsibility: string) => (
                    <li>
                      <BulletIcon iconClassName={styles.bulletIcon} />
                      {responsibility}
                    </li>
                  )
                )}
              </ul>
            </div>
          </div>
        ) : null}

        {eventData.otherRequirements.list.length > 0 ? (
          <div>
            <div className={styles.sectionName}>Other requirements</div>
            <div>
              <ul className={styles.customBulletList}>
                {eventData.otherRequirements.list.map(
                  (responsibility: string) => (
                    <li>
                      <BulletIcon iconClassName={styles.bulletIcon} />
                      {responsibility}
                    </li>
                  )
                )}
              </ul>
            </div>
          </div>
        ) : null}

        <div className={styles.greenTips}>
          <GreenTips />
        </div>

        {user?.type ===
        UserType.SportOrganization ? null : eventData.isAlreadyApplied ? null : (
          <div className={styles.sectionNameCenter}>
            Are you ready to participate?
          </div>
        )}

        <div className={styles.buttonContainer}>
          {user?.type === UserType.SportOrganization ? null : user?.type ===
            UserType.Volunteer ? (
            eventData.isAlreadyApplied ? (
              <SuccessfullyApplied />
            ) : (
              <button className={styles.submitBtn} onClick={applyForEvent}>
                Apply for free
              </button>
            )
          ) : (
            <button className={styles.submitBtn} onClick={openRegisterModal}>
              Apply for free
            </button>
          )}
        </div>
      </ContentContainer>
    </>
  );
};

export default EventData;
