import { Link } from "react-router-dom";
import facebookIcon from "../../assets/facebook-icon.svg";
import WebSiteIcon from "../../assets/icons/WebSiteIcon";
import linkedinIcon from "../../assets/linkedin-icon.svg";
import twitterIcon from "../../assets/twitter-icon.svg";
import youtubeIcon from "../../assets/youtube-icon.svg";

import styles from "./SocialIcons.module.scss";
import { GetUsersResponse } from "@apps/sdk";

type SocialIconsProps = {
  socialType: "withWebsite" | "noWebsite";
  distance: "small" | "big";
  socialLinks: GetUsersResponse["users"][0]["socials"]["links"];
};

const SocialIcons = ({
  socialLinks,
  socialType,
  distance,
}: SocialIconsProps) => {
  let iconStyle;
  if (distance === "small") {
    iconStyle = "socialIconFooter";
  } else {
    iconStyle = "socialIconProfile";
  }

  const socialMediaObject: { [key: string]: string } = {};

  socialLinks.forEach((item) => {
    const { name, url } = item;
    socialMediaObject[name.toLowerCase()] = url;
  });

  return (
    <div className={styles.socialIcons}>
      {socialType === "withWebsite" ? (
        <Link
          to={socialMediaObject.website}
          target="_blank"
          rel="noreferrer"
          className={styles[iconStyle]}
        >
          <WebSiteIcon iconClassName={styles[iconStyle]} />
        </Link>
      ) : null}
      <Link
        to={socialMediaObject.facebook}
        target="_blank"
        rel="noreferrer"
        className={styles[iconStyle]}
      >
        <img src={facebookIcon} alt="facebook-icon" />
      </Link>
      <Link
        to={socialMediaObject.xaccount}
        target="_blank"
        rel="noreferrer"
        className={styles[iconStyle]}
      >
        <img src={twitterIcon} alt="twitter-icon" />
      </Link>
      <Link
        to={socialMediaObject.linkedin}
        target="_blank"
        rel="noreferrer"
        className={styles[iconStyle]}
      >
        <img src={linkedinIcon} alt="linkedin-icon" />
      </Link>
      <Link
        to={socialMediaObject.youtube}
        target="_blank"
        rel="noreferrer"
        className={styles[iconStyle]}
      >
        <img src={youtubeIcon} alt="youtube-icon" />
      </Link>
    </div>
  );
};

export default SocialIcons;
