import { useEffect, useState } from "react";
import BlogPost from "../../../components/BlogPost/BlogPost";
import BlogPostLatest from "../../../components/BlogPost/BlogPostLatest";
import { useGetBlogsQuery } from "../../../query-hooks";
import styles from "./LatestBlogPosts.module.scss";
import { getFileSrc } from "../../../utils";
import { Blog, BlogStatus } from "@apps/sdk";

const LatestBlogPosts = () => {
  const { getBlogs, data: blogs } = useGetBlogsQuery();

  const [blogPostsSorted, setBlogPostsSorted] = useState<Blog[]>();
  const [blogPostTop, setBlogPostTop] = useState<Blog>();
  const [blogPostBottom, setBlogPostBottom] = useState<Blog[]>();

  useEffect(() => {
    getBlogs({
      pagination: {
        page: 0,
        rowsPerPage: 3,
      },
      filter: {
        status: BlogStatus.Published,
      },
    });
  }, []);

  useEffect(() => {
    if (!blogs) {
      return;
    }

    const blogPostsSortedTemp = blogs.blogs.sort(
      (a, b) => Number(b.createdAt) - Number(a.createdAt)
    );
    setBlogPostsSorted(blogPostsSortedTemp);
  }, [blogs]);

  useEffect(() => {
    if (!blogPostsSorted) {
      return;
    }

    setBlogPostTop(blogPostsSorted[0]);
    setBlogPostBottom([blogPostsSorted[1], blogPostsSorted[2]]);
  }, [blogPostsSorted]);

  return (
    <div className={styles.container}>
      <div className={styles.title}>LATEST BLOG POSTS</div>

      {blogPostTop ? (
        <BlogPostLatest
          title={blogPostTop?.title}
          description={blogPostTop.text}
          date={blogPostTop.createdAt}
          thumbnailIMG={getFileSrc(blogPostTop.imageUrl || "")}
          slug={`/blog/${blogPostTop.slug}`}
        />
      ) : null}

      <div className={styles.blogContainerInner}>
        {blogPostBottom
          ? blogPostBottom.map((post) => (
              <BlogPost
                key={post?.id}
                title={post?.title || ""}
                date={post?.createdAt || ""}
                thumbnailIMG={getFileSrc(post?.imageUrl || "")}
                slug={`/blog/${post?.slug}`}
              />
            ))
          : null}
      </div>
    </div>
  );
};

export default LatestBlogPosts;
