"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EditUserResponseSchema = exports.EditUserRequestSchema = void 0;
var zod_1 = require("zod");
var shared_1 = require("../shared");
exports.EditUserRequestSchema = zod_1.z.object({
    id: zod_1.z.string().optional(),
    countryId: zod_1.z.string().optional(),
    phoneNumber: zod_1.z.string().optional(),
    description: zod_1.z.string().optional(),
    socials: shared_1.SocialsJsonSchema.optional(),
    photo: shared_1.UploadSchema.optional(),
    password: zod_1.z.object({
        current: zod_1.z.string(),
        new: zod_1.z.string(),
    }).optional()
}).merge(shared_1.UserSchema.omit({
    id: true,
    photoUrl: true,
    country: true,
    phoneNumber: true,
    socials: true,
    description: true
}));
exports.EditUserResponseSchema = shared_1.UserSchema;
