import { Response } from "@packages/core-shared";
import { useState } from "react";

export const useQueryState = <T>() => {
  const [state, setState] = useState<Response<T>>({});

  const [loading, setLoading] = useState(false);

  const { data, error } = state;

  return {
    data,
    error,
    loading,
    setState,
    setLoading,
  };
};
