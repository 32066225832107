import { Upload } from "../types";
export var hasFilesInPayload = function (value) {
    if (value instanceof Upload) {
        return true;
    }
    if (value && typeof value === 'object') {
        for (var property in value) {
            if (value[property] instanceof Upload) {
                return true;
            }
            else if (Array.isArray(value[property])) {
                var hasFile = value[property].some(function (x) { return hasFilesInPayload(x); });
                if (hasFile) {
                    return true;
                }
            }
            else {
                hasFilesInPayload(value[property]);
            }
        }
    }
    return false;
};
