import { Header, Tenant, createService } from "@apps/sdk";

export const frontofficeService = createService(
  process.env.REACT_APP_BE_SERVICE_URL as string
);

frontofficeService.appendHeader(Header.Tenant, Tenant.FrontOffice);

export const resolveAssetsPath = (assetPath: string) =>
  `${process.env.REACT_APP_DO_SPACES_URL}/${assetPath}`;
