"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpdateSportEventUserStatusResponseSchema = exports.UpdateSportEventUserStatusRequestSchema = void 0;
var zod_1 = require("zod");
var shared_1 = require("../shared");
exports.UpdateSportEventUserStatusRequestSchema = zod_1.z.object({
    status: zod_1.z.nativeEnum(shared_1.SportEventUserStatus),
    id: zod_1.z.string()
});
exports.UpdateSportEventUserStatusResponseSchema = zod_1.z.object({
    status: zod_1.z.nativeEnum(shared_1.SportEventUserStatus),
});
