import { CloudUpload } from "@mui/icons-material";
import { Button } from "@mui/material";
import React from "react";

import { MAX_FILE_SIZE_MB } from "../ImageUpload.constants";
import { ImageUploadComponentProps, withImageUpload } from "../withImageUpload";
import { Styled } from "./AvatarUpload.styles";

const AvatarUploadWrapped: React.FC<ImageUploadComponentProps> = ({
  imgFileFieldName,
  imgAltText,
  imgSrc,
  showError,
  errorText,
  outsideErrorText,
  onFileChange,
  acceptFileTypes,
}) => {
  const acceptFileTypesMessage = acceptFileTypes
    ?.join(", ")
    .replaceAll(".", "")
    .toUpperCase();

  return (
    <Styled.Container>
      <Styled.Avatar alt={imgAltText} src={imgSrc} />

      {showError && (
        <Styled.Error>{errorText || outsideErrorText}</Styled.Error>
      )}

      <label>
        <Styled.FileInput
          onChange={onFileChange}
          accept={acceptFileTypes?.join(", ")}
          name={imgFileFieldName}
          type="file"
        />

        <Button
          color="primary"
          variant="outlined"
          component="span"
          startIcon={<CloudUpload />}
        >
          Upload
        </Button>

        <Styled.TextContainer>
          {`Max size: ${MAX_FILE_SIZE_MB}MB`}
          <br />
          {acceptFileTypesMessage && `${acceptFileTypesMessage}`}
        </Styled.TextContainer>
      </label>
    </Styled.Container>
  );
};

const AvatarUpload = withImageUpload(AvatarUploadWrapped);

export default AvatarUpload;
