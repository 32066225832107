"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetSportEventsResponseSchema = exports.GetSportEventsRequestSchema = void 0;
var zod_1 = require("zod");
var shared_1 = require("../shared");
exports.GetSportEventsRequestSchema = zod_1.z.object({
    pagination: shared_1.PaginationSchema,
    filter: zod_1.z.object({
        id: zod_1.z.string().optional(),
        volunteerUserId: zod_1.z.string().optional(),
        volunteerUserStatus: zod_1.z.nativeEnum(shared_1.SportEventUserStatus).optional(),
        countryId: zod_1.z.string().optional(),
        name: zod_1.z.string().optional(),
        createdByUserId: zod_1.z.string().optional(),
        status: zod_1.z.nativeEnum(shared_1.SportEventUserStatus).optional(),
    }).optional()
});
exports.GetSportEventsResponseSchema = zod_1.z.object({
    sportEvents: zod_1.z.array(shared_1.SportEventSchema),
    maxCount: zod_1.z.number(),
});
