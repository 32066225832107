import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";

const ProgressBar = (
  props: LinearProgressProps & {
    value: number;
    participants?: number;
  }
) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={9}>
          <Box sx={{ width: "100%", mr: 1 }}>
            <LinearProgress variant="determinate" {...props} />
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box sx={{ minWidth: 35 }}>
            <Typography
              variant="body2"
              fontSize={10}
              color="white"
            >{`${Math.round(props.value)}% / ${
              props.participants
            }`}</Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProgressBar;
