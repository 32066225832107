export const getBase64FromUrl = async (url: string): Promise<string> => {
    const data = await fetch(url);

    const blob = await data.blob();

    return new Promise((resolve) => {
        const reader = new FileReader();

        reader.readAsDataURL(blob);

        reader.onloadend = () => {
            const base64data = reader.result;

            resolve(base64data as string);
        }
    });
}
