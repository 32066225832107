"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TOKEN_EXPIRE_MINUTES = exports.Tenant = exports.Header = void 0;
var Header;
(function (Header) {
    Header["Authorization"] = "authorization";
    Header["Tenant"] = "x-tenant";
})(Header || (exports.Header = Header = {}));
var Tenant;
(function (Tenant) {
    Tenant["BackOffice"] = "1";
    Tenant["FrontOffice"] = "2";
})(Tenant || (exports.Tenant = Tenant = {}));
exports.TOKEN_EXPIRE_MINUTES = 60;
