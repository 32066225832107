import React from "react";

interface IconProps {
  iconClassName?: string;
}

const FilterIcon: React.FC<IconProps> = ({ iconClassName }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
      className={iconClassName}
    >
      <path
        d="M18.5 1.5H1L8 9.778V15.5L11.5 17.25V9.778L18.5 1.5Z"
        stroke="#52559E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FilterIcon;
