import { getEventContinuity } from "../../../utils/dates/getEventContinuity";

import clsx from "clsx";

import { getPercentage } from "../../../utils/numbers";

import styles from "./EventBoxActions.module.scss";
import ProgressBar from "../../ProgressBar";

import { Link } from "react-router-dom";

import { getFileSrc } from "../../../utils";
import { formatTimestamp } from "../../../utils/dates";
import { SportEvent, SportEventStatus } from "@apps/sdk";

import pencilIcon from "./Pencil.svg";
import volunteersIcon from "./Volunteers.svg";
import DraftIcon from "../../../assets/icons/DraftIcon";
import ApprovedIcon from "../../../assets/icons/ApprovedIcon";
import RejectedIcon from "../../../assets/icons/RejectedIcon";

type EventBoxActionsProps = {
  event: SportEvent;
};

const EventBoxActions = ({ event }: EventBoxActionsProps) => {
  let statusIcon;
  let statusText = "";
  let statusTextCss = "";

  switch (event.status) {
    case SportEventStatus.Approved:
      statusIcon = <ApprovedIcon />;
      statusText = "Approved";
      statusTextCss = "statusApproved";
      break;
    case SportEventStatus.Draft:
      statusIcon = <DraftIcon />;
      statusText = "Draft";
      statusTextCss = "statusDraft";
      break;
    case SportEventStatus.Pending:
      break;
    case SportEventStatus.Rejected:
      statusIcon = <RejectedIcon />;
      statusText = "Rejected";
      statusTextCss = "statusRejected";
      break;
  }

  // const divStyle = {
  //   backgroundImage: `url(${getFileSrc(event.imageUrl || "")})`,
  //   backgroundSize: "contain",
  //   backgroundPosition: "center",
  // };

  const divStyle = {
    backgroundImage: `url(${getFileSrc(event.imageUrl || "")})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    // backgroundPositionY: "top",
  };

  // console.log(
  //   formatTimestamp(Number(event.dateFrom)),
  //   formatTimestamp(Number(event.dateTo))
  // );

  return (
    <div className={clsx(styles.box)}>
      <div className={styles.title}>{event.name}</div>
      <span className={styles.subtitle}>
        <strong>
          {event.country.name}, {event.city}
        </strong>
      </span>
      <span className={styles.dates}>
        {getEventContinuity(
          formatTimestamp(Number(event.dateFrom)),
          formatTimestamp(Number(event.dateTo))
        )}
      </span>

      {/* //TODO: Create it with a div backgroung not an img tag */}

      {/* <img
        className={styles.sliderImage}
        src={getFileSrc(event.imageUrl || "")}
      /> */}

      <div style={divStyle} className={styles.photoContainer}></div>

      <span className={styles.volunteersCount}>
        {event.approvedVolunteersCount} out of {event.volunteersCount}{" "}
        volunteers joined
      </span>

      <ProgressBar
        value={getPercentage(
          event.approvedVolunteersCount as number,
          event.volunteersCount as number
        )}
        participants={event.volunteersCount}
        color="inherit"
        className={styles.progressBar}
        sx={{
          backgroundColor: " #ffffff",
          "& .MuiLinearProgress-bar": {
            backgroundColor: "#33BBC5",
          },
        }}
      />

      <div className={styles.actionsContainer}>
        <Link
          to={`/event/edit/${event.id}`}
          className={`${styles.buttonAction} ${styles.editButton}`}
        >
          <img src={pencilIcon} className={styles.buttonIcon} />
          Edit
        </Link>
        <Link
          to={`/event/${event.id}/applied-volunteers`}
          className={`${styles.buttonAction} ${styles.volunteersButton}`}
        >
          <img src={volunteersIcon} className={styles.buttonIcon} />
          Volunteers
        </Link>
      </div>

      <div className={styles.eventStatus}>
        {statusIcon} <span className={styles[statusTextCss]}>{statusText}</span>
      </div>
    </div>
  );
};

export default EventBoxActions;
