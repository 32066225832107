import { Query, GetPagesResponse, GetPagesRequest } from "@apps/sdk";
import { useCallback } from "react";
import { useQueryState } from "./common";
import { frontofficeService } from "../services";

export const useGetPagesQuery = () => {
  const { data, error, loading, setState, setLoading } =
    useQueryState<GetPagesResponse>();

  const getPages = useCallback(async (payload: GetPagesRequest) => {
    setLoading(true);

    setState(
      await frontofficeService.query({
        query: Query.GetPages,
        payload,
      })
    );

    setLoading(false);
  }, []);

  return {
    getPages,
    data,
    error,
    loading,
  };
};
