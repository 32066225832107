import EventSlider from "../../../components/EventSlider";
import { useTranslations } from "../../../hooks";

import SwiperControls from "../../../components/EventSlider/SwiperControls";

import styles from "./Volunteers.module.scss";
import TextArrow from "../../../components/TextArrow/TextArrow";
import { useGetSportEventsQuery } from "../../../query-hooks";
import { useEffect } from "react";

import { path } from "../../../router";

const Volunteers = () => {
  const { translate } = useTranslations();

  const {
    getSportEvents,
    data: eventsData,
    loading,
  } = useGetSportEventsQuery();

  useEffect(() => {
    getSportEvents({
      pagination: {
        page: 0,
        rowsPerPage: 10,
      },
    });
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <div className={styles.title}>{translate((t) => t.VOLUNTEERS)}</div>
        <div className={styles.navigatinoSubtitleContainer}>
          <div className={styles.subtitle}>
            {translate((t) => t.BROWSE_OUR_LIST_OF_SPORT_EVENTS_AND_APPLY)}
          </div>

          <SwiperControls next={"custom_next"} prev={"custom_prev"} />
        </div>
      </div>

      {eventsData?.sportEvents ? (
        <EventSlider sportEvents={eventsData?.sportEvents} />
      ) : null}

      <TextArrow
        caption={translate((t) => t.CHECK_OUT_OUR_EVENTS)}
        containerWrapper={styles.textArrowContainer}
        size={"medium"}
        linkUrl={path.events}
      />

      <div className={styles.pushDown}></div>
    </div>
  );
};

export default Volunteers;
