export function formatTimestamp(timestamp: number): string {
  const date = new Date(timestamp);

  // Get the components of the date
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(date.getDate()).padStart(2, "0");
  const year = date.getFullYear();

  // Create the formatted date string
  const formattedDate = `${month}.${day}.${year}`;

  return formattedDate;
}
