import App from "./App";

import { createBrowserRouter } from "react-router-dom";

import EventSettings from "./pages/EditEvent/EventSettings";
import Events from "./pages/Events";
import Event from "./pages/Event/Event";
import Home from "./pages/Home";
import MyProfile from "./pages/MyProfile";
import CreatedEvents from "./pages/CreatedEvents/CreatedEvents";
import AppliedVolunteers from "./pages/AppliedVolunteers/AppliedVolunteers";
import UserProfile from "./pages/ProfilePage/UserProfile";
import Blog from "./pages/Blog/Blog";
import EventsCalendar from "./pages/EventsCalendar/EventsCalendar";
import StaticPage from "./pages/StaticPage/StaticPage";
import Contact from "./pages/Contact/Contact";
import EducationalResources from "./pages/EducationalResources/EducationalResources";

// const Home = React.lazy(() => import("./pages/Home/Home"));

// const MyProfile = React.lazy(() => import("./pages/MyProfile/MyProfile"));
// import MyProfile from "./pages/MyProfile";

export const path = {
  home: "/",
  myProfile: "my-profile",
  blog: "blog/:id",
  createEvent: "create-event",
  event: "event/:id",
  events: "events",
  createdEvents: "created-events",
  eventsCalendar: "events-calendar",
  educatioanlResources: "educational-resources",
  myEvents: "my-events/:userId",
};

// TODO: Make route protection

export const router = createBrowserRouter(
  [
    {
      element: <App />,
      path: "/",
      // TODO: errorElement: ''
      children: [
        {
          index: true,
          element: <Home />,
        },
        {
          path: "my-profile",
          element: <MyProfile />,
        },
        {
          path: "create-event",
          element: <EventSettings />,
        },
        {
          path: "events",
          element: <Events />,
        },
        {
          path: "created-events",
          element: <CreatedEvents />,
        },
        {
          path: "event/:id",
          element: <Event />,
        },
        {
          path: "event/edit/:eventId",
          element: <EventSettings />,
        },
        {
          path: "event/:eventId/applied-volunteers",
          element: <AppliedVolunteers />,
        },
        {
          path: "user-profile/:userId",
          element: <UserProfile />,
        },
        {
          path: "blog",
          element: <Blog />,
        },
        {
          path: "blog/:slug",
          element: <Blog />,
        },
        {
          path: ":slug",
          element: <StaticPage />,
        },
        {
          path: "events-calendar",
          element: <EventsCalendar />,
        },
        {
          path: "contact",
          element: <Contact />,
        },
        {
          path: "educational-resources",
          element: <EducationalResources />,
        },
        {
          path: "my-events/:userId",
          element: <UserProfile />,
        },
      ],
    },
  ],
  { basename: process.env.REACT_APP_PUBLIC_URL }
);
