import * as Yup from "yup";
import { startOfDay, isAfter } from "date-fns";

const ERROR = {
  REQUIRED: "Required Field",
  CATEGORIES_EMPTY: "You must select at leaset one category",
  LANGUAGES_EMPTY: "You must select at leaset one language",
  DATE_ERROR: "From date must be smaller or equal to To Date",
  POSITIVE: "Number has to be greater than 0",
};

export const validationSchema = Yup.object().shape({
  name: Yup.string().required(ERROR.REQUIRED),
  dateFrom: Yup.string().required(ERROR.REQUIRED),
  dateTo: Yup.string()
    .nonNullable()
    .required(ERROR.REQUIRED)
    .test("dateTo", ERROR.DATE_ERROR, function (value) {
      const { dateFrom } = this.parent;

      if (!dateFrom || !value) {
        return true;
      }

      const start = startOfDay(new Date(dateFrom));
      const end = startOfDay(new Date(value));

      return isAfter(end, start) || end.getTime() === start.getTime();
    }),
  volunteersCount: Yup.number()
    .required(ERROR.REQUIRED)
    .positive(ERROR.POSITIVE),
  workingHoursCount: Yup.number()
    .required(ERROR.REQUIRED)
    .positive(ERROR.POSITIVE),
  countryId: Yup.string().required(ERROR.REQUIRED),
  city: Yup.string().required(ERROR.REQUIRED),
  costs: Yup.string().required(ERROR.REQUIRED),
});
