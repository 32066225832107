import { State } from "./State";
var StateSnapshot = /** @class */ (function () {
    function StateSnapshot(store) {
        this.store = store;
        this.currentSnapshot = createStoreSnapshot(this.store);
    }
    StateSnapshot.prototype.getCurrent = function () {
        return this.currentSnapshot;
    };
    StateSnapshot.prototype.update = function () {
        this.prevSnapshot = this.currentSnapshot;
        this.currentSnapshot = createStoreSnapshot(this.store, this.prevSnapshot.currentStoreSnapshot);
    };
    return StateSnapshot;
}());
export { StateSnapshot };
function createStoreSnapshot(store, prevSnapshot, currentStoreSnapshot, differences) {
    if (currentStoreSnapshot === void 0) { currentStoreSnapshot = {}; }
    if (differences === void 0) { differences = []; }
    for (var property in store) {
        var state = store[property];
        var prevStateValue = prevSnapshot === null || prevSnapshot === void 0 ? void 0 : prevSnapshot[property];
        if (state instanceof State) {
            var stateValue = state.get();
            currentStoreSnapshot[property] = stateValue;
            if (prevStateValue && prevStateValue !== stateValue) {
                differences.push({ name: property, fromValue: prevStateValue, toValue: stateValue });
            }
            continue;
        }
        if (Array.isArray(state)) {
            continue;
        }
        if (typeof state === 'object') {
            createStoreSnapshot(state, prevStateValue, currentStoreSnapshot[property] = {}, differences);
        }
    }
    return {
        currentStoreSnapshot: currentStoreSnapshot,
        differences: prevSnapshot ? differences : undefined
    };
}
