import { useParams } from "react-router-dom";
import { useGetBlogsQuery } from "../../query-hooks";
import { useEffect } from "react";
import ContentContainer from "../../components/ContentContainer";
import styles from "./BlogPostContent.module.scss";
import { getDateDay, getDateMonth } from "../../utils/dates";
import ShareIcon from "../../assets/icons/ShareIcon";
import { getFileSrc } from "../../utils";

import parseHtml from "html-react-parser";

const BlogPostContent = () => {
  const { slug } = useParams<{ slug: string }>();

  const { getBlogs, data: blogs } = useGetBlogsQuery();

  useEffect(() => {
    getBlogs({
      pagination: {
        page: 0,
        rowsPerPage: 1,
      },
      filter: { slug },
    });
  }, []);

  // const dateDay = getDayFromDate("12.12.2023");
  // const dateMonthName = getMonthFromDate("12.12.2023");

  const dateDay = getDateDay(blogs?.blogs[0].createdAt || "");
  const dateMonthName = getDateMonth(blogs?.blogs[0].createdAt || "");

  const eventImage = getFileSrc(blogs?.blogs[0].imageUrl || "");

  const divStyle = {
    backgroundImage: `url(${eventImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    // backgroundPositionY: "top",
  };

  return (
    <>
      <div style={divStyle} className={styles.photoContainer} />

      <ContentContainer className={styles.contentContainer}>
        <div className={styles.infoContainer}>
          <div className={styles.date}>
            <div className={styles.dateDay}>{dateDay}</div>
            <div className={styles.dateMonth}>{dateMonthName}</div>
          </div>
          <div className={styles.divider} />

          <div className={styles.title}>{blogs?.blogs[0].title}</div>

          <div className={styles.shareContainer}>
            <div>
              <ShareIcon />
            </div>
            <div className={styles.shareText}>share</div>
          </div>
        </div>
        <div className={styles.blogText}>
          {parseHtml(blogs?.blogs[0].text || "")}
        </div>
      </ContentContainer>
    </>
  );
};

export default BlogPostContent;
