import styles from "./SwiperControls.module.scss";

import leftArrow from "./assets/arrow-left.svg";
import rightArrow from "./assets/arrow-right.svg";

import leftArrowHover from "./assets/arrow-left-hover.svg";
import rightArrowHover from "./assets/arrow-right-hover.svg";

type SwiperControlsProps = {
  next: string;
  prev: string;
};

const SwiperControls = ({ next, prev }: SwiperControlsProps) => {
  return (
    <div className={styles.navigationWrapper}>
      <img
        alt="arrow"
        src={leftArrow}
        onMouseOver={(e) => (e.currentTarget.src = leftArrowHover)}
        onMouseOut={(e) => (e.currentTarget.src = leftArrow)}
        className={`${prev} ${styles.leftArrow}`}
      />
      <img
        alt="arrow"
        src={rightArrow}
        onMouseOver={(e) => (e.currentTarget.src = rightArrowHover)}
        onMouseOut={(e) => (e.currentTarget.src = rightArrow)}
        className={`${next}  ${styles.rightArrow}`}
      />
    </div>
  );
};

export default SwiperControls;
