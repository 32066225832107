import React from "react";

interface IconProps {
  iconClassName?: string;
}

const DraftIcon: React.FC<IconProps> = ({ iconClassName }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29"
      height="25"
      viewBox="0 0 29 25"
      fill="none"
      className={iconClassName}
    >
      <path
        d="M14.128 5.594C15.8247 5.58815 17.4543 6.25629 18.6585 7.45153C19.8628 8.64678 20.5431 10.2713 20.55 11.968C20.5504 12.7682 20.3941 13.5608 20.09 14.301L23.841 18.021C25.7965 16.4064 27.313 14.3245 28.25 11.968C26.7863 8.31397 23.9634 5.36945 20.3743 3.75308C16.7852 2.13671 12.7093 1.97431 9.00298 3.3L11.777 6.055C12.5243 5.7536 13.3222 5.59715 14.128 5.594ZM1.28598 2.12L4.21798 5.027L4.81098 5.613C2.66391 7.25882 1.0019 9.45468 0.000976562 11.968C1.50264 15.7056 4.4233 18.6966 8.1242 20.2866C11.8251 21.8767 16.0051 21.9365 19.75 20.453L20.292 20.989L24.056 24.709L25.689 23.089L2.91298 0.5L1.28598 2.12ZM8.38598 9.162L10.378 11.135C10.3129 11.4056 10.2794 11.6827 10.278 11.961C10.2797 12.4649 10.3807 12.9634 10.5753 13.4282C10.7699 13.893 11.0542 14.3149 11.4119 14.6697C11.7697 15.0245 12.1939 15.3052 12.6603 15.4959C13.1267 15.6866 13.6261 15.7835 14.13 15.781C14.4102 15.78 14.6894 15.7464 14.962 15.681L16.95 17.654C15.9768 18.1405 14.8956 18.3706 13.8087 18.3224C12.7217 18.2741 11.6651 17.9492 10.7389 17.3784C9.81268 16.8076 9.04751 16.0098 8.51588 15.0605C7.98425 14.1113 7.70376 13.042 7.70098 11.954C7.71077 10.9831 7.94586 10.0275 8.38598 9.162ZM13.921 8.172L17.968 12.188L17.993 11.988C17.9913 11.4841 17.8902 10.9856 17.6957 10.5208C17.5011 10.056 17.2168 9.63413 16.859 9.27933C16.5012 8.92453 16.077 8.64375 15.6106 8.45306C15.1442 8.26237 14.6448 8.1655 14.141 8.168L13.921 8.172Z"
        fill="#CCCCCC"
      />
    </svg>
  );
};

export default DraftIcon;
