import { createState } from "react-reactive-tools";

enum UserAgent {
  Tablet = "tablet",
  Mobile = "mobile",
  Desktop = "desktop",
}
const getdeviceTypeState = () => {
  const ua = navigator.userAgent;

  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return UserAgent.Tablet;
  } else if (
    /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua
    )
  ) {
    return UserAgent.Mobile;
  }
  return UserAgent.Desktop;
};

const getUserAgent = () => {
  const isBrowser = typeof window !== "undefined";
  if (!isBrowser) {
    return { isMobile: false, isTablet: false, isDesktop: true };
  }
  const agent = getdeviceTypeState();

  return {
    isMobile: agent === UserAgent.Mobile,
    isTablet: agent === UserAgent.Tablet,
    isDesktop: agent === UserAgent.Desktop,
  };
};

type DeviceTypeState = Readonly<{
  isMobile: boolean;
  isDesktop: boolean;
  isTablet: boolean;
}>;

export const deviceTypeState = createState<DeviceTypeState>(
  "deviceType",
  getUserAgent()
);
